<template>
    <div class="wrapper presentation-page">

        <div id="poker-spot" data-background-color="white">
            <div class="space-100"></div>
            <div class="container">
                <div class="mb-5">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }"><span class="text-black-50"><i class="fa fa-home"></i></span></el-breadcrumb-item>
                        <el-breadcrumb-item><span class="text-black-50">利用規約</span></el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
            <div v-html="content_html" />
            <div v-if="false" class="cards">
                <div class="container">
                    <h6 class="with-title-dark">利用規約</h6>
                    <div class="row">
                        <div class="col-12">

                            <h6 class="mb-3">はじめに</h6>
                            <p class="card-description mb-5">
Ａｔｔａｉｎ合同会社（以下、「当社」といいます）が提供するサービス/アプリケーション（以下、「本サービス」といいます）の利用について、以下のとおり利用規約（以下「本規約」といいます）を定めます。利用者（以下「お客様」といいます）は、予め本規約に同意した上で、本サービスを利用するものとします。本サービスの全部または一部を利用された場合には、本規約に同意されたものとみなされます。
                            </p>

                            <h6 class="">第１条　著作権等</h6>
                            <p class="card-description mb-5">
本サービスに含まれるコンテンツ、プログラムおよび関連ドキュメント等の著作権その他の権利は、当社または当社に権利を許諾した第三者に帰属します。</p>

                            <h6 class="">第２条　有料サービスの利用</h6>
                            <p class="card-description mb-5">
１．有料サービスの利用にあたっては、お客様は、当社が別途定める利用料金（以下「利用料金」といいます）を支払う必要があります。<br />
２． 利用料金は、お客様が本サービスをダウンロードされたウェブサイト、マーケットプレイス等を運営する事業者（以下「事業者」といいます。）が当社に代わって 事業者所定の方法によりお客様から回収します。なお、お客様と事業者との間で利用料金の支払を巡って疑義および紛争が発生した場合、かかる当事者間で処理 解決しなければならず、当社は一切の責任を負いません。<br />
                            </p>

                            <h6 class="">第３条　個人情報</h6>
                            <p class="card-description mb-5">
当社がお客様の個人情報を取得する場合は、当社のプライバシーポリシーに従い適切に取り扱うものとします。
                            </p>

                            <h6 class="">第４条　禁止事項</h6>
                            <p class="card-description mb-3">
本サービスの利用に関して、お客様は以下の行為およびこれらに類する行為を行ってはならないものとします。お客様の違反行為により当社に損害が生じた場合、お客様はその損害を賠償する責任を負うものとします。
                            </p>
                            <ol>
                                <li>法令又は公序良俗に反する行為</li>
                                <li>本サービスに含まれるあらゆる情報に関する著作権、特許権、商標権等の知的所有権、およびその他の権利を侵害する行為、またはそのおそれのある行為</li>
                                <li>他者の財産、名誉、プライバシーもしくは肖像権を侵害する行為、または侵害するおそれのある行為</li>
                                <li>本サービスにより利用しうる情報を改ざんまたは消去する行為</li>
                                <li>ウィルス等の有害なコンピュータプログラム等を送信または掲載する行為</li>
                                <li>当社の承認なく本アプリを利用した営利を目的とする行為</li>
                                <li>その他当社が不適当と判断した一切の行為</li>
                            </ol>

                            <h6 class="">第５条　免責</h6>
                            <ol>
                                <li>当社は、お客様への通知を行うことなく、当社の裁量に基づいていつでも本サービスの仕様または内容の変更、アップデート、修正、配布方法の変更、サービス終了等を実施することができるものとします。</li>
                                <li>当社は、本サービスの瑕疵に基づく直接的または間接的損害および障害等について、いかなる責任も負わないものとし、一切の賠償を行わないものとします。</li>
                            </ol>

                            <h6 class="">第６条　本規約の変更および本サービス毎の規約の制定</h6>
                            <p class="card-description mb-3">
当社は、本規約の内容については､必要に応じて全部又は一部を変更することができるものとします｡当該変更は当社所定のサイトに掲載したときに、その効力を生じるものとします。また別途本サービス毎に規約が制定される場合は別段の定義がない限りサービス毎の規約を適応するものとします。
                            </p>

                            <h6 class="">第７条　準拠法・管轄</h6>
                            <p class="card-description mb-5">
本規約の解釈は日本法を準拠するものとし、本規約に関する紛争が生じた場合には、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
                            </p>

                            <p class="card-description float-right mb-3">
制定日：2019年08月01日
                            </p>

                        </div>
                    </div>
                    <div class="space-50"></div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
  import {
    Breadcrumb,
    BreadcrumbItem,
  } from '@/components';
  import axios from "axios";
  import {API_ENDPOINT} from '@/constants';

  export default {
    name: 'privacy',
    bodyClass: 'presentation-page',
    components: {
      [Breadcrumb.name]: Breadcrumb,
      [BreadcrumbItem.name]: BreadcrumbItem,
    },
    data() {
      return {
        content_html: ''
      }
    },
    async created() {
      try {
        document.title = '利用規約 | ポーカーナビ - 全国のポーカースポット、サークル、トーナメント情報を配信！'

        await axios
          .get(API_ENDPOINT + `appconstant/2`)
          .then(response => {
            this.content_html = response.data.text_value
          })

      } catch (e) {
        this.content_html = '<div class="spinner"></div><div class="space-50"></div>'
      } finally {
        //
      }
    }
  }
</script>

