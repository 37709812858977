<template>
    <div v-if="!loading">

        <div v-if="this.no_content" class="wrapper profile-page no-content">
            <div class="spinner"></div>
        </div>
        <div v-else class="wrapper profile-page">
            <div class="page-header page-header-smalls clear-filter" filter-color="black">
                <div v-if="circle.image_header !== null" class="page-header-image"
                     :style="{ 'background-image': 'url('+ circle.image_header +')' }">
                </div>
                <div v-else class="page-header-image" style="background-image: url('https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/images/shutterstock_295358264.jpg')"></div>

                <div class="rellax-text-container rellax-text">
                    <div v-if="circle !== null && circle.logo !== null" class="photo-container">
                        <div class="photo-container-inner" :style="{ 'background-image': 'url('+ circle.logo +')' }"></div>
                    </div>
                    <div v-else class="mb-5"></div>
                    <h1 v-if="circle !== null" class="h1-seo mb-2 col-sm-6 mr-auto ml-auto" data-rellax-speed="-2">{{ circle.name }}</h1>
                    <h6 v-if="circle !== null && circle.alias !== ''" class="category">{{ circle.alias }}</h6>
                </div>
            </div>

            <a v-if="true" :href="order_form" target="_blank" class="register-wrap">
                <div class="register">
                   <span>無</span>
                   <span>料</span>
                   <span>掲</span>
                   <span>載</span>
                   <span>登</span>
                   <span>録</span>
                </div>
            </a>

            <div class="section section-components" data-background-color="white">
                <div class="container pb-4 pt-3">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }"><span class="text-white-50s"><i class="fa fa-home"></i></span></el-breadcrumb-item>
                        <el-breadcrumb-item><span v-if="circle !== null">{{ circle.name }}</span><span v-if="circle.alias !== null && circle.alias !== ''" class="mx-0">｜</span>{{ circle.alias }}</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>

            <div class="slider-container google-calendar-wraps" data-background-color="white">
                <div class="google-calendar-mask">

                    <div class="container mt-3">
                        <div class="mb-0 text-right">
                            <social-sharing
                                :url="currentURL()"
                                :title="getName(circle)"
                                :description="getAnnouncement(circle.charged ? circle : '')"
                                :quote="getAnnouncement(circle.charged ? circle : '')"
                                :hashtags="getHashtags(circle)"
                                twitter-user="PokerNavi_JP"
                                inline-template>
                                <div>
                                    <network network="facebook">
                                        <div class="sns_share fb">
                                            <i class="fab fa-facebook-f mr-1" style="color:#fff"></i>
                                            <span class="font-weight-bold" style="font-size:.8rem">Share</span>
                                        </div>
                                    </network>
                                    <network network="twitter">
                                        <div class="sns_share twitter">
                                            <i class="fab fa-twitter mr-1" style="color:#fff"></i>
                                            <span class="font-weight-bold" style="font-size:.7rem;vertial-align:middle">ツイート</span>
                                        </div>
                                    </network>
                                </div>
                            </social-sharing>
                        </div>
                    </div>
                    <div class="container mb-3">
                        <div class="mb-5">
                            <p class="blockquote blockquote-primary">
                                <strong v-if="circle !== null" class="font-weight-bold" style="font-size:1.5rem">{{ circle.name }}<br /></strong>
                                <small v-if="circle !== null && circle.alias !== ''">{{ circle.alias }}<br /></small>
                                <small v-if="circle !== null"><br /><i class="far fa-map-marker-alt mr-2"></i>{{circle.prefecture.name}} {{circle.city}}</small>
                            </p>
                        </div>

                        <div v-if="!circle.charged" class="text-center ml-auto mr-auto mb-5">
                            <a :href="order_form" target="_blank" class="btn btn-lg btn-simples btn-primary btn-rounds text-white mb-4">
                                <i class="fa fa-share mr-2"></i>無料掲載登録はコチラ
                            </a>
                            <div>
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSfkWuDqNTH-YzFT686BElGVpXQ7vcJ92eoTa9t37GRcDeRpEQ/viewform" target="_blank">掲載サークルの休止、その他お問合せについて</a>
                            </div>
                        </div>

                        <div v-if="circle.charged" class="">

                            <div v-if="circle.attribute.id === 2 && (todays_events.length > 0 || future_events.length > 0)" >
                                <div class="row">
                                    <div class="col-md-12 ml-auto mr-auto">

                                        <div v-if="todays_events.length > 0">
                                            <h6 class="with-title-dark">
                                                本日開催｜<span class="small">{{ Date.now() | yyyyMMdd}}</span>
                                            </h6>
                                            <div v-if="todays_events.length > 0" >
                                                <el-table :data="todays_events" class="mb-5">
                                                    <el-table-column label="開始" min-width="70" align="center">
                                                        <template slot-scope="scope">{{ scope.row.start | HHmm }}</template>
                                                    </el-table-column>
                                                    <el-table-column label="終了" min-width="70" align="center">
                                                        <template slot-scope="scope">{{ scope.row.end | HHmm }}</template>
                                                    </el-table-column>
                                                    <el-table-column min-width="220"
                                                                     align="left"
                                                                     prop="title"
                                                                     label="イベント">
                                                    </el-table-column>
                                                </el-table>
                                            </div>
                                        </div>

                                        <div v-if="future_events.length > 0">
                                            <h6 class="with-title-dark">今後の開催</h6>
                                            <el-table :data="future_events" class="mb-5">
                                                <el-table-column label="日時" min-width="90" align="center">
                                                    <template slot-scope="scope">{{ scope.row.start | MMdd }}</template>
                                                </el-table-column>
                                                <el-table-column label="開始" min-width="70" align="center">
                                                    <template slot-scope="scope">{{ scope.row.start | HHmm }}</template>
                                                </el-table-column>
                                                <el-table-column label="終了" min-width="70" align="center">
                                                    <template slot-scope="scope">{{ scope.row.end | HHmm }}</template>
                                                </el-table-column>
                                                <el-table-column min-width="220"
                                                                 align="left"
                                                                 prop="title"
                                                                 label="イベント">
                                                </el-table-column>
                                            </el-table>
                                        </div>
                                    </div>
                                </div>
                            </div><!-- event end -->

                            <div v-if="circle.youtube !== null && circle.youtube !== '' && circle.youtube_enabled">
                                <h6 class="with-title-dark">VIDEO</h6>
                                <div class="youtube_wrap mb-4">
                                    <iframe class="youtube_content" :src="'https://www.youtube.com/embed/' + circle.youtube + ''" frameborder="0" allow="accelerometer;autoplay;encrypted-media;gyroscope;picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>

                            <div v-if="sliders.length > 0" class="slider-container mb-5">
                                <div v-if="sliders.length > 0" class="row justify-content-center">
                                    <div class="col-12">
                                        <el-carousel height="300" type="" arrow="always">
                                            <el-carousel-item v-for="(slider_image,key) in sliders" :key="key" class="carousel-item">
                                                <!--  no-repeat center center;background-size:cover; とすると表示できない -->
                                                <div class="slider-content" :style="{ 'background-image':'url(' + slider_image + ')' }">
                                                    <div class="slider-content clear-filter" filter-color="blue"></div>
                                                </div>
                                                <div class="carousel-caption d-none d-md-block">
                                                    <h5></h5>
                                                </div>
                                            </el-carousel-item>
                                        </el-carousel>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-4">
                                <div :class="getGridLeft(circle) + ' mb-3'">
                                    <h6 class="with-title-dark">お知らせ</h6>
                                    <pre class="mb-5">{{ circle.announcement }}</pre>
                                </div>

                                <div :class="getGridRight(circle) + ' mb-3'">
                                    <div v-if="(circle.access !== null && circle.access !== '') || (circle.access_url !== null && circle.access_url !== '') || (circle.access_url2 !== null && circle.access_url2 !== '')" class="mb-5">
                                        <h6 class="with-title-dark">アクセス</h6>
                                        <p v-if="circle.access !== null && circle.access !== ''" class="col-12 description mb-4">{{ circle.access }}</p>

                                        <p v-if="circle.access_url !== null && circle.access_url !== ''" class="col-12 description mb-4">
                                          <i class="fa fa-external-link mr-2"></i>
                                          <a :href="circle.access_url" target="_blank">
                                            <strong>
                                              <span v-if="circle.access_url_text">{{ circle.access_url_text }}</span>
                                              <span v-else>{{circle.access_url}}</span>
                                            </strong>
                                          </a>
                                        </p>
                                        <p v-if="circle.access_url2 !== null && circle.access_url2 !== ''" class="col-12 description mb-4">
                                          <i class="fa fa-external-link mr-2"></i>
                                          <a :href="circle.access_url2" target="_blank">
                                            <strong>
                                              <span v-if="circle.access_url2_text">{{ circle.access_url2_text }}</span>
                                              <span v-else>{{circle.access_url2}}</span>
                                            </strong>
                                          </a>
                                        </p>
                                    </div>

                                    <div v-if="(
                                        (circle.phone_enabled && circle.phone !== null && circle.phone !== '')
                                        || (circle.email_enabled && circle.email !== null && circle.email !== '')
                                        || (circle.web !== null && circle.web !== '') 
                                        || (circle.desc_url !== null && circle.desc_url !== '')
                                        || (circle.desc_url2 !== null && circle.desc_url2 !== '')
                                        || (circle.desc_url3 !== null && circle.desc_url3 !== '')
                                        || (circle.desc_url4 !== null && circle.desc_url4 !== '')
                                        || (circle.desc_url5 !== null && circle.desc_url5 !== '') )">

                                        <h6 class="with-title-dark">基本情報</h6>
                                        <p v-if="circle.phone_enabled && circle.phone !== null && circle.phone !== ''" class="col-12 description mb-4"><i class="fa fa-phone mr-2"></i>{{circle.phone}}</p>
                                        <p v-if="circle.email_enabled && circle.email !== null && circle.email !== ''" class="col-12 description mb-4"><i class="fa fa-envelope mr-2"></i>{{circle.email}}</p>
                                        <p v-if="circle.web !== null && circle.web !== ''" class="col-12 description mb-4"><i class="fa fa-globe mr-2"></i><a :href="circle.web" target="_blank"><strong>{{circle.name}} 公式サイト</strong></a></p>
                                        <p v-if="circle.desc_url !== null && circle.desc_url !== ''" class="col-12 description mb-4">
                                          <i class="fa fa-globe mr-2"></i>
                                          <a :href="circle.desc_url" target="_blank">
                                            <strong>
                                              <span v-if="circle.desc_url_text">{{ circle.desc_url_text }}</span>
                                              <span v-else>{{circle.desc_url}}</span>
                                            </strong>
                                          </a>
                                        </p>
                                        <p v-if="circle.desc_url2 !== null && circle.desc_url2 !== ''" class="col-12 description mb-4">
                                          <i class="fa fa-globe mr-2"></i>
                                          <a :href="circle.desc_url2" target="_blank">
                                            <strong>
                                              <span v-if="circle.desc_url2_text">{{ circle.desc_url2_text }}</span>
                                              <span v-else>{{circle.desc_url2}}</span>
                                            </strong>
                                          </a>
                                        </p>
                                        <p v-if="circle.desc_url3 !== null && circle.desc_url3 !== ''" class="col-12 description mb-4">
                                          <i class="fa fa-globe mr-2"></i>
                                          <a :href="circle.desc_url3" target="_blank">
                                            <strong>
                                              <span v-if="circle.desc_url3_text">{{ circle.desc_url3_text }}</span>
                                              <span v-else>{{circle.desc_url3}}</span>
                                            </strong>
                                          </a>
                                        </p>
                                        <p v-if="circle.desc_url4 !== null && circle.desc_url4 !== ''" class="col-12 description mb-4">
                                          <i class="fa fa-globe mr-2"></i>
                                          <a :href="circle.desc_url4" target="_blank">
                                            <strong>
                                              <span v-if="circle.desc_url4_text">{{ circle.desc_url4_text }}</span>
                                              <span v-else>{{circle.desc_url4}}</span>
                                            </strong>
                                          </a>
                                        </p>
                                        <p v-if="circle.desc_url5 !== null && circle.desc_url5 !== ''" class="col-12 description mb-4">
                                          <i class="fa fa-globe mr-2"></i>
                                          <a :href="circle.desc_url5" target="_blank">
                                            <strong>
                                              <span v-if="circle.desc_url5_text">{{ circle.desc_url5_text }}</span>
                                              <span v-else>{{circle.desc_url5}}</span>
                                            </strong>
                                          </a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div v-if="circle.main_spots.length > 0" class="row">
                                <div class="container">

                                    <h6 class="with-title-dark">活動スポット</h6>
                                    <div class="cards">
                                        <div v-if="circle.main_spots.length > 0" class="card-columns mb-3">
                                            <div v-for="(main_spot, i) in circle.main_spots" :key="main_spot.id">
                                                <div v-if="main_spot.approve && main_spot.published">
                                                    <router-link v-if="main_spot.unique_domain !== null && main_spot.unique_domain !== ''" :to="'/' + main_spot.unique_domain" tag="a">
                                                        <a>
                                                            <card :color="main_spot.charged_type == 10 ? '' : (main_spot.charged_type == 2 ? 'gray' : 'orange')"
                                                                  :cardBodyClasses="addNew(main_spot)" >
                                                                <img v-if="main_spot.charged && main_spot.thumbnail !== null" slot="image" class="img rounded" v-lazy="main_spot.thumbnail">
                                                                <h6 class="category text-primary font-weight-normal text-left">
                                                                    <span v-if="main_spot.prefecture !== null">
                                                                        <i class="far fa-map-marker-alt mr-2"></i>{{main_spot.prefecture.name}} {{main_spot.city}}
                                                                    </span>
                                                                    <span v-if="main_spot.online">
                                                                        <i class="far fa-globe mr-2"></i>オンライン
                                                                    </span>
                                                                </h6>
                                                                <h5 class="card-title font-weight-bold">{{main_spot.name}}</h5>
                                                                <p v-if="main_spot.charged && false" class="card-description" v-html="$options.filters.readmore(main_spot.description, 100)"></p>
                                                            </card>
                                                        </a>
                                                    </router-link>
                                                    <router-link v-else :to="{ name: 'spot', params: { id: main_spot.id }}" tag="a">
                                                        <a>
                                                            <card :color="main_spot.charged_type == 10 ? '' : (main_spot.charged_type == 2 ? 'gray' : 'orange')"
                                                                  :cardBodyClasses="addNew(main_spot)" >
                                                                <img v-if="main_spot.charged && main_spot.thumbnail !== null" slot="image" class="img rounded" v-lazy="main_spot.thumbnail">
                                                                <h6 class="category text-primary font-weight-normal text-left">
                                                                    <span v-if="main_spot.prefecture !== null">
                                                                        <i class="far fa-map-marker-alt mr-2"></i>{{main_spot.prefecture.name}} {{main_spot.city}}
                                                                    </span>
                                                                    <span v-if="main_spot.online">
                                                                        <i class="far fa-globe mr-2"></i>オンライン
                                                                    </span>
                                                                </h6>
                                                                <h5 class="card-title font-weight-bold">{{main_spot.name}}</h5>
                                                                <p v-if="main_spot.charged && false" class="card-description" v-html="$options.filters.readmore(main_spot.description, 100)"></p>
                                                            </card>
                                                        </a>
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div v-if="snsViewable(circle)">
                                <div class="section pb-3 mx-auto" data-background-color="white">
                                    <div v-if="bothViewable(circle)">
                                        <div class="row">
                                            <div class="col-md-7">
                                                <h6 v-if="false" class="with-title-dark mb-3">CALENDAR</h6>
                                                <iframe :src="'https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23e3e7ff&amp;ctz=Asia%2FTokyo&amp;src=' +circle.google_calendar+ '&amp;color=%23ffffff&amp;showTitle=0&amp;showNav=1&amp;showPrint=0&amp;showTabs=1&amp;showCalendars=0&amp;showTz=0'+getCalendarView(circle)"
                                                        style="border-width:0;width:100%" class="pb-2" height="600" frameborder="0" scrolling="no"></iframe>
                                            </div>
                                            <div class="col-md-5" id="fbpage1">
                                                <h6 v-if="false" class="with-title-dark mb-3">EVENTS</h6>
                                                <div class="fb-page" :data-href="'' + facebook_page_url + ''" data-tabs="events" :data-width="getFbWidgetWidth()" data-height="600" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-hide-dta="true" data-show-facepile="true"><blockquote :cite="'' + facebook_page_url + ''" class="fb-xfbml-parse-ignore"></blockquote></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else-if="onlyFbViewable(circle)">
                                            <div class="" id="fbpage1">
                                                <h6 v-if="false" class="with-title-dark mb-3">EVENTS</h6>
                                                <div class="fb-page" :data-href="'' + facebook_page_url + ''" data-tabs="events" :data-width="getFbWidgetWidth()" data-height="600" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-hide-dta="true" data-show-facepile="true"><blockquote :cite="'' + facebook_page_url + ''" class="fb-xfbml-parse-ignore"></blockquote></div>
                                            </div>
                                    </div>
                                    <div v-else-if="onlyCalendarViewable(circle)">
                                        <h6 v-if="false" class="with-title-dark mb-3">CALENDAR</h6>
                                        <div class="">
                                            <iframe :src="'https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23e3e7ff&amp;ctz=Asia%2FTokyo&amp;src=' +circle.google_calendar+ '&amp;color=%23ffffff&amp;showTitle=0&amp;showNav=1&amp;showPrint=0&amp;showTabs=1&amp;showCalendars=0&amp;showTz=0'+getCalendarView(circle)"
                                                    style="border-width:0;width:100%" class="pb-2" height="600" frameborder="0" scrolling="no"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div v-if="circle.charged" class="container">
                    <div v-if="(circle.twitter_enabled && circle.twitter !== null && circle.twitter !== '') && (circle.facebook == null || circle.facebook == '' || !circle.facebook_timeline)">
                        <div class="row">
                            <div v-if="circle.twitter_enabled && circle.twitter !== null" class="col-12 mb-5">
                                <h6 class="with-title-dark mb-3">TWITTER</h6>
                                <twitter v-if="true"></twitter>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="(circle.facebook_timeline && circle.facebook !== null && circle.facebook !== '') && (circle.twitter == null || !circle.twitter_enabled || circle.twitter == '')">
                        <div class="row">
                            <div class="col-12 mb-5">
                                <h6 class="with-title-dark mb-3">FACEBOOK</h6>
                                <div id="fbpage" class="">
                                  <div class="fb-page" :data-href="'' + facebook_page_url + ''" data-tabs="timeline" :data-width="getFbWidgetWidth()" data-height="1500" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-hide-dta="true" data-show-facepile="true"><blockquote :cite="'' + facebook_page_url + ''" class="fb-xfbml-parse-ignore"></blockquote></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="row">
                            <div v-if="circle.twitter_enabled && circle.twitter !== null && circle.twitter !== ''" class="col-md-7 mb-5">
                                <h6 class="with-title-dark mb-3">TWITTER</h6>
                                <twitter v-if="true"></twitter>
                            </div>
                            <div v-if="circle.facebook_timeline && circle.facebook !== null && circle.facebook !== ''" class="col-md-5 mb-5">
                                <h6 class="with-title-dark mb-3">FACEBOOK</h6>
                                <div id="fbpage" class="">
                                  <div class="fb-page" :data-href="'' + facebook_page_url + ''" data-tabs="timeline" :data-width="getFbWidgetWidth()" data-height="1500" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-hide-dta="true" data-show-facepile="true"><blockquote :cite="'' + facebook_page_url + ''" class="fb-xfbml-parse-ignore"></blockquote></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!circle.charged" class="pt-4 mb-3">
                <div v-if="true" class="adsense-infeed text-center">
                    <dfp-slot v-if="true"
                        path="/21867335047/pokernavi/pokernavi-footer"
                        :size="dfpSlotSize('div-gpt-ad-1580361234575-0')"
                        id="div-gpt-ad-1580361234575-0"
                    />
                </div>
            </div>
        </div>

    </div>
</template>
<script>
  import {
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    NukButton,
    Card,
    Collapse,
    CollapseItem,
  } from '@/components';
  import Twitter from '../components/Twitter';
  import { API_ENDPOINT } from '@/constants'
  import {
    Carousel,
    CarouselItem,
    Loading,
    Table,
    TableColumn,
  } from 'element-ui';
  import Vue from 'vue'
  import axios from "axios";
  import dayjs from 'dayjs'
  import DfpSlot from '../components/DfpSlot.vue'

  export default {
    name: 'organization-circle',
    bodyClass: 'profile-page',
    components: {
      Card,
      Collapse,
      CollapseItem,
      Twitter,
      Badge,
      [Breadcrumb.name]: Breadcrumb,
      [BreadcrumbItem.name]: BreadcrumbItem,
      [NukButton.name]: NukButton,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      DfpSlot
    },
    filters: {
      dateFormatter: function (date) {
        return format(date, 'YYYY年MM月DD日')
      },
      yyyyMMdd: function (date) {
        return dayjs(date).format('YYYY年M月D日')
      },
      MMdd: function (date) {
        return dayjs(date).format('MM月DD日')
      },
      HHmm: function (date) {
        return dayjs(date).format('HH:mm')
      },
      yyyyMMddd: function (date) {
        return dayjs(date).format('YYYY年M月D日 (ddd)');
      },
    },
    mounted() {
    },
    async created() {
      try {
        this.loading = true
        this.loadingInstance = Loading.service({ fullscreen: true });
        await this.getCircle()
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
        this.loadingInstance.close();

/*
        if (!document.getElementById('facebook-jssdk')) {
          (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v3.2';
            fjs.parentNode.insertBefore(js, fjs);
          }(document, 'script', 'facebook-jssdk'));
        }
*/
      }
    },
    data() {
      return {
        no_content: true,
        circle: {},
        twitter_id: '',
        facebook_id: '',
        facebook_page_url: '',
        sliders: [],
        loading: false,
        order_form: '',
        twitter_id: '',
        loadingInstance: null,
        calendar_view_event: 0,
        events: [],
        todays_events: [],
        future_events: [],
        dfp_slots: {
          'div-gpt-ad-1571972845074-0': { // 不明
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580282482771-0': { // 不明
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580355841225-0': { // circles
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580353644223-0': { // spots
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580356736019-0': { // events
            336: [336, 280]
          },
          'div-gpt-ad-1580361234575-0': { // footer
            300: [300, 250]
          },
        },
      }
    },
    watch: {
      circle: function() {
        this.$nextTick(() => {
            var positionY = sessionStorage.getItem('positionY')
            scrollTo(0, positionY);
            setTimeout(function(){
                scrollTo(0, positionY);
                }, 500);
            })

        this.no_content = (this.circle == null)
        document.title = this.circle.name
        if (this.circle.alias !== null)
          document.title = this.circle.name + ' | ' + this.circle.alias
/*
        if (this.circle.description !== null)
          document.querySelector("meta[name='description']").setAttribute('content', this.circle.description)
*/
      },
/*
      '$route' (to, from) {
        this.createPageTitle(to);
      }
*/
    },
    updated() {
      this.$nextTick(()=>{
        if (window.FB.XFBML) {
          window.FB.XFBML.parse(document.getElementById('fbpage'));
        }
      });
    },
    methods: {
      dfpSlotSize: function(key) {
        let dfp_slots_sizes = this.dfp_slots[key]
        return dfp_slots_sizes['300']
      },
      getGridRight: function(circle) {
        let announcementExists = circle.announcement !== null && circle.announcement !== '';
        let accessExists = (circle.access !== null && circle.access !== '') || (circle.access_url !== null && circle.access_url !== '') || (circle.access_url2 !== null && circle.access_url2 !== '');
        let infoExists = (circle.phone_enabled && circle.phone !== null && circle.phone !== '')
                          || (circle.email_enabled && circle.email !== null && circle.email !== '')
                          || (circle.web !== null && circle.web !== '')
                          || (circle.desc_url !== null && circle.desc_url !== '')
                          || (circle.desc_url2 !== null && circle.desc_url2 !== '')
                          || (circle.desc_url3 !== null && circle.desc_url3 !== '')
                          || (circle.desc_url4 !== null && circle.desc_url4 !== '')
                          || (circle.desc_url5 !== null && circle.desc_url5 !== '');

        if (announcementExists) {
          return (accessExists || infoExists) ? 'col-md-5' : 'd-none';
        } else {
          return 'col-12';
        }
      },
      getGridLeft: function(circle) {
        let announcementExists = circle.announcement !== null && circle.announcement !== '';
        let accessExists = (circle.access !== null && circle.access !== '') || (circle.access_url !== null && circle.access_url !== '') || (circle.access_url2 !== null && circle.access_url2 !== '');
        if (announcementExists) {
          return accessExists ? 'col-md-7' : 'col-12';
        } else {
          return 'd-none';
        }
      },
      getCircle: function() {
        axios
          .get(API_ENDPOINT + `organizations/` + this.$route.params.id)
          .then(response => {
            this.circle = response.data
            this.sliders = response.data.images
            this.twitter_id = response.data.twitter
            this.facebook_id = response.data.facebook
            this.facebook_page_url = 'https://www.facebook.com/' + response.data.facebook
            this.calendar_view_event = response.data.calendar_view_event
            this.events = response.data.events

/*
            document.title = this.circle.name
            if (this.circle.alias !== null)
              document.title = this.circle.name + ' | ' + this.circle.alias

            if (this.circle.description !== null)
              document.querySelector("meta[name='description']").setAttribute('content', this.circle.description)
*/

            if (this.events !== null) {
              let today = dayjs(Date.now()).format('YYYY/MM/DD')
              for (let [key, value] of Object.entries(this.events)) {
                key == today
                  ? (this.todays_events = value)
                  : Array.prototype.push.apply(this.future_events, value);
              }
            }
            this.no_content = false
          })
          .catch(function(error) {
            console.log(error)
          })
        // layout ------------
        axios
          .get(API_ENDPOINT + `appconstant/7`)
          .then(response => {
            this.order_form = response.data.text_value
        })
      },
      handleRowClick(spot) {
        this.$router.push({ name: 'spot', params: { id: spot.id } });

      },
      snsViewable: function(circle){
        let facebook_viewable = circle.facebook !== null && circle.facebook_event
        let google_calendar_viewable = circle.google_calendar !== null && circle.google_calendar_view >= 1
        return facebook_viewable || google_calendar_viewable;
      },
      bothViewable: function(circle){
        let facebook_viewable = circle.facebook !== null && circle.facebook_event
        let google_calendar_viewable = circle.google_calendar !== null && circle.google_calendar_view >= 1
        return facebook_viewable && google_calendar_viewable;
      },
      onlyCalendarViewable: function(circle){
        let facebook_not_viewable = circle.facebook == null || !circle.facebook_event
        let google_calendar_viewable = circle.google_calendar !== null && circle.google_calendar_view >= 1
        return facebook_not_viewable && google_calendar_viewable;
      },
      onlyFbViewable: function(circle){
        let facebook_viewable = circle.facebook !== null && circle.facebook_event
        let google_calendar_not_viewable = circle.google_calendar == null || circle.google_calendar_view == null
        return facebook_viewable && google_calendar_not_viewable;
      },
      getCalendarView: function(circle) {
        return circle.google_calendar_view == 2 ? '&mode=AGENDA' : '';
      },
      getFbWidgetWidth: function() {
        if (window.innerWidth > 1200) {
          return 445;
        } else if (991 < window.innerWidth && window.innerWidth <= 1200) {
          return 370;
        } else if (768 < window.innerWidth && window.innerWidth <= 991) {
          return 270;
        } else if (window.innerWidth <= 533) {
          return window.innerWidth - 30;
        } else if (533 < window.innerWidth && window.innerWidth <= 767) {
          return 500;
        }
      },
      addNew: function(spot) {
        let day7ago = dayjs(Date.now()).add(-7, 'days')
        if (spot.charged && (dayjs(spot.updated_at).diff(day7ago) > 0)) {
          return 'add-new';
        }
      },
      setOrange: function(image) {
        if (!image) {
          return 'orange';
        }
      },
    },
  }
</script>
<style>
.twitter-widget-wrapper iframe { width: 20000px !important; }
</style>
