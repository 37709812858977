<template>
     <div v-if="this.no_content" class="wrapper presentation-page no-content">
        <div class="spinner"></div>
    </div>
    <div v-else class="wrapper presentation-page">

        <vue-backTop v-if="false"></vue-backTop>
        <vue-backTop v-if="true" :height="600" :bottom="50" :right="30" @onClick="" @complete="">
            <div class="backtop">
                <i class="fa fa-spade display-5 text-info"></i>
            </div>
        </vue-backTop>

        <div id="poker-spot" data-background-color="white">
            <div class="space-100"></div>

            <div class="container">
                <div class="mb-5">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }"><span class="text-black-50"><i class="fa fa-home"></i></span></el-breadcrumb-item>
                      <el-breadcrumb-item v-if="page.title">{{ page.title }}</el-breadcrumb-item>
                      <el-breadcrumb-item v-else>動画一覧</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>

            <a v-if="true" :href="order_form" target="_blank" class="register-wrap">
                <div class="register">
                   <span>無</span>
                   <span>料</span>
                   <span>掲</span>
                   <span>載</span>
                   <span>登</span>
                   <span>録</span>
                </div>
            </a>

            <div class="container">

                <div class="mb-0 text-right">
                    <social-sharing
                        :url="currentURL()"
                        title="動画一覧 | ポーカーナビ - 全国のポーカースポット、サークル、トーナメント情報を配信！"
                        description="ポーカーナビがお届けする動画一覧です"
                        quote="ポーカーナビがお届けする動画一覧です"
                        :hashtags="getHashtags()"
                        twitter-user="PokerNavi_JP"
                        inline-template>
                        <div>
                            <network network="facebook">
                                <div class="sns_share fb">
                                    <i class="fab fa-facebook-f mr-1" style="color:#fff"></i>
                                    <span class="font-weight-bold" style="font-size:.8rem">Share</span>
                                </div>
                            </network>
                            <network network="twitter">
                                <div class="sns_share twitter">
                                    <i class="fab fa-twitter mr-1" style="color:#fff"></i>
                                    <span class="font-weight-bold" style="font-size:.7rem;vertial-align:middle">ツイート</span>
                                </div>
                            </network>
                        </div>
                    </social-sharing>
                </div>

                <h6 class="with-title-dark">
                  <span v-if="page.title !== null && page.title !== ''">{{ page.title }}</span>
                  <span v-else>動画一覧</span>
                </h6>
            </div>

            <div class="container">
                <div class="row">

                    <div class="col-md-12">
                        <n-button v-for="tag_video in tag_videos" :key="tag_video.id" type="primary" size="sm" simple>
                            <router-link :to="{ name: 'videos', query: { tag: tag_video.id }}">
                                <span class="text-black-50">{{tag_video.title}}</span>
                            </router-link>
                        </n-button>
                        <hr  class="mb-5" />
                    </div>

                    <div v-if="videos.length == 0" class="mx-auto">
                        <pre class="text-center">登録されたデータはありません</pre>
                    </div>

                    <div v-else class="col-md-12">
                        <div class="cards">
                            <div v-if="this.page.description" class="mb-5">
                                <p class="inner-card description">{{ this.page.description }}</p>
                            </div>
                            <div v-if="meta.total_pages > 1" class="pagination-container justify-content-center mb-3">
                                <video-pagination type="primary"
                                              :total="meta.count"
                                              :value="meta.current_pages"
                                              :page-count="meta.total_pages"
                                              v-model="pagination.simple"
                                              @fav="addToFavorite"
                                              ></video-pagination>
                            </div>

                            <video-pagination
                                v-if="false"
                                v-model="currentPage"
                                :page-count=meta.total_pages
                                :page-range="5"
                                :margin-pages="1"
                                :click-handler="clickCallback"
                                :prev-text="'前へ'"
                                :next-text="'次へ'"
                                :container-class="'pagination'"
                                :page-class="'page-item'">
                            </video-pagination>

                            <div v-if="false" class="container">
                                <div class="">
                                    <h6 class="mb-3 text-black pb-2 pl-3 video_h6" slot="title">新着
                                        <span><i class="fa fa-chevron-down text-black-50 float-right mr-2"></i></span>
                                    </h6>
                                </div>
                                <div class="container">
                                    <div v-if="video_recommends.length > 0" class="row mb-5" style="background-color: #000">
                                        <div v-for="(video, i) in video_recommends" :key="video.id" class="video_element">
                                            <router-link v-if="i==0" :to="{ name: 'video', params: { id: video.id }}" tag="div" class="">
                                                <img v-if="video.thumbnail !== null" class="" :src="video.thumbnail">
                                                <div class="px-3 py-2">
                                                    <h5 class="card-title font-weight-bold mt-3 mb-3">{{video.title}}</h5>
                                                    <p class="card-description">{{ video.description }}</p>
                                                </div>
                                            </router-link>
                                            <router-link v-else :to="{ name: 'video', params: { id: video.id }}" tag="div" class="">
                                                <img v-if="video.thumbnail !== null" class="" :src="video.thumbnail">
                                                <div class="px-1 py-1">
                                                    <h5 v-if="false" class="card-title font-weight-bold">{{video.title}}</h5>
                                                    <p class="card-description" v-html="$options.filters.readmore(video.description, 60)"></p>
                                                </div>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="card-columns container mb-5">
                                    <div v-for="(video, i) in videos" :key="video.id" class="">
                                        <router-link :to="{ name: 'video', params: { id: video.id }}" tag="a">
                                            <a>
                                                <card>
                                                    <img v-if="video.thumbnail !== null" slot="image" class="img rounded" :src="video.thumbnail">
                                                    <h5 class="card-title font-weight-bold">{{video.title}}</h5>
                                                    <p class="card-description"i v-html="$options.filters.readmore(video.description, 50)"></p>

                                                </card>
                                            </a>
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                            <div v-if="meta.total_pages > 1" class="pagination-container justify-content-center mb-3">
                                <video-pagination type="primary"
                                              :total="meta.count"
                                              :value="meta.current_pages"
                                              :page-count="meta.total_pages"
                                              v-model="pagination.simple"
                                              @fav="addToFavorite"
                                              ></video-pagination>
                            </div>
                            <div class="space-50"></div>

                        </div>
                    </div>

                    <div v-if="true" class="pt-3 mb-5">
                        <div class="adsense-infeed text-center">
                            <dfp-slot
                                path="/21867335047/pokernavi/pokernavi-footer"
                                :size="dfpSlotSize('div-gpt-ad-1580361234575-0')"
                                id="div-gpt-ad-1580361234575-0"
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
  import {
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Card,
    Collapse,
    CollapseItem,
    NukButton,
    VideoPagination,
  } from '@/components';
  import {
    Button,
    ButtonGroup,
    Carousel,
    CarouselItem,
    Loading,
    Table,
    TableColumn,
  } from 'element-ui';
  import axios from "axios";
  import {API_ENDPOINT} from '@/constants';
  import DfpSlot from '../components/DfpSlot.vue'

  export default {
    name: 'videos',
    bodyClass: 'presentation-page',
    components: {
      Badge,
      Card,
      Collapse,
      CollapseItem,
      [NukButton.name]: NukButton,
      [Button.name]: Button,
      [ButtonGroup.name]: ButtonGroup,
      [Breadcrumb.name]: Breadcrumb,
      [BreadcrumbItem.name]: BreadcrumbItem,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem,
      [VideoPagination.name]: VideoPagination,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      DfpSlot,
    },
    methods: {
      dfpSlotSize: function(key) {
        let dfp_slots_sizes = this.dfp_slots[key]
        if (1200 <= window.innerWidth) {
          return dfp_slots_sizes['336']
        } else if(767 < window.innerWidth && window.innerWidth < 1199) {
          return dfp_slots_sizes['336']
        } else if (window.innerWidth <= 517) {
          return dfp_slots_sizes['336']
        }
      },
      addNew: function(index) {
        return 'add-new';
      },
      setTypeBlog: function(index) {
        if (index%3 !== 0) {
          return 'blog';
        }
      },
      changePage: function(e) {
      },
      clickCallback: function(pageNum) {
        //console.log(pageNum)
      },
      getVideos () {
        let endpoint = this.baseUrl+'videos/'

        if (this.$route.query.tag) {
          // タグ関連動画の場合
          endpoint = endpoint + '?tag=' + this.$route.query.tag
          axios
            .get(endpoint)
            .then(response => {
              this.meta = response.data.meta
              this.videos = response.data.videos
              axios
                .get(this.baseUrl + `tags/` + this.$route.query.tag)
                .then(response => {
                  this.page.title = response.data.title
                  this.page.description = response.data.description
                  this.page.description = response.data.description
                  document.title = this.page.title + 'の動画一覧 | ポーカーナビ - 全国のポーカースポット、サークル、トーナメント情報を配信！'
                  this.no_content = false
                })
            })
            .catch(response => {
              console.log(response);
            });
        } else {
          // 動画一覧(全件)の場合
          axios
            .get(endpoint)
            .then(response => {
              this.meta = response.data.meta
              this.videos = response.data.videos
              document.title = '動画一覧 | ポーカーナビ - 全国のポーカースポット、サークル、トーナメント情報を配信！'
              this.no_content = false
            })
            .catch(response => {
              console.log(response);
            });
        }
        // layout ------------
        axios
          .get(API_ENDPOINT + `appconstant/7`)
          .then(response => {
            this.order_form = response.data.text_value
          })
      },
      setPages () {
        let numberOfPages = Math.ceil(this.videos.length / this.perPage);
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      addToFavorite: function(arrayPosts) {
        this.videos = arrayPosts
      },
    },
    async created() {
      try {
        this.loadingInstance = Loading.service({ fullscreen: true });
        this.getVideos();

        await axios
          .get(API_ENDPOINT + `tags/?attribute=4`)
          .then(response => {
            this.tag_videos = response.data
          })
      } catch (e) {
        //
      } finally {
        this.loadingInstance.close();
        this.no_content_text = 'コンテンツが取得できません'
      }
    },
    watch: {
      posts() {
        this.setPages();
      },
      videos: function() {
        this.$nextTick(() => {
            var positionY = sessionStorage.getItem('positionY')
            scrollTo(0, positionY);
            setTimeout(function(){
                scrollTo(0, positionY);
                }, 500);
            })
      }
    },
    data() {
      return {
        no_content: true,
        no_content_text: '',
        meta: {},
        videos: [],
        tag_videos: [],
        loadingInstance: null,
        baseUrl: API_ENDPOINT,
        order_form: '',
        page: {
          title: '',
          description: ''
        },
        currentPage: 1,
        pagination: {
            simple: 1,
            default: 2,
            full: 3
        },
        paginationValue: 1,
        dfp_slots: {
          'div-gpt-ad-1571972845074-0': { // 不明
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580282482771-0': { // 不明
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580355841225-0': { // circles
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580353644223-0': { // spots
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580356736019-0': { // events
            336: [336, 280]
          },
          'div-gpt-ad-1580361234575-0': { // footer
            300: [300, 250]
          },
        }
      }
    }
  }
</script>

