<template>
    <div v-if="this.no_content" class="wrapper presentation-page no-content">
        <div class="mx-auto text-center">
            <div class="spinner"></div>
            <div class="space-50"></div>
        </div>
    </div>
    <div v-else class="wrapper presentation-page">

        <vue-backTop v-if="false"></vue-backTop>
        <vue-backTop v-if="true" :height="600" :bottom="50" :right="30" @onClick="" @complete="">
            <div class="backtop">
                <i class="fa fa-spade display-5 text-info"></i>
            </div>
        </vue-backTop>


        <div id="poker-spot" data-background-color="white">
            <div class="space-100"></div>
            <div class="container">
                <div class="mb-5">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }"><span class="text-black-50"><i class="fa fa-home"></i></span></el-breadcrumb-item>
                        <el-breadcrumb-item>
                          <span class="text-black-50">お知らせ一覧</span>
                        </el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>


            <div class="container" v-if="true">
                <div class="mb-0 text-right">
                    <social-sharing
                        :url="currentURL()"
                        title="お知らせ一覧 | ポーカーナビ - 全国のポーカースポット、サークル、トーナメント情報を配信！"
                        description="ポーカーナビからのお知らせ一覧です"
                        quote="ポーカーナビからのお知らせ一覧です"
                        :hashtags="getHashtags()"
                        twitter-user="PokerNavi_JP"
                        inline-template>
                        <div>
                            <network network="facebook">
                                <div class="sns_share fb">
                                    <i class="fab fa-facebook-f mr-1" style="color:#fff"></i>
                                    <span class="font-weight-bold" style="font-size:.8rem">Share</span>
                                </div>
                            </network>
                            <network network="twitter">
                                <div class="sns_share twitter">
                                    <i class="fab fa-twitter mr-1" style="color:#fff"></i>
                                    <span class="font-weight-bold" style="font-size:.7rem;vertial-align:middle">ツイート</span>
                                </div>
                            </network>
                        </div>
                    </social-sharing>
                </div>
                <h6 class="with-title-dark">お知らせ一覧</h6>
                <div class="row">

                    <div v-if="announcements.length == 0" class="mx-auto">
                        <pre class="text-center">お知らせはありません</pre>
                    </div>

                    <div v-else class="container">
                        <div class="w-100">
                            <div v-if="meta.total_pages > 1" class="pagination-container justify-content-center mb-3">
                                <announcement-pagination type="primary"
                                              :total="meta.count"
                                              :value="meta.current_pages"
                                              :page-count="meta.total_pages"
                                              v-model="pagination.simple"
                                              @fav="addToFavorite"
                                              ></announcement-pagination>
                            </div>
                            <div v-else class="space-20"></div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 mx-auto">

                                <div class="table-responsive pn-tables mb-4 text-black">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th class="font-weight-bold text-center">更新日時</th>
                                                <th class="font-weight-bold text-center">タイトル</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="announcement in announcements" @click="announcementRowClick(announcement)">
                                                <td class="w-25">
                                                    <div class="px-2">
                                                        <span v-if="announcement.pubdate !== null && announcement.pubdate !== ''" class="small">
                                                        {{ announcement.pubdate | pubdate_format }}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td class="w-75">
                                                    <div class="px-2">
                                                        <div class="my-2">
                                                            <badge v-if="announcement.article_type.id == 2 || announcement.article_type.id == 4" type="info">
                                                                {{ announcement.article_type.name }}
                                                            </badge>
                                                            <badge v-else-if="announcement.article_type.id == 1 || announcement.article_type.id == 3" type="primary">
                                                                {{ announcement.article_type.name }}
                                                            </badge>
                                                        </div>
                                                        <span v-if="announcement.title !== null && announcement.title !== ''">
                                                            {{ announcement.title }}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div v-if="true" class="w-100">
                            <div v-if="meta.total_pages > 1" class="pagination-container justify-content-center mb-3">
                                <announcement-pagination type="primary"
                                              :total="meta.count"
                                              :value="meta.current_pages"
                                              :page-count="meta.total_pages"
                                              v-model="pagination.simple"
                                              @fav="addToFavorite"
                                              ></announcement-pagination>
                            </div>
                            <div v-else class="space-20"></div>
                        </div>

                    </div>

                </div>
            </div>

        </div>

        <div class="pt-4 mb-3">
            <div v-if="true" class="adsense-infeed text-center">
                <dfp-slot v-if="true"
                    path="/21867335047/pokernavi/pokernavi-footer"
                    :size="dfpSlotSize('div-gpt-ad-1580361234575-0')"
                    id="div-gpt-ad-1580361234575-0"
                />
            </div>
        </div>

    </div>
</template>

<script>
  import {
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Card,
    NukButton,
    Pagination,
    AnnouncementPagination,
  } from '@/components';
  import {
    Button,
    ButtonGroup,
    Loading
  } from 'element-ui';
  import axios from "axios";
  import dayjs from 'dayjs'
  import {API_ENDPOINT} from '@/constants'
  import DfpSlot from '../components/DfpSlot.vue'

  export default {
    name: 'announcements',
    bodyClass: 'presentation-page',
    components: {
      Badge,
      Card,
      [NukButton.name]: NukButton,
      [Button.name]: Button,
      [ButtonGroup.name]: ButtonGroup,
      [Breadcrumb.name]: Breadcrumb,
      [BreadcrumbItem.name]: BreadcrumbItem,
      [Pagination.name]: Pagination,
      [AnnouncementPagination.name]: AnnouncementPagination,
      DfpSlot
    },
    methods: {
      dfpSlotSize: function(key) {
        let dfp_slots_sizes = this.dfp_slots[key]
        return dfp_slots_sizes['300']
      },
      announcementRowClick: function(announcement) {
        if (announcement.link_target == 2) {
          // 内部リンク
          var relative_path = announcement.internal_link.replace(/(https?:\/\/[^\/]+\/)/g, '')
          this.$router.push(relative_path)
        } else if (announcement.link_target == 3) {
          // 外部リンク
          window.open(announcement.external_link, "_blank");
        } else {
          // お知らせ詳細
          this.$router.push({ name: 'announcement', params: { id: announcement.id } });
        }
      },
      changePage: function(e) {
        this.getPosts();
      },
      clickCallback: function(pageNum) {
      },
      getPosts () {
        let endpoint = this.baseUrl+'announcements/'
        document.title = 'お知らせ一覧 | ポーカーナビ - 全国のポーカースポット、サークル、トーナメント情報を配信！'
        axios
          .get(endpoint)
          .then(response => {
            this.meta = response.data.meta
            this.announcements = response.data.announcements
            this.no_content = false
          })
      },
      setPages () {
        let numberOfPages = Math.ceil(this.announcements.length / this.perPage);
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      addToFavorite: function(arrayPosts) {
        this.announcements = arrayPosts
      },
    },
    async created() {
      try {
        this.loadingInstance = Loading.service({ fullscreen: true });
        this.getPosts();
      } catch (e) {
        console.error(e)
      } finally {
        this.loadingInstance.close();
      }
    },
    watch: {
      posts() {
        this.setPages();
      },
      announcements: function() {
        this.$nextTick(() => {
            var positionY = sessionStorage.getItem('positionY')
            scrollTo(0, positionY);
            setTimeout(function(){
                scrollTo(0, positionY);
                }, 500);
            })
        this.no_content = (this.announcements == null)
        document.title = 'お知らせ一覧 | ポーカーナビ - 全国のポーカースポット、サークル、トーナメント情報を配信！'
      }
    },
    filters: {
      pubdate_format: function (date) {
        if (date == null || date == '') {
          return '---';
        }
        return dayjs(date).format('YYYY年MM月DD日 HH:mm');
      },
    },
    data() {
      return {
        no_content: true,
        announcements: [],
        meta: {},
        loadingInstance: null,
        page: {
          title: '',
          description: ''
        },
        baseUrl: API_ENDPOINT,
        currentPage: 1,
        pagination: {
            simple: 1,
            default: 2,
            full: 3
        },
        paginationValue: 1,
        dfp_slots: {
          'div-gpt-ad-1571972845074-0': { // 不明
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580282482771-0': { // 不明
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580355841225-0': { // circles
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580353644223-0': { // spots
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580356736019-0': { // events
            336: [336, 280]
          },
          'div-gpt-ad-1580361234575-0': { // footer
            300: [300, 250]
          },
        },
      }
    }
  }
</script>

<style scoped lang="scss">
</style>
