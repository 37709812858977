<template>
    <div class="wrapper presentation-page">

        <div id="poker-spot" data-background-color="white">
            <div class="space-100"></div>
            <div class="container">
                <div class="mb-5">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }"><span class="text-black-50"><i class="fa fa-home"></i></span></el-breadcrumb-item>
                        <el-breadcrumb-item><span class="text-black-50">プライバシーポリシー</span></el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
            <div v-html="content_html" />

            <div v-if="false" class="cards">
                <div class="container">
                    <h6 class="with-title-dark">プライバシーポリシー</h6>
                    <div class="row">
                        <div class="col-12">
                            <h6 class="mb-3">1. 個人情報の収集</h6>
                            <p class="card-description mb-5">Ａｔｔａｉｎ合同会社（以下、「当社」といいます。）は、お客様に当社サービスをご利用いただく際に、お客様の個人情報をお預かりする場合がございます。
本ポリシーにおいて、個人情報とは、各サービスを通じてお客様からお預かりする氏名、住所、生年月日、電話番号、電子メールアドレス等のお客様個人を識別できる情報およびお客様固有の情報を意味します。
当社は、お客様から個人情報をお預かりする場合は、サービス毎に、利用目的、利用方法、利用範囲、お客様に対する当社の窓口、当社がお客様の個人情報を提供する提携会社（お預かりした個人情報を当社と共有する会社）の範囲等をあらかじめ通知し、またはウェブサイト等に公表したうえで、必要な範囲の個人情報をお預かりいたします。
</p>
                            <h6 class="">2. 個人情報の利用目的</h6>
                            <p class="card-description mb-5">当社では、お客様からお預かりした個人情報を、サービス毎に通知または公表した目的で利用しております。当社は、この利用目的の範囲を超えて、お客様の同意なく、個人情報を利用いたしません。 なお、当社では、お預かりした個人情報を年齢分布、趣味嗜好分布、利用履歴等で統計的に処理したうえで第三者に提供し、または一般公開する場合がございますが、これらの情報は、個人を特定できるものではございません。</p>
                            <h6 class="">3. 個人情報の提供・開示</h6>
                            <p class="card-description mb-5">当社は、お客様の同意なく、第三者に個人情報を提供・開示いたしません。<br />
当社は、あらかじめ利用規約等によりお客様の同意を得て、各サービスに必要な範囲内で、当社の提携会社および業務委託先に個人情報を開示する場合がございます。この場合、当該提携会社および業務委託先が個人情報を漏洩し、再提供する等、あらかじめ定められた利用目的を超えて利用しないように秘密保持契約を締結する等の適切な措置を講じております。<br />また、当社は、裁判所、検察庁、警察等の公的機関の要請、または法令に基づく情報の開示・提供の要請があった場合に限り、これに協力する場合がございます。
</p>
                            <h6 class="">4. 個人情報の安全管理</h6>
                            <p class="card-description mb-5">当社は、お客様の個人情報の取り扱いに関し、情報の漏洩、滅失、毀損、改ざん、不正アクセス等の防止その他安全管理のために、最大限の努力をもって必要かつ適切な措置を講じております。<br />
当社は、個人情報の取り扱いをする従業員を、利用目的に応じて必要な範囲内に限定しており、サービス毎にお客様の個人情報を取り扱う管理責任者を置くことにより、必要な管理監督を行っております。
</p>
                            <h6 class="">5. 個人情報の訂正・利用停止</h6>
                            <p class="card-description mb-5">当社は、個人情報を正確かつ最新の内容に保つよう努めます。
お客様の個人情報に関して訂正・利用停止をご希望の場合には、各サービスで提示する手順にしたがって、お申し出ください。当社にて、適切な本人確認を行った後、合理的な範囲ですみやかに対応いたします。<br />
また、当社サービスをご利用されなくなったお客さまの個人情報について、当社は、各サービスに関わる法令に定める保管期間、または各サービスの性質・内容に応じて合理的に設定した保管期間において保持する場合がございます。
</p>
                            <h6 class="">6. 未成年のお客様の個人情報</h6>
                            <p class="card-description mb-5">当社では、未成年のお客様のプライバシー保護についても最大限の注意を払います。未成年のお客様は、必ず保護者の方の同意のもとに、個人情報をご提供ください。</p>
                            <h6 class="">7. 個人情報の開示請求</h6>
                            <p class="card-description mb-5">お客様がご自身の個人情報の登録内容について照会を希望される場合は、各サービスの問い合わせ窓口までご本人様が直接お申し出ください。当社にて、適切な本人確認を行った後、合理的な範囲ですみやかに対応いたします。なお、手数料を別途いただく場合もございます。</p>
                            <h6 class="">8. 関連法令の遵守</h6>
                            <p class="card-description mb-5">当社は、当社が保有する個人情報の取り扱いにつき、日本の法令を遵守し、適宜その改善に努めてまいります。</p>
                        </div>
                    </div>
                    <div class="space-50"></div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
  import {
    Breadcrumb,
    BreadcrumbItem,
  } from '@/components';
  import axios from "axios";
  import {API_ENDPOINT} from '@/constants';

  export default {
    name: 'privacy',
    bodyClass: 'presentation-page',
    components: {
      [Breadcrumb.name]: Breadcrumb,
      [BreadcrumbItem.name]: BreadcrumbItem,
    },
    data() {
      return {
        content_html: ''
      }
    },
    async created() {
      try {
        document.title = 'プライバシーポリシー | ポーカーナビ - 全国のポーカースポット、サークル、トーナメント情報を配信！'

        await axios
          .get(API_ENDPOINT + `appconstant/5`)
          .then(response => {
            this.content_html = response.data.text_value
          })

      } catch (e) {
        this.content_html = '<div class="spinner"></div><div class="space-50"></div>'
        console.error(e)
      } finally {
        //
      }
    },
  }
</script>

