<template>
  <div :id="id"></div>
</template>
<script>
  export default {
    name: 'DfpSlot',
    props: {
      size: Array,
      path: String,
      id: String
    },
    beforeCreate() {
      window.googletag.cmd.push(() => {
//console.log('=================== before destroy')
        window.googletag.destroySlots();
      });
    },
    mounted() {
      const { path, size, id } = this;

/*
      const googletag = window['googletag'] || {};
      if (window['googletag'] && googletag.apiReady) {
        googletag.cmd = googletag.cmd || [];

//console.log('before destroy')
        googletag.destroySlots();
        googletag.cmd.push(() => {
//console.log('inside push')
          window.googletag.defineSlot(path, size, id).addService(window.googletag.pubads());
//console.log('after define')
          window.googletag.pubads().enableSingleRequest();
//console.log('after enableSingleReq')
          window.googletag.enableServices();
//console.log('after display')
          window.googletag.display(id);

          //googletag.pubads().setTargeting('site', 'weatherinnovations');

          //googletag.defineSlot('/21867335047/pokernavi/pokernavi-top-events', [[300, 250], [336, 280]], 'div-gpt-ad-1580356736019-0').addService(googletag.pubads());
          //googletag.pubads().collapseEmptyDivs(true, true);
          //googletag.pubads().enableSingleRequest();
          //googletag.enableServices();
        });
        //googletag.display('/21867335047/pokernavi/pokernavi-top-spots');

      }
*/

      window.googletag.cmd.push(() => {
//console.log('==================================== inside push')
        window.googletag.defineSlot(path, size, id).addService(window.googletag.pubads());
//console.log('==================================== after define')
        //window.googletag.pubads().enableSingleRequest();
//console.log('==================================== after enableSingleReq')
        window.googletag.enableServices();
//console.log('==================================== after enableService')
        window.googletag.display(id);
      });

    },
  };
</script>
