<template>
    <div class="wrapper presentation-page">

        <vue-backTop :height="600" :bottom="110" :right="30" @onClick="" @complete="">
            <div class="backtop"><i class="fa fa-spade display-5 text-info"></i></div>
        </vue-backTop>

        <router-link v-if="this.is_view_sales_banner" :to="{ name: 'ticket' }" tag="a" class="register-wrap">
            <div class="register">
               <span>イ</span>
               <span>ベ</span>
               <span>ン</span>
               <span>ト</span>
               <span>チ</span>
               <span>ケ</span>
               <span>ッ</span>
               <span>ト</span>
            </div>
        </router-link>

        <transition name="fade" v-if="show && sitejack.banner_footer !== null && sitejack.banner_footer !== ''">
            <div class="footer-advertisement">
                <div class="fa-close" @click="show = !show"></div>
                <div style="width:100%;height:90px" @click="linkable(sitejack.banner_footer_link)"></div>
            </div>
        </transition>
        <component is="style">
          .footer-advertisement::before {
            background-image: url( {{sitejack.banner_footer}} );
            background-color: rgba(244,244,244,.5);
            background-repeat: no-repeat;
            background-position: bottom center;
            background-size: contain;
            content: "";
            display: block;
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 90px;
            cursor: pointer;
          }
        </component>

        <div class="container-fluid">
            <div class="row">

                <div class="page-header clear-filter col-12" filter-color="white">
                    <div class="rellax-header rellax-header-sky">
                        <div v-if="sitejack.banner_header !== null && sitejack.banner_header !== ''"
                             class="page-header-image"
                             :style="'background-image: url(' + sitejack.banner_header + ') !important'">
                        </div>
                        <div v-else
                             class="page-header-image"
                             style="background-image: url('https://storage.googleapis.com/pokernavi/images/shutterstock_1027709941_1-min.jpg') !important">
                        </div>
                    </div>
                    <div class="rellax-text-container rellax-text">
                        <img src="https://storage.googleapis.com/pokernavi/img/pokernavi_wh.png" alt="ポーカーナビ" class="n-logo" />
                        <div class="mx-auto mb-3">
                            <el-button type="primary" icon="" class="el-button-event" @click="scrollToEvent"><i class="fa fa-trophy ml-1 mr-2"></i>イベント</el-button>
                        </div>
                        <div class="mx-auto mb-3">
                            <div class="text-center">
                                <el-button-group>
                                    <el-button type="primary" icon="" class="" @click="scrollToSpot"><i class="fa fa-home mr-2"></i>スポット</el-button>
                                    <el-button type="primary" @click="scrollToCircle"><i class="fa fa-user-friends mr-2"></i>サークル</el-button>
                                    <el-button type="primary" @click="scrollToVideo"><i class="fab fa-youtube ml-2 mr-2"></i><span class="mr-2">動画</span></el-button>
                                </el-button-group>
                            </div>
                        </div>
                        <div v-if="sitejack.button_header !== null && sitejack.button_header !== ''" class="mx-auto mt-5">
                            <el-button
                              type="info"
                              class="el-button-sitejack"
                              @click="linkable(sitejack.button_header_link)"
                            >{{sitejack.button_header}}
                            </el-button>
                        </div>
                    </div>
                </div>

                <div class="section section-components col-12" data-background-color="white"
                     :style="`background-color: ${sitejack.background_color}`">
                    <div class="container py-3">
                        <el-breadcrumb
                            separator-class="el-icon-arrow-right"
                            :style="`color: ${sitejack.text_color} !important;`">
                            <el-breadcrumb-item>
                                <span :style="`color: ${sitejack.text_color} !important;`"><i class="fa fa-home"></i></span>
                            </el-breadcrumb-item>
                            <el-breadcrumb-item>
                                <span :style="`color: ${sitejack.text_color} !important;`">TOP</span>
                            </el-breadcrumb-item>
                        </el-breadcrumb>

                        <div class="text-right">
                            <social-sharing
                                :url="currentURL()"
                                title="ポーカーナビ | 全国のポーカースポット、サークル、トーナメント情報を配信！"
                                description="ポーカーナビ |全国のポーカースポット、サークル、トーナメント情報を配信！"
                                quote="ポーカーナビ |全国のポーカースポット、サークル、トーナメント情報を配信！"
                                :hashtags="getHashtags()"
                                twitter-user="PokerNavi_JP"
                                inline-template>
                                <div>
                                    <network network="facebook">
                                        <div class="sns_share fb">
                                            <i class="fab fa-facebook-f mr-1" style="color:#fff"></i>
                                            <span class="font-weight-bold" style="font-size:.8rem">Share</span>
                                        </div>
                                    </network>
                                    <network network="twitter">
                                        <div class="sns_share twitter">
                                            <i class="fab fa-twitter mr-1" style="color:#fff"></i>
                                            <span class="font-weight-bold" style="font-size:.7rem;vertial-align:middle">ツイート</span>
                                        </div>
                                    </network>
                                </div>
                            </social-sharing>
                        </div>
                    </div>

                    <div v-for="(alert, index) in alerts">
                        <alert v-if="alert.article_type.id === 2" type="primary" class="mb-1 py-2">
                            <div class="container">
                                <div class="">
                                    <a v-if="alert.link_target == 3" :href="alert.external_link" class="alert-link" target="_blank">
                                        <i class="far fa-external-link mr-3"></i>
                                        {{ alert.title }}
                                    </a>
                                    <router-link v-else-if="alert.link_target == 1" :to="{ name: 'announcement', params: { id: alert.id }}" tag="a" class="alert-link" >
                                        <a>{{ alert.title }}</a>
                                    </router-link>
                                    <router-link v-else-if="alert.link_target == 2" :to="alert.internal_link.replace(/(https?:\/\/[^\/]+\/)/g, '')" tag="a" class="alert-link" >
                                        <a>{{ alert.title }}</a>
                                    </router-link>
                                </div>
                            </div>
                        </alert>
                        <alert v-else-if="alert.article_type.id === 1 || alert.article_type.id === 3 || alert.article_type.id === 4" type="" class="mb-1 py-2" style="background-color:rgba(49, 97, 141, 0.7)">
                            <div class="container">
                                <div class="">
                                    <a v-if="alert.link_target == 3" :href="alert.external_link" class="alert-link" target="_blank">
                                        <i class="far fa-external-link mr-3"></i>
                                        {{ alert.title }}
                                    </a>
                                    <router-link v-else-if="alert.link_target == 1" :to="{ name: 'announcement', params: { id: alert.id }}" tag="a" class="alert-link" >
                                        <a>{{ alert.title }}</a>
                                    </router-link>
                                    <router-link v-else-if="alert.link_target == 2" :to="alert.internal_link.replace(/(https?:\/\/[^\/]+\/)/g, '')" tag="a" class="alert-link" >
                                        <a>{{ alert.title }}</a>
                                    </router-link>
                                </div>
                            </div>
                        </alert>
                    </div>

                </div>
            </div>
        </div>


        <div class="container-fluid">

            <div class="row flex-container d-flex"
                 data-background-color="white"
                 :style="`background-color: ${sitejack.background_color}`">

                <div class="side-content flex-column side-content-left"
                     :style="'background-image:url(' + sitejack.banner_left + ');background-attachment:fixed;background-size: contain;background-repeat: no-repeat;background-position: top 86px left 0px;'"
                     @click="linkable(sitejack.banner_left_link)">
                </div>
                <component v-if="false" is="style">
                  .side-content-left::before {
                    background-image: url( {{sitejack.banner_left}} );
                    background-repeat: no-repeat;
                    background-position: top 86px left 0px;
                    background-size: contain;
                    content: "";
                    display: block;
                    position: fixed;
                    bottom: 0;
                    left: 0;
                    //z-index: 9999;
                    width: 80px;
                    height: 100%;
                    //height: 90px;
                  }
                </component>

                <div class="container center-content flex-column" style="z-index:1">
                    <div class="section section-components mx-auto my-4 row">

                        <div class="container">
                            <div v-if="announcements_list.length > 0" class="row">
                                <div class="col-md-12 mx-auto">
                                    <div v-if="true" class="table-responsive pn-tables text-black">
                                        <table class="table">
                                            <thead>
                                            </thead>
                                            <tbody>
                                                <tr v-for="announcement in announcements_list" @click="announcementRowClick(announcement)">
                                                    <td class="w-25">
                                                        <div class="px-2">
                                                            <span v-if="announcement.pubdate !== null && announcement.pubdate !== ''" class="small">
                                                            {{ announcement.pubdate | yyyyMM }}<br />{{ announcement.pubdate | ddd }}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td class="w-75">
                                                        <div class="px-2 fixed-row">
                                                            <div class="my-2">
                                                                <badge v-if="announcement.article_type.id == 2 || announcement.article_type.id == 4" type="info">
                                                                    {{ announcement.article_type.name }}
                                                                </badge>
                                                                <badge v-else-if="announcement.article_type.id == 1 || announcement.article_type.id == 3" type="primary">
                                                                    {{ announcement.article_type.name }}
                                                                </badge>
                                                            </div>
                                                            <span v-if="announcement.title !== null && announcement.title !== ''">
                                                                {{ announcement.title }}
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="announcements_list.length > 0" id="announcement-link" class="text-center mx-auto">
                        <router-link :to="{ name: 'announcements', params: {}}" tag="a" class="front" >
                            <a class="btn btn-lg btn-simples btn-primary btn-rounds text-white"><i class="fa fa-share mr-2"></i>お知らせ一覧をみる</a>
                        </router-link>
                    </div>

                    <div id="poker-event" data-background-color="white" class="section section-components row" style="background:none">
                        <div class="space-100"></div>
                        <div class="container">
                            <h6 class="with-title-dark">開催情報</h6>
                            <div v-if="pickup_events_count >= 5" class="col-md-12 mb-5">
                                <div class="row">
                                    <div v-for="(event, index) in pickup_events.slice(0,5)" :key="event.id" class="col-md-1 col-lg-2 mb-3">
                                        <div v-if="event.charged && event.flyer !== null && event.flyer !== ''">
                                            <div class="card-container" :class="['card-' + (index+1)]">
                                                <div class="card-component">
                                                    <div @click="eventRowClick(event)" class="front">
                                                        <img v-lazy="event.flyer" alt="sections_thumb" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="pickup_events_count === 4" class="col-md-12 mb-5">
                                <div class="row">
                                    <div v-for="(event, index) in pickup_events.slice(0,4)" :key="event.id" class="col-md-3 col-lg-3 mb-3">
                                        <div v-if="event.charged && event.flyer !== null && event.flyer !== ''">
                                            <div class="card-container" :class="['card-' + (index+1)]">
                                                <div class="card-component">
                                                    <div @click="eventRowClick(event)" class="front">
                                                        <img v-lazy="event.flyer" alt="sections_thumb" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="pickup_events_count === 3" class="col-md-12 mb-5 mx-auto">
                                <div class="row">
                                    <div v-for="(event, index) in pickup_events.slice(0,3)" :key="event.id" class="col-md-3 col-lg-3 mb-3">
                                        <div v-if="event.charged && event.flyer !== null && event.flyer !== ''">
                                            <div class="card-container" :class="['card-' + (index+1)]">
                                                <div class="card-component">
                                                    <div @click="eventRowClick(event)" class="front">
                                                        <img v-lazy="event.flyer" alt="sections_thumb" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="pickup_events_count === 2" class="col-md-8 mb-5 mx-auto">
                                <div class="row">
                                    <div v-for="(event, index) in pickup_events.slice(0,2)" :key="event.id" class="col-md-6 col-lg-6 mb-3">
                                        <div v-if="event.charged && event.flyer !== null && event.flyer !== ''">
                                            <div class="card-container mx-auto" :class="['card-' + (index+1)]">
                                                <div class="card-component">
                                                    <div @click="eventRowClick(event)" class="front">
                                                        <img v-lazy="event.flyer" alt="sections_thumb" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="col-md-10 mb-5 mx-auto">
                                <div class="row">
                                    <div v-for="(event, index) in pickup_events.slice(0,1)" :key="event.id" class="col-md-12 col-lg-12 mb-3">
                                        <div v-if="event.charged && event.flyer !== null && event.flyer !== ''">
                                            <div class="card-container mx-auto" :class="['card-' + (index+1)]">
                                                <div class="card-component">
                                                    <div @click="eventRowClick(event)" class="front">
                                                        <img v-lazy="event.flyer" alt="sections_thumb" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 ml-auto mr-auto">
                                    <div v-if="true" class="table-responsive pn-tables mb-4 text-black">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th class="font-weight-bold">
                                                        <div class="fixed-row">決勝 FINAL</div>
                                                    </th>
                                                    <th class="font-weight-bold">
                                                        <div class="fixed-row">大会</div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="event in events_in_table" @click="eventRowClick(event)">
                                                    <td class="w-25">
                                                        <div v-if="event.end !== null" class="px-2 align-bottom">
                                                            <span class="small">{{ event.end | yyyyMM }}<br />{{ event.end | ddd }}</span>
                                                        </div>
                                                        <div v-else class="px-2 fixed-row align-bottom">
                                                            <span class="align-bottom">---</span>
                                                        </div>
                                                    </td>
                                                    <td class="w-75">
                                                        <div class="px-2">
                                                            <span v-if="event.title !== null && event.title !== ''">
                                                                {{ event.title }}
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="cards">
                                    <div v-if="events.length > 0" class="card-columns container mb-5">
                                        <div v-for="(event, i) in events" :key="event.id">
                                            <router-link v-if="event.unique_domain !== null && event.unique_domain !== ''" :to="'/' + event.unique_domain" tag="a">
                                                <a>
                                                    <card :color="setBgColor(event.charged ? event.thumbnail : '')"
                                                          :cardBodyClasses="addNewAtEvent(event)" >
                                                        <img v-if="event.charged && event.thumbnail !== null" slot="image" class="img rounded" v-lazy="event.thumbnail">
                                                        <h6 class="category text-primary font-weight-normal mb-4">
                                                            <small class="mr-1 " style="border:1px solid #ccc;padding:5px 10px;">決勝</small>
                                                            <span v-if="event.end !== null" class="py-1 px-2" style="border-bottom:1px solid #ccc">{{event.end | yyyyMMddd }}</span>
                                                            <span v-else class="py-1 px-2">---</span>
                                                        </h6>
                                                        <h5 class="card-title font-weight-bold">{{event.title}}</h5>
                                                        <p v-if="event.charged" class="card-description" v-html="$options.filters.readmore(event.overview, 60)"></p>
                                                    </card>
                                                </a>
                                            </router-link>
                                            <router-link v-else :to="{ name: 'event', params: { id: event.id }}" tag="a">
                                                <a>
                                                    <card :color="setBgColor(event.charged ? event.thumbnail : '')"
                                                          :cardBodyClasses="addNewAtEvent(event)" >
                                                        <img v-if="event.charged && event.thumbnail !== null" slot="image" class="img rounded" v-lazy="event.thumbnail">
                                                        <h6 class="category text-primary font-weight-normal mb-4">
                                                            <small class="mr-1 " style="border:1px solid #ccc;padding:5px 10px;">決勝</small>
                                                            <span v-if="event.end !== null" class="py-1 px-2" style="border-bottom:1px solid #ccc">{{event.end | yyyyMMddd }}</span>
                                                            <span v-else class="py-1 px-2">---</span>
                                                        </h6>
                                                        <h5 class="card-title font-weight-bold">{{event.title}}</h5>
                                                        <p v-if="event.charged" class="card-description" v-html="$options.filters.readmore(event.overview, 60)"></p>

                                                    </card>
                                                </a>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center mx-auto">
                                    <router-link :to="{ name: 'events', params: {}}" tag="a" class="front" >
                                        <a class="btn btn-lg btn-simples btn-primary btn-rounds text-white"><i class="fa fa-share mr-2"></i>すべての大会をみる</a>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="poker-spot" data-background-color="white" class="row" style="background:none">
                        <div class="space-90"></div>

                        <div class="container">
                            <h6 class="with-title-dark">ポーカースポット</h6>
                            <div class="row">
                                <div class="col-md-12 mb-0">

                                    <collapse :multiple-active="true" class="" :active-index="0" :animationDuration=0>
                                        <collapse-item no-icon name="1" class="mr-auto ml-auto mb-3">
                                            <h6 class="mb-0 text-black pl-3" slot="title"
                                                :style="`color: ${sitejack.text_color} !important;`">キーワードからさがす
                                                <span class="float-rights">
                                                    <i class="fa fa-chevron-down mr-2"
                                                       :style="`color: ${sitejack.text_color} !important;`"></i>
                                                </span>
                                            </h6>
                                            <n-button v-for="tag_spot in tag_spots_filtered" :key="tag_spot.id" type="" size="sm" simple>
                                                <router-link :to="{ name: 'spots', query: { tag: tag_spot.id }}">{{tag_spot.title}}</router-link>
                                            </n-button>
                                        </collapse-item>
                                    </collapse>

                                    <collapse :multiple-active="true" class="" :active-index="0" :animationDuration=0>
                                        <collapse-item no-icon name="1" class="mr-auto ml-auto mb-3">
                                            <h6 class="mb-0 pl-3" slot="title"
                                                :style="`color: ${sitejack.text_color} !important;`">五十音順でさがす
                                                <span class="float-rights">
                                                    <i class="fa fa-chevron-down mr-2"
                                                       :style="`color: ${sitejack.text_color} !important;`"></i>
                                                </span>
                                            </h6>
                                            <n-button type="default" size="sm" simple class="">
                                                <router-link :to="{ name: 'spots', query: { tag: 18 } }">あ行</router-link>
                                            </n-button>
                                            <n-button type="" size="sm" simple>
                                                <router-link :to="{ name: 'spots', query: { tag: 19 } }">か行</router-link>
                                            </n-button>
                                            <n-button type="" size="sm" simple>
                                                <router-link :to="{ name: 'spots', query: { tag: 20 } }">さ行</router-link>
                                            </n-button>
                                            <n-button type="" size="sm" simple>
                                                <router-link :to="{ name: 'spots', query: { tag: 21 } }">た行</router-link>
                                            </n-button>
                                            <n-button type="" size="sm" simple>
                                                <router-link :to="{ name: 'spots', query: { tag: 22 } }">な行</router-link>
                                            </n-button>
                                            <n-button type="" size="sm" simple>
                                                <router-link :to="{ name: 'spots', query: { tag: 23 } }">は行</router-link>
                                            </n-button>
                                            <n-button type="" size="sm" simple>
                                                <router-link :to="{ name: 'spots', query: { tag: 24 } }">ま行</router-link>
                                            </n-button>
                                            <n-button type="" size="sm" simple>
                                                <router-link :to="{ name: 'spots', query: { tag: 25 } }">や行</router-link>
                                            </n-button>
                                            <n-button type="" size="sm" simple>
                                                <router-link :to="{ name: 'spots', query: { tag: 26 } }">ら行</router-link>
                                            </n-button>
                                            <n-button type="" size="sm" simple>
                                                <router-link :to="{ name: 'spots', query: { tag: 27 } }">わ行</router-link>
                                            </n-button>
                                            <div class="collapse-inner-div"></div>
                                        </collapse-item>
                                    </collapse>

                                    <collapse :multiple-active="true" class="" :active-index="0" :animationDuration=0>
                                        <collapse-item no-icon name="1" class="mr-auto ml-auto mb-3">
                                            <h6 class="mb-0 text-black pl-3" slot="title"
                                                :style="`color: ${sitejack.text_color} !important;`">エリアからさがす
                                                <span class="float-rights">
                                                  <i class="fa fa-chevron-down mr-2"
                                                     :style="`color: ${sitejack.text_color} !important;`"></i>
                                                </span>
                                            </h6>
                                            <n-button type="" size="sm" simple>
                                                <router-link :to="{ name: 'spots', query: { tag: 7 } }">東京</router-link>
                                            </n-button>
                                            <n-button type="" size="sm" simple>
                                                <router-link :to="{ name: 'spots', query: { tag: 11 } }">大阪</router-link>
                                            </n-button>
                                            <n-button type="" size="sm" simple>
                                                <router-link :to="{ name: 'spots', query: { tag: 4 } }">北海道 東北 ( 青森 岩手 秋田 山形 宮城 福島 )</router-link>
                                            </n-button>
                                            <n-button type="" size="sm" simple>
                                                <router-link :to="{ name: 'spots', query: { tag: 5 } }">北関東 ( 茨城 栃木 群馬 埼玉 )</router-link>
                                            </n-button>
                                            <n-button type="" size="sm" simple>
                                                <router-link :to="{ name: 'spots', query: { tag: 6 } }">南関東 ( 神奈川 千葉 山梨 )</router-link>
                                            </n-button>
                                            <n-button type="" size="sm" simple>
                                                <router-link :to="{ name: 'spots', query: { tag: 8 } }">北陸信越 ( 新潟 富山 石川 福井 長野 )</router-link>
                                            </n-button>
                                            <n-button type="" size="sm" simple>
                                                <router-link :to="{ name: 'spots', query: { tag: 9 } }">東海 ( 岐阜 静岡 愛知 三重 )</router-link>
                                            </n-button>
                                            <n-button type="" size="sm" simple>
                                                <router-link :to="{ name: 'spots', query: { tag: 10 } }">近畿 ( 滋賀 京都 兵庫 奈良 和歌山 )</router-link>
                                            </n-button>
                                            <n-button type="" size="sm" simple>
                                                <router-link :to="{ name: 'spots', query: { tag: 12 } }">中国 ( 鳥取 島根 岡山 広島 山口)</router-link>
                                            </n-button>
                                            <n-button type="" size="sm" simple>
                                                <router-link :to="{ name: 'spots', query: { tag: 13 } }">四国 ( 徳島 香川 愛媛 高知 )</router-link>
                                            </n-button>
                                            <n-button type="" size="sm" simple>
                                                <router-link :to="{ name: 'spots', query: { tag: 14 } }">九州沖縄 ( 福岡 佐賀 長崎 熊本 大分 宮崎 鹿児島 沖縄 )</router-link>
                                            </n-button>
                                            <div class="collapse-inner-div"></div>
                                        </collapse-item>
                                    </collapse>

                                    <collapse :multiple-active="true" class="mb-3" :active-index="-1" :animationDuration=0>
                                        <collapse-item no-icon name="1" class="mr-auto ml-auto">
                                            <h6 class="mb-0 text-black pl-3" slot="title"
                                                :style="`color: ${sitejack.text_color} !important;`">都道府県からさがす
                                                <span class="float-rights">
                                                    <i class="fa fa-chevron-down mr-2"
                                                       :style="`color: ${sitejack.text_color} !important;`"></i>
                                                </span>
                                            </h6>
                                            <div v-for="area in areas ">
                                                <n-button v-for="prefecture in area.prefectures" :key="prefecture.id" type="" size="sm" simple>
                                                    <router-link :to="{ name: 'spots', query: { prefecture: prefecture.id }}">{{prefecture.name.replace(/県|府/, '').replace(/東京都/, '東京')}}</router-link>
                                                </n-button>
                                            </div>
                                            <n-button type="" size="sm" simple>
                                                <router-link :to="{ name: 'spots', query: { tag: 15 } }">オンライン</router-link>
                                            </n-button>
                                        </collapse-item>
                                    </collapse>
                                </div>

                                <!-- 375px以下のデバイス表示の場合 -->
                                <div v-if="false && isMinimum()" class="cards cards-narrow-device">
                                    <div v-if="spots.length > 0" class="card-columns container mb-2">
                                        <div v-for="(spot, i) in spots.slice(0,3)" :key="spot.id">
                                            <router-link v-if="spot.unique_domain !== null && spot.unique_domain !== ''" :to="spot.unique_domain" tag="a">
                                                    <card :color="spot.charged_type == 10 ? '' : (spot.charged_type == 2 ? 'gray' : 'orange')"
                                                          :cardBodyClasses="addNew(spot)" >
                                                        <img v-if="spot.thumbnail !== null" slot="image" class="img rounded" :src="spot.thumbnail" />
                                                        <h6 class="category text-primary font-weight-normal">
                                                            <span v-if="spot.prefecture !== null">
                                                                <i class="far fa-map-marker-alt mr-2"></i>{{spot.prefecture.name}} {{spot.city}}
                                                            </span>
                                                            <span v-if="spot.online">
                                                                <i class="far fa-globe mr-2"></i>オンライン
                                                            </span>
                                                        </h6>
                                                        <h5 class="card-title font-weight-bold">{{spot.name}}</h5>
                                                        <p v-if="spot.charged" class="card-description" v-html="$options.filters.readmore(spot.description, 60)"></p>
                                                    </card>
                                            </router-link>
                                            <router-link v-else :to="{ name: 'spot', params: { id: spot.id }}" tag="a">
                                                    <card :color="spot.charged_type == 10 ? '' : (spot.charged_type == 2 ? 'gray' : 'orange')"
                                                          :cardBodyClasses="addNew(spot)" >
                                                        <img v-if="spot.thumbnail !== null" slot="image" class="img rounded" :src="spot.thumbnail" />
                                                        <h6 class="category text-primary font-weight-normal">
                                                            <span v-if="spot.prefecture !== null">
                                                                <i class="far fa-map-marker-alt mr-2"></i>{{spot.prefecture.name}} {{spot.city}}
                                                            </span>
                                                            <span v-if="spot.online">
                                                                <i class="far fa-globe mr-2"></i>オンライン
                                                            </span>
                                                        </h6>
                                                        <h5 class="card-title font-weight-bold">{{spot.name}}</h5>
                                                        <p v-if="spot.charged" class="card-description" v-html="$options.filters.readmore(spot.description, 60)"></p>
                                                    </card>
                                            </router-link>
                                        </div>
                                    </div>
                                    <div class="adsense-infeed text-center mb-5" data-background-color="_gray">
                                        <dfp-slot
                                            path="/21867335047/pokernavi/pokernavi-top-spots"
                                            :size="dfpSlotSize('div-gpt-ad-1580353644223-0')"
                                            id="div-gpt-ad-1580353644223-0"
                                        />
                                    </div>
                                    <div v-if="spots.length > 0" class="card-columns container mb-5">
                                        <div v-for="(spot, i) in spots.slice(3)" :key="spot.id">
                                            <router-link v-if="spot.unique_domain !== null && spot.unique_domain !== ''" :to="spot.unique_domain" tag="a">
                                                    <card :color="spot.charged_type == 10 ? '' : (spot.charged_type == 2 ? 'gray' : 'orange')"
                                                          :cardBodyClasses="addNew(spot)" >
                                                        <img v-if="spot.thumbnail !== null" slot="image" class="img rounded" :src="spot.thumbnail" />
                                                        <h6 class="category text-primary font-weight-normal">
                                                            <span v-if="spot.prefecture !== null">
                                                                <i class="far fa-map-marker-alt mr-2"></i>{{spot.prefecture.name}} {{spot.city}}
                                                            </span>
                                                            <span v-if="spot.online">
                                                                <i class="far fa-globe mr-2"></i>オンライン
                                                            </span>
                                                        </h6>
                                                        <h5 class="card-title font-weight-bold">{{spot.name}}</h5>
                                                        <p v-if="spot.charged" class="card-description" v-html="$options.filters.readmore(spot.description, 60)"></p>
                                                    </card>
                                            </router-link>
                                            <router-link v-else :to="{ name: 'spot', params: { id: spot.id }}" tag="a">
                                                    <card :color="spot.charged_type == 10 ? '' : (spot.charged_type == 2 ? 'gray' : 'orange')"
                                                          :cardBodyClasses="addNew(spot)" >
                                                        <img v-if="spot.thumbnail !== null" slot="image" class="img rounded" :src="spot.thumbnail" />
                                                        <h6 class="category text-primary font-weight-normal">
                                                            <span v-if="spot.prefecture !== null">
                                                                <i class="far fa-map-marker-alt mr-2"></i>{{spot.prefecture.name}} {{spot.city}}
                                                            </span>
                                                            <span v-if="spot.online">
                                                                <i class="far fa-globe mr-2"></i>オンライン
                                                            </span>
                                                        </h6>
                                                        <h5 class="card-title font-weight-bold">{{spot.name}}</h5>
                                                        <p v-if="spot.charged" class="card-description" v-html="$options.filters.readmore(spot.description, 60)"></p>
                                                    </card>
                                            </router-link>
                                        </div>
                                    </div>
                                    <div v-if="spots.length > 0" class="text-center">
                                        <router-link :to="{ name: 'spots', params: { tag_name: '全国', page: '1' }}" tag="a" class="front" >
                                            <a class="btn btn-lg btn-simples btn-primary btn-rounds text-white"><i class="fa fa-share mr-2"></i>ポーカースポットをもっとみる</a>
                                        </router-link>
                                    </div>
                                </div>

                                <!-- 376px以上のデバイス表示の場合 -->

                                <isotope v-if="true"
                                         ref="cpt"
                                         :options='isotopeOptions'
                                         v-images-loaded:on.progress="layout"
                                         :list="spots"
                                         class="card-columns container cards-wide-device mb-5" >
                                    <div class="grid-sizer"></div>
                                    <div v-for="(spot, i) in spots" :key="i" class="isotope-card">
                                        <div v-if="true && i == 4" class="adsense-infeed text-center mb-4" :key="'ad'+i">
                                            <dfp-slot
                                                path="/21867335047/pokernavi/pokernavi-top-spots"
                                                :size="dfpSlotSize('div-gpt-ad-1580353644223-0')"
                                                id="div-gpt-ad-1580353644223-0"
                                            />
                                        </div>

                                        <router-link v-if="spot.unique_domain !== null && spot.unique_domain !== ''" :to="spot.unique_domain" tag="a">
                                            <div style="background-color:white;"
                                                 class="card"
                                                 :data-background-color="spot.charged_type == 10 ? '' : (spot.charged_type == 2 ? 'gray' : 'orange')"
                                                 :key="i">
                                                <div v-if="spot.thumbnail !== null" class="card-image">
                                                    <img :src="spot.thumbnail" class="img rounded" />
                                                </div>
                                                <div class="card-body" :class="{ 'add-new' : addNew(spot) }">
                                                    <span v-if="addNew(spot)" class="dog-ear text-white"></span>
                                                    <h6 class="category text-primary font-weight-normal">
                                                        <span v-if="spot.prefecture !== null">
                                                            <i class="far fa-map-marker-alt mr-2"></i>{{spot.prefecture.name}} {{spot.city}}
                                                        </span>
                                                        <span v-if="spot.online">
                                                            <i class="far fa-globe mr-2"></i>オンライン
                                                        </span>
                                                    </h6>
                                                    <h5 class="card-title font-weight-bold">{{spot.name}}</h5>
                                                    <p v-if="spot.charged" class="card-description" v-html="$options.filters.readmore(spot.description, 60)"></p>
                                                </div>
                                            </div>
                                        </router-link>
                                        <router-link v-else :to="{ name: 'spot', params: { id: spot.id }}" tag="a">
                                            <div style="background-color:white;"
                                                 class="card"
                                                 :data-background-color="spot.charged_type == 10 ? '' : (spot.charged_type == 2 ? 'gray' : 'orange')"
                                                 :key="i">
                                                <div v-if="spot.thumbnail !== null" class="card-image">
                                                    <img :src="spot.thumbnail" class="img rounded" />
                                                </div>
                                                <div class="card-body" :class="{ 'add-new' : addNew(spot) }">
                                                    <span v-if="addNew(spot)" class="dog-ear text-white"></span>
                                                    <h6 class="category text-primary font-weight-normal">
                                                        <span v-if="spot.prefecture !== null">
                                                            <i class="far fa-map-marker-alt mr-2"></i>{{spot.prefecture.name}} {{spot.city}}
                                                        </span>
                                                        <span v-if="spot.online">
                                                            <i class="far fa-globe mr-2"></i>オンライン
                                                        </span>
                                                    </h6>
                                                    <h5 class="card-title font-weight-bold">{{spot.name}}</h5>
                                                    <p v-if="spot.charged" class="card-description" v-html="$options.filters.readmore(spot.description, 60)"></p>
                                                </div>
                                            </div>
                                        </router-link>

                                        <div v-if="false" style="background-color:white;" class="card" :key="'a'+i">
                                            <div v-if="spot.thumbnail !== null" class="card-image">
                                                <img :src="spot.thumbnail" class="img rounded" />
                                            </div>
                                            <div class="card-body">
                                                <span v-if="true" class="dog-ear text-white"></span>
                                                <h6 class="category text-primary font-weight-normal">
                                                    <span v-if="spot.prefecture !== null">
                                                        <i class="far fa-map-marker-alt mr-2"></i>{{spot.prefecture.name}} {{spot.city}}
                                                    </span>
                                                    <span v-if="spot.online">
                                                        <i class="far fa-globe mr-2"></i>オンライン
                                                    </span>
                                                </h6>
                                                <h5 class="card-title font-weight-bold">{{spot.name}}</h5>
                                                <p v-if="spot.charged" class="card-description" v-html="$options.filters.readmore(spot.description, 60)"></p>
                                            </div>
                                        </div>

                                        <card v-if="false" :color="spot.charged_type == 10 ? '' : (spot.charged_type == 2 ? 'gray' : 'orange')"
                                              :cardBodyClasses="addNew(spot)" >
                                            <img v-if="spot.thumbnail !== null" slot="image" class="img rounded" :src="spot.thumbnail" />
                                            <h6 class="category text-primary font-weight-normal">
                                                <span v-if="spot.prefecture !== null">
                                                    <i class="far fa-map-marker-alt mr-2"></i>{{spot.prefecture.name}} {{spot.city}}
                                                </span>
                                                <span v-if="spot.online">
                                                    <i class="far fa-globe mr-2"></i>オンライン
                                                </span>
                                            </h6>
                                            <h5 class="card-title font-weight-bold">{{spot.name}}</h5>
                                            <p v-if="spot.charged" class="card-description" v-html="$options.filters.readmore(spot.description, 60)"></p>
                                        </card>
                                    </div>

                                    <div v-if="false && spots.length > 0" class="card-columns container mb-2">
                                        <div v-for="(spot, i) in spots" :key="spot.id" 
                                             style="position:relative;-webkit-column-break-inside:avoid;-webkit-backface-visibiliy:hidden;">
                                            <iframe v-if="false" title="qiita" class="adsense-infeed mb-5" width="" height="" src="https://ja.wikipedia.org/wiki/Qiita">Qiitaとは</iframe>
                                            <div v-if="true && i == 4" class="adsense-infeed text-center mb-5" style="position:absolute;-webkit-column-break-inside:avoid;">
                                                <dfp-slot
                                                    path="/21867335047/pokernavi/pokernavi-top-spots"
                                                    :size="dfpSlotSize('div-gpt-ad-1580353644223-0')"
                                                    id="div-gpt-ad-1580353644223-0"
                                                />
                                            </div>
                                            <router-link v-if="spot.unique_domain !== null && spot.unique_domain !== ''" :to="spot.unique_domain" tag="a">
                                                    <card :color="spot.charged_type == 10 ? '' : (spot.charged_type == 2 ? 'gray' : 'orange')"
                                                          :cardBodyClasses="addNew(spot)" >
                                                        <img v-if="spot.thumbnail !== null" slot="image" class="img rounded" :src="spot.thumbnail" />
                                                        <h6 class="category text-primary font-weight-normal">
                                                            <span v-if="spot.prefecture !== null">
                                                                <i class="far fa-map-marker-alt mr-2"></i>{{spot.prefecture.name}} {{spot.city}}
                                                            </span>
                                                            <span v-if="spot.online">
                                                                <i class="far fa-globe mr-2"></i>オンライン
                                                            </span>
                                                        </h6>
                                                        <h5 class="card-title font-weight-bold">{{spot.name}}</h5>
                                                        <p v-if="spot.charged" class="card-description" v-html="$options.filters.readmore(spot.description, 60)"></p>
                                                    </card>
                                            </router-link>
                                            <router-link v-else :to="{ name: 'spot', params: { id: spot.id }}" tag="a">
                                                    <card :color="spot.charged_type == 10 ? '' : (spot.charged_type == 2 ? 'gray' : 'orange')"
                                                          :cardBodyClasses="addNew(spot)" >
                                                        <img v-if="spot.thumbnail !== null" slot="image" class="img rounded" :src="spot.thumbnail" />
                                                        <h6 class="category text-primary font-weight-normal">
                                                            <span v-if="spot.prefecture !== null">
                                                                <i class="far fa-map-marker-alt mr-2"></i>{{spot.prefecture.name}} {{spot.city}}
                                                            </span>
                                                            <span v-if="spot.online">
                                                                <i class="far fa-globe mr-2"></i>オンライン
                                                            </span>
                                                        </h6>
                                                        <h5 class="card-title font-weight-bold">{{spot.name}}</h5>
                                                        <p v-if="spot.charged" class="card-description" v-html="$options.filters.readmore(spot.description, 60)"></p>
                                                    </card>
                                            </router-link>
                                        </div>
                                    </div>
                                </isotope>
                                <div v-if="spots.length > 0" class="text-center mx-auto">
                                    <router-link :to="{ name: 'spots', params: { tag_name: '全国', page: '1' }}" tag="a" class="front" >
                                        <a class="btn btn-lg btn-simples btn-primary btn-rounds text-white"><i class="fa fa-share mr-2"></i>ポーカースポットをもっとみる</a>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div v-if="true && circles.length > 0" id="poker-circle" data-background-color="white" class="row" style="background:none">
                        <div class="space-100"></div>
                        <div class="container">
                            <h6 class="with-title-dark">ポーカーサークル</h6>
                            <div class="row">

                                <div v-if="tag_circles_filtered.length > 0" class="col-md-12">
                                    <collapse :multiple-active="true" class="" :active-index="0" :animationDuration=0>
                                        <collapse-item no-icon name="1" class="mr-auto ml-auto mb-3">
                                            <h6 class="mb-0 pl-3" slot="title"
                                                :style="`color: ${sitejack.text_color} !important;`">キーワードからさがす
                                                <i class="fa fa-chevron-down mr-2"
                                                   :style="`color: ${sitejack.text_color} !important;`"></i>
                                            </h6>
                                            <n-button v-for="tag_circle in tag_circles_filtered" :key="tag_circle.id" type="" size="sm" simple>
                                                <router-link :to="{ name: 'circles', query: { tag: tag_circle.id }}">{{tag_circle.title}}</router-link>
                                            </n-button>
                                        </collapse-item>
                                    </collapse>
                                </div>

                                <div class="cards">
                                    <div v-if="circles.length > 0" class="card-columns container mb-5">
                                        <div v-for="(circle, i) in circles" :key="circle.id" class="">
                                            <div v-if="false && (i == 4)" >
                                                <div class="adsense-infeed text-center mb-4" data-background-color="_gray">
                                                    <dfp-slot
                                                        path="/21867335047/pokernavi/pokernavi-top-circles"
                                                        :size="dfpSlotSize('div-gpt-ad-1580355841225-0')"
                                                        id="div-gpt-ad-1580355841225-0"
                                                    />
                                                </div>
                                            </div>
                                            <router-link v-if="circle.unique_domain !== null && circle.unique_domain !== ''" :to="circle.unique_domain" tag="a">
                                                <a>
                                                    <card :color="circle.contract_type == 10 ? '' : (circle.contract_type == 2 ? 'gray' : 'orange')"
                                                          :cardBodyClasses="addNew(circle)" >
                                                        <img v-if="circle.charged && circle.thumbnail !== null" slot="image" class="img rounded" v-lazy="circle.thumbnail">
                                                        <h6 class="category text-primary font-weight-normal">
                                                            <i class="far fa-map-marker-alt mr-2"></i>{{circle.prefecture.name}} {{circle.city}}
                                                        </h6>
                                                        <h5 class="card-title font-weight-bold">{{circle.name}}</h5>
                                                        <p v-if="circle.charged" class="card-description" v-html="$options.filters.readmore(circle.description, 60)"></p>
                                                    </card>
                                                </a>
                                            </router-link>
                                            <router-link v-else :to="{ name: 'circle', params: { id: circle.id }}" tag="a">
                                                <a>
                                                    <card :color="circle.contract_type == 10 ? '' : (circle.contract_type == 2 ? 'gray' : 'orange')"
                                                          :cardBodyClasses="addNew(circle)" >
                                                        <img v-if="circle.charged && circle.thumbnail !== null" slot="image" class="img rounded" v-lazy="circle.thumbnail">
                                                        <h6 class="category text-primary font-weight-normal">
                                                            <i class="far fa-map-marker-alt mr-2"></i>{{circle.prefecture.name}} {{circle.city}}
                                                        </h6>
                                                        <h5 class="card-title font-weight-bold">{{circle.name}}</h5>
                                                        <p v-if="circle.charged" class="card-description" v-html="$options.filters.readmore(circle.description, 60)"></p>
                                                    </card>
                                                </a>
                                            </router-link>
                                        </div>
                                    </div>
                                    <div v-if="circles.length > 0" class="text-center">
                                        <router-link :to="{ name: 'circles', params: {}}" tag="a" class="front" >
                                            <a class="btn btn-lg btn-simples btn-primary btn-rounds text-white"><i class="fa fa-share mr-2"></i>サークルをもっとみる</a>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="video_newests.length > 0 || video_recommends.length > 0" id="poker-video" data-background-color="white" class="row" style="background:none">
                        <div class="space-100"></div>
                        <div class="container">
                            <h6 class="with-title-dark">動画</h6>
                            <div class="row">

                                <div v-if="tag_videos.length > 0" class="col-md-12">
                                    <collapse :multiple-active="true" class="" :active-index="0" :animationDuration=0>
                                        <collapse-item no-icon name="1" class="mx-auto mb-3">
                                            <h6 class="mb-0 pl-3" slot="title"
                                                :style="`color: ${sitejack.text_color} !important;`">キーワードからさがす
                                                <i class="fa fa-chevron-down mr-2"
                                                   :style="`color: ${sitejack.text_color} !important;`"></i>
                                            </h6>
                                            <n-button v-for="tag_video in tag_videos_filtered" :key="tag_video.id" type="" size="sm" simple>
                                                <router-link :to="{ name: 'videos', query: { tag: tag_video.id }}">{{tag_video.title}}</router-link>
                                            </n-button>
                                        </collapse-item>
                                    </collapse>
                                </div>

                                <div v-if="video_newests.length > 0" class="container">
                                    <div class="">
                                        <h6 class="mb-3 pb-2 pl-3 video_h6" slot="title"
                                            :style="`color: ${sitejack.text_color} !important;`">新着
                                            <i class="fa fa-chevron-down float-right mr-2"
                                               :style="`color: ${sitejack.text_color} !important;`"></i>
                                        </h6>
                                    </div>
                                    <div class="container">
                                        <div class="row mb-5">
                                            <div v-for="(video, i) in video_newests.slice(0,1)" class="video-element-first-child">
                                                <router-link :to="{ name: 'video', params: { id: video.id }}" tag="div" class="">
                                                    <div class="video-thumb">
                                                        <img v-if="video.thumbnail !== null" class="" v-lazy="video.thumbnail">
                                                    </div>
                                                    <div class="px-3 py-2">
                                                        <h5 class="card-title font-weight-bold mt-3 mb-3">{{video.title}}</h5>
                                                        <p class="card-description">{{ video.description }}</p>
                                                    </div>
                                                </router-link>
                                            </div>

                                            <div v-for="(video, i) in video_newests.slice(1,5)" class="video-element">
                                                <router-link :to="{ name: 'video', params: { id: video.id }}" tag="div" class="">
                                                    <div class="video-thumb-wrap">
                                                        <div class="video-thumb">
                                                            <img v-if="video.thumbnail !== null" class="" v-lazy="video.thumbnail">
                                                        </div>
                                                    </div>
                                                    <div class="px-3 py-2 video-text">
                                                        <h5 class="card-title font-weight-bold mt-3 mb-3">{{video.title}}</h5>
                                                        <p class="card-description" v-html="$options.filters.readmore(video.description, 60)"></p>
                                                    </div>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="video_recommends.length > 0" class="container">
                                    <div class="">
                                        <h6 class="mb-3 pb-2 pl-3 video_h6" slot="title"
                                            :style="`color: ${sitejack.text_color} !important;`">おすすめ
                                            <i class="fa fa-chevron-down float-right mr-2"
                                               :style="`color: ${sitejack.text_color} !important;`"></i>
                                        </h6>
                                    </div>
                                    <div class="container">
                                        <div class="row mb-5">
                                            <div v-for="(video, i) in video_recommends.slice(0,1)" class="video-element-first-child">
                                                <router-link :to="{ name: 'video', params: { id: video.id }}" tag="div" class="">
                                                    <div class="video-thumb">
                                                        <img v-if="video.thumbnail !== null" class="" v-lazy="video.thumbnail">
                                                    </div>
                                                    <div class="px-3 py-2">
                                                        <h5 class="card-title font-weight-bold mt-3 mb-3">{{video.title}}</h5>
                                                        <p class="card-description">{{ video.description }}</p>
                                                    </div>
                                                </router-link>
                                            </div>
                                            <div v-for="(video, i) in video_recommends.slice(1,5)" class="video-element">
                                                <router-link :to="{ name: 'video', params: { id: video.id }}" tag="div" class="">
                                                    <div class="video-thumb-wrap">
                                                        <div class="video-thumb">
                                                            <img v-if="video.thumbnail !== null" class="" v-lazy="video.thumbnail">
                                                        </div>
                                                    </div>
                                                    <div class="px-3 py-2 video-text">
                                                        <h5 class="card-title font-weight-bold mt-3 mb-3">{{video.title}}</h5>
                                                        <p class="card-description" v-html="$options.filters.readmore(video.description, 60)"></p>
                                                    </div>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mx-auto text-center mb-5">
                                    <router-link :to="{ name: 'videos', params: {}}" tag="a" class="front" >
                                        <a class="btn btn-lg btn-simples btn-primary btn-rounds text-white"><i class="fa fa-share mr-2"></i>動画をもっとみる</a>
                                    </router-link>
                                </div>
                                <div class="space-50"></div>
                            </div>
                        </div>
                    </div>

                    <div v-if="true" class="section section-pricing row" id="sectionBuy" style="background:none">
                        <div class="container">

                            <div class="our-clients">
                                <div class="row mb-5">
                                    <div v-for="(banner, index) in banners.slice(0,4)" :key="banner.id" class="mb-3 col-sm-6 col-md-3 col-lg-3 mx-auto text-center">
                                        <a :href="banner.link" target="_blank" class="">
                                            <img v-lazy="banner.banner_image" :alt="banner.name"/>
                                        </a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div v-for="(banner, index) in banners_lower.slice(0,4)" :key="banner.id" class="mb-3 col-sm-6 col-md-3 col-lg-3 mx-auto text-center">
                                        <a :href="banner.link" target="_blank" class="">
                                            <img v-lazy="banner.banner_image" :alt="banner.name"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="our-clients">
                                <div class="row">
                                    <div class="adsense-infeed text-center mb-4" data-background-color="_gray" style="">
                                        <dfp-slot v-if="false"
                                            path="/21867335047/pokernavi/pokernavi-footer"
                                            :size="dfpSlotSize('div-gpt-ad-1580361234575-0')"
                                            id="div-gpt-ad-1580361234575-0"
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <div v-if="true" class="side-content flex-column"
                     :style="'background-image:url(' + sitejack.banner_right + ');background-attachment:fixed;background-size: contain;background-repeat: no-repeat;background-position: top 86px right 0px;'"
                     @click="linkable(sitejack.banner_right_link)">
                </div>

            </div>
        </div>

    </div>
</template>

<script>
  import {
    Alert,
    Breadcrumb,
    BreadcrumbItem,
    NukButton,
    Card,
    Badge,
    Collapse,
    CollapseItem,
  } from '@/components';
  import {
    Button,
    ButtonGroup,
    Loading,
  } from 'element-ui';
  import axios from "axios";
  import dayjs from 'dayjs'
  import {API_ENDPOINT} from '@/constants'
  import DfpSlot from './components/DfpSlot.vue';
  import imagesLoaded from 'vue-images-loaded'
  //import ImagesLoaded from 'imagesloaded';
  import Masonry from 'masonry-layout';
  import MagicGrid from 'vue-magic-grid';
  //import Vue from 'vue';
  import isotope from 'vueisotope'

  export default {
    name: 'presentation',
    bodyClass: 'presentation-page',
    components: {
      Alert,
      Badge,
      Card,
      Collapse,
      CollapseItem,
      [NukButton.name]: NukButton,
      [Button.name]: Button,
      [ButtonGroup.name]: ButtonGroup,
      [Breadcrumb.name]: Breadcrumb,
      [BreadcrumbItem.name]: BreadcrumbItem,
      DfpSlot,
      isotope
    },
    directives: {
      imagesLoaded
    },
    methods: {
      layout () {
        this.$refs.cpt.layout('masonry');
      },
      imagesLoaded: function(instance, image) {
console.log('============= imagesLoaded')
        const result = image.isLoaded ? 'loaded' : 'broken';
        console.log( 'image is ' + result + ' for ' + image.img.src );
        //this.$refs.cpt.layout('masonry');
/*
        //const grid = document.querySelector('.grid');
        return new ImagesLoaded(grid, () => {
          setTimeout(() => {
            this.masonry();
          }, 100);
        });
*/
      },
      masonry: function() {
        const grid = document.querySelector('.grid');
console.log('============= masonry')
        return new Masonry(grid, {
          percentPosition: true,
          itemSelector: '.grid-item',
        });
      },
      createGrid: function() {
console.log('============= createGrid')
        this.$nextTick(() => this.imagesLoaded());
        //this.imagesLoaded();
      },

      imageProgress: function(instance, image) {
        const result = image.isLoaded ? 'loaded' : 'broken';
        //console.log( 'image is ' + result + ' for ' + image.img.src );
      },
      addNew: function(spot) {
        let day7ago = dayjs(Date.now()).add(-7, 'days')
        if (spot.charged && (dayjs(spot.updated_at).diff(day7ago) > 0)) {
          return 'add-new';
        }
      },
      addNewAtEvent: function(event) {
        let day7ago = dayjs(Date.now()).add(-5, 'days')
        if (event.is_new && (dayjs(event.updated_at).diff(day7ago) > 0)) {
          return 'add-new';
        }
      },
      setBgColor: function(image) {
        if (!image) {
          return 'gray';
        }
      },
      scrollToEvent: function() {
        event.preventDefault()
        this.$SmoothScroll(
          document.querySelector('#poker-event'),
          400,
          null,
          null,
          'y'
        )
      },
      scrollToSpot: function() {
        event.preventDefault()
        this.$SmoothScroll(
          document.querySelector('#poker-spot'),
          400,
          null,
          null,
          'y'
        )
      },
      scrollToCircle: function() {
        event.preventDefault()
        this.$SmoothScroll(
          document.querySelector('#poker-circle'),
          400,
          null,
          null,
          'y'
        )
      },
      scrollToVideo: function() {
        event.preventDefault()
        this.$SmoothScroll(
          document.querySelector('#poker-video'),
          400,
          null,
          null,
          'y'
        )
      },
      dfpSlotSize: function(key) {
        let dfp_slots_sizes = this.dfp_slots[key]
        if (1200 <= window.innerWidth) {
          return dfp_slots_sizes['336']
        } else if(768 <= window.innerWidth && window.innerWidth <= 1199) {
          return dfp_slots_sizes['300']
        } else if(518 <= window.innerWidth && window.innerWidth <= 767) {
          return dfp_slots_sizes['336']
        } else if (375 <= window.innerWidth && window.innerWidth <= 517) {
          return dfp_slots_sizes['336']
        } else if (window.innerWidth <= 374) {
          return dfp_slots_sizes['300']
        }
      },
      isMinimum: function() {
        return window.innerWidth <= 375
      },
      announcementRowClick: function(announcement) {
        if (announcement.link_target == 2) {
          // 内部リンク
          var relative_path = announcement.internal_link.replace(/(https?:\/\/[^\/]+\/)/g, '')
          this.$router.push(relative_path)
        } else if (announcement.link_target == 3) {
          // 外部リンク
          window.open(announcement.external_link, "_blank");
        } else {
          // お知らせ詳細
          this.$router.push({ name: 'announcement', params: { id: announcement.id } });
        }
      },
      eventRowClick: function(event) {
        if (event.link_target_in_top == 2) {
          // 内部リンク
          var relative_path = event.internal_link.replace(/(https?:\/\/[^\/]+\/)/g, '')
          this.$router.push(relative_path)
        } else if (event.link_target_in_top == 3) {
          // 外部リンク
          window.open(event.external_link, "_blank");
        } else {
          // お知らせ詳細 or テーブル表示
          if (event.unique_domain !== null && event.unique_domain !== '') {
            this.$router.push('/' +event.unique_domain);
          } else {
            this.$router.push({ name: 'event', params: { id: event.id } });
          }
        }
      },
      linkable: function(url) {
        if (!url) return;
        window.open(url, "_blank");
      },
      runScript: function() {
        const scripts = this.$el.querySelectorAll('script')
        scripts.forEach(script => {
          const parentNode = script.parentNode
          let alternativeNode

          //if (script.src.indexOf('https://rot0.a8.net/') !== -1) {
            alternativeNode = document.createElement('iframe')
            alternativeNode.src = URL.createObjectURL(new Blob(['<!DOCTYPE html><title></title>' + script.outerHTML], {type: 'text/html'}))
            alternativeNode.onload = () => {
              alternativeNode.height = alternativeNode.contentDocument.body.scrollHeight + 50
            }
          //} else {
          //  alternativeNode = document.createElement('script')
          //  alternativeNode.src = script.src
          //}
          parentNode.replaceChild(alternativeNode, script)
        })
      }
    },
/*
    beforeCreate() {
    },
*/
    async created() {
      try {
        document.title = 'ポーカーナビ | 全国のポーカースポット、サークル、トーナメント情報を配信！'
        //this.show = true;
        //this.loadingInstance = Loading.service({ fullscreen: true });

        // sitejack ------------
        await axios
          .get(API_ENDPOINT + `sitejack/`)
          .then(response => {
            if (typeof response.data.sitejacks !== 'undefined' && response.data.sitejacks.length > 0) {
              this.sitejack = response.data.sitejacks[0]
            }
          })

        // spots ----------------
        await axios
          .get(API_ENDPOINT + `spots/?page=1&random`)
          .then(response => {
            this.spots = response.data.spots
            //this.$redrawVueMasonry()
            //Vue.use(MagicGrid);

/*
            let magicGrid = new MagicGrid({
container: '.posts-list',
animate: true,
gutter: 30,
static: true,
useMin: true
});
*/

//magicGrid.listen();

            /*
            this.$redrawVueMasonry();

            var grid = document.querySelector('.grid');
            var msnry = new Masonry( grid, {
              itemSelector: '.grid-item',
            });
            */
          })

        // videos ------------
        await axios
          .get(API_ENDPOINT + `videos/?newest`)
          .then(response => {
            this.video_newests = response.data.videos
          })
        await axios
          .get(API_ENDPOINT + `videos/?recommend`)
          .then(response => {
            this.video_recommends = response.data.videos
          })

        // announcements ----------
        await axios
          .get(API_ENDPOINT + `announcements/?pickup`)
          .then(response => {
            this.announcements = response.data;
            this.announcements_list = response.data.filter(function(i) {
              return i.published && i.top_view == 3;
            });
            this.alerts = response.data.filter(function(i) {
              return i.published && i.top_view == 2;
            });
          })

        // events ---------------
        await axios
          .get(API_ENDPOINT + `events?latest`)
          .then(response => {
            this.list_events = response.data.filter(function(i) {
              return i.list_view
            })
          })
        await axios
          .get(API_ENDPOINT + `events/?pickup_future`)
          .then(response => {
            this.pickup_future_events = response.data.events
            axios
              .get(API_ENDPOINT + `events/?pickup_expired`)
              .then(response => {
                this.pickup_expired_events = response.data.events
                this.pickup_events = this.pickup_future_events.concat(this.pickup_expired_events)
                this.pickup_events_count = this.pickup_events.length
              })
          })
        await axios
          .get(API_ENDPOINT + `events/?table_in_future`)
          .then(response => {
            this.future_events = response.data
            axios
              .get(API_ENDPOINT + `events/?table_expired`)
              .then(response => {
                this.expired_events = response.data
                this.events_at_top = this.future_events.concat(this.expired_events)

                this.events_in_table = this.events_at_top.filter(function(i){
                  return i.list_view
                })
                this.events = this.events_at_top.filter(function(i) {
                  return i.card_view
                })
              })
          })

        // layout ------------
        await axios
          .get(API_ENDPOINT + `appconstant/1`)
          .then(response => {
            this.is_view_sales_banner = response.data.bool_value
          })

      } catch (e) {
        console.error(e)
      } finally {
        //this.loadingInstance.close();
      }

      // 以下はFirstViewで表示されない領域なので、ローディング表示中の制約から外す

      // prefectures ----------
      await axios
        .get(API_ENDPOINT + `area`)
        .then(response => (this.areas = response.data))

      // tag_spots ------------
      await axios
        .get(API_ENDPOINT + `tags/?attribute=1`)
        .then(response => {
          this.tag_spots = response.data
        })

      // circles ------------
      await axios
        .get(API_ENDPOINT + `organizations/?top`)
        .then(response => {
          this.circles = response.data.organizations
        })

      // tag_circles ------------
      await axios
        .get(API_ENDPOINT + `tags/?attribute=2`)
        .then(response => {
          this.tag_circles = response.data
        })

      // tag_videos ------------
      await axios
        .get(API_ENDPOINT + `tags/?attribute=4`)
        .then(response => {
          this.tag_videos = response.data
        })


      // banners ----------------
      await axios
        .get(API_ENDPOINT + `banners`)
        .then(response => {
          this.banners = response.data.banners
        })
      await axios
        .get(API_ENDPOINT + `banners/?lower`)
        .then(response => {
          this.banners_lower = response.data
        })

    },
    computed: {
      tag_spots_filtered: function() {
        // タグへ表示(get_rid_of_tags=False)、且つ"キーワードから探す"へ表示(view_in_list=True)のものだけ表示
        return this.tag_spots.filter(function(i) {
          return i.view_in_list;
        })
      },
      tag_circles_filtered: function() {
        return this.tag_circles.filter(function(i) {
          return i.view_in_list;
        })
      },
      tag_videos_filtered: function() {
        return this.tag_videos.filter(function(i) {
          return i.view_in_list;
        })
      },
    },
/*
    beforeCreate() {
      window.googletag.cmd.push(() => {
        window.googletag.destroySlots();
      });
    },
*/
    mounted() {
      const hash = this.$route.hash
      if (hash && hash.match(/^#.+$/)) {
          this.$SmoothScroll(
            document.querySelector(hash),
            400,
            null,
            null,
            'y'
          )
      }

      // Google Ad Manager
/*
      googletag.destroySlots();
      window.googletag.cmd.push(() => {
        window.googletag.destroySlots();
      });
*/

/*
      const googletag = window['googletag'] || {};

      if (window['googletag'] && googletag.apiReady) {
        googletag.cmd = googletag.cmd || [];
        googletag.cmd.push(() => {
          //googletag.pubads().setTargeting('site', 'weatherinnovations');

          googletag.destroySlots();
          googletag.defineSlot('/21867335047/pokernavi/pokernavi-top-events', [[336, 280]], 'div-gpt-ad-1580356736019-0').addService(googletag.pubads());
          googletag.pubads().collapseEmptyDivs(true, true);
          googletag.pubads().enableSingleRequest();
          googletag.enableServices();
          googletag.display('div-gpt-ad-1580356736019-0');
        });

        googletag.cmd.push(() => {
          //googletag.pubads().setTargeting('site', 'weatherinnovations');

          googletag.defineSlot('/21867335047/pokernavi/pokernavi-top-spots', [[300, 250], [336, 280]], 'div-gpt-ad-1580353644223-0').addService(googletag.pubads());
          googletag.pubads().collapseEmptyDivs(true, true);
          googletag.pubads().enableSingleRequest();
          googletag.enableServices();
        });
        //googletag.display('/21867335047/pokernavi/pokernavi-top-spots');
      }
*/

/*
      window.googletag.cmd.push(() => {
        window.googletag.defineSlot(path, size, id)
          .addService(window.googletag.pubads());
        window.googletag.pubads().enableSingleRequest();
        window.googletag.enableServices();
        window.googletag.display(id);
      });
*/


/*
      VS2.load('https://rot0.a8.net/jsa/94c1f99c9867b2f177529215a0fde259/c6f057b86584942e415435ffb1fa93d4.js').then(function () {
      (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://rot0.a8.net/jsa/94c1f99c9867b2f177529215a0fde259/c6f057b86584942e415435ffb1fa93d4.js';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'a8tag'));
        VS2.load('https://rot0.a8.net/jsa/94c1f99c9867b2f177529215a0fde259/c6f057b86584942e415435ffb1fa93d4.js')
      })
*/
/*
      (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://rot0.a8.net/jsa/94c1f99c9867b2f177529215a0fde259/c6f057b86584942e415435ffb1fa93d4.js';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'a8tag'));
*/
    },
    updated() {
      //this.runScript();

      this.$nextTick(()=>{
        //googletag.cmd.push(function() {googletag.display('div-1');});
        //googletag.pubads().refresh();
      });
      //let ckeditor = document.createElement('script');
      //ckeditor.setAttribute('src',"//rot0.a8.net/jsa/94c1f99c9867b2f177529215a0fde259/c6f057b86584942e415435ffb1fa93d4.js");
      //document.head.appendChild(ckeditor);
    },
    watch: {
      spots: function() {
        //this.$redrawVueMasonry()
        //this.masonry();

/*
var grid = document.querySelector('.grid');
var msnry = new Masonry( grid, {
  itemSelector: '.grid-item',
});
*/

        //this.createGrid();
        this.$nextTick(() => {
          if (this.$route.hash)
            return;

          var positionY = sessionStorage.getItem('positionY')
          scrollTo(0, positionY);
          setTimeout(function(){
              scrollTo(0, positionY);
              }, 500);
          })
      },
    },
    data() {
      return {
        spots: [],
        areas: [],
        banners: [],
        banners_lower: [],
        sitejack: {
          'background-color': '#fff',
          'text-color': '#000',
          'banner_header': '',
          'banner_header_link': '',
          'button_header': '',
          'button_header_link': '',
          'banner_left': '',
          'banner_left_link': '',
          'banner_right': '',
          'banner_right_link': '',
          'banner_footer': '',
          'banner_footer_link': '',
        },
        prefectures: [],
        tag_spots: [],
        tag_circles: [],
        tag_videos: [],
        events: [],
        pickup_future_events: [],
        pickup_expired_events: [],
        future_events: [],
        expired_events: [],
        events_in_table: [],
        announcements: [],
        alerts: [],
        announcements_list: [],
        pickup_events: [],
        pickup_events_count: '',
        list_events: [],
        circles: [],
        video_newests: [],
        video_recommends: [],
        show: true,
        loadingInstance: null,
        is_view_sales_banner: 0,
        isotopeOptions: {
          masonry: {
            gutter: 20,
            fitWidth: true
          }
        },
        dfp_slots: {
          'div-gpt-ad-1571972845074-0': { // 不明
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580282482771-0': { // 不明
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580355841225-0': { // circles
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580353644223-0': { // spots
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580356736019-0': { // events
            336: [336, 280]
          },
          'div-gpt-ad-1580361234575-0': { // footer
            300: [300, 250]
          },
        }
      }
    },
    filters: {
      yyyyMMddd: function (date) {
        return dayjs(date).format('YYYY/M/D (ddd)');
      },
      yyyyMM: function (date) {
        return dayjs(date).format('YYYY/M/D');
      },
      HHmm: function (date) {
        return dayjs(date).format('HH:mm')
      },
      ymd: function (date) {
        return dayjs(date).format('YYYY-M-D (ddd)');
      },
      yyyy: function (date) {
        return dayjs(date).format('YYYY年');
      },
      mmddd: function (date) {
        return dayjs(date).format('M月D日 (ddd)');
      },
      ddd: function (date) {
        return dayjs(date).format('(ddd)');
      },
    },
  }
</script>

<style scoped lang="scss">
    /* ----------------------------------------
    iframe
    -----------------------------------------*/
    .vcontent >>> iframe {
      width: 100%;
      border: none;
      overflow: hidden;
    }

    /* ----------------------------------------
    el-button
    -----------------------------------------*/
    .el-button {
      font-size: 14px;
      font-weight: 300;
    }
    .el-button-group .el-button--primary:not(:first-child):not(:last-child) {
      border-left-color: #bbb;
      border-right-color: #bbb;
    }
    .el-button-group .el-button--primary:last-child {
      border-left-color: #bbb;
    }
    .el-button-group .el-button--primary:first-child {
      border-right-color: #bbb;
    }
    .el-button-event {
      padding-left: 28px;
      padding-right: 40px;
    }

    /* ----------------------------------------
    video_h6
    -----------------------------------------*/
    .video_h6 {
      display: block;
      border-bottom: 1px solid #ddd;
    }

    /* ----------------------------------------
    video-element
    -----------------------------------------*/
    .video-element {
      display: inline-block;
      width: 23.5%;
      background-color: #fff;
      cursor: pointer;
      box-shadow: 0px 3px 10px 0px rgba(100, 100, 100, 0.2);
    }
    .video-element img {
      display: block;
    }
    .video-element > div {
      background-color: #000;
    }
    .video-element .video-text {
      box-sizing: border-box;
      background-color: #fff;
    }
    .video-element .video-thumb-wrap {
      display: flex;
      align-items: center;
      background-color: #000;
      height: 200px;
      overflow: hidden;
    }
    .video-element .video-thumb {
      width: 100%;
      position: relative;
      text-align: center;
      background-color: #000;
    }
    .video-element .video-thumb img {
      max-width: 100%;
      vertical-align: middle;
      min-height: 100px;
      max-height: 280px;
      object-fit: contain; //covor;
      display: inline-block;
    }

    /* ----------------------------------------
    video-element-first-child 
    -----------------------------------------*/

    .video-element-first-child {
      width: 100%;
      margin-bottom: 10px;
      cursor: pointer;
      box-shadow: 0px 3px 10px 0px rgba(100, 100, 100, 0.2);
    }
    .video-element-first-child > div {
      display: flex;
      //flex-wrap: wrap;
      align-items: center;
      background-color: #000;
    }
    .video-element-first-child > div > div {
      box-sizing: border-box;
      background-color: #000;
    }
    .video-element-first-child h5 {
      display: block;
      color: #ccc;
    }
    .video-element-first-child p {
      display: block;
      color: #999;
    }
    .video-element-first-child .video-thumb {
      width: 50%;
      flex-shrink: 0;
      position: relative;
      text-align: center;
      background-color: #000;
    }
    .video-element-first-child .video-thumb img {
      max-width: 100%;
      vertical-align: middle;
      min-height: 100px;
      max-height: 280px;
      object-fit: contain; //covor;
      display: inline-block;
    }
    @media screen and (min-width: 992px) {
        .video-element-first-child .video-thumb {
          width: 35%;
        }
        .video-element:nth-child(n+3):nth-child(-n+5) {
          margin-left: 2%;
        }
    }
    @media screen and (max-width: 991px) and (min-width: 576px) {
        .video-element-first-child .video-thumb {
          width: 60%;
        }
        .video-element {
          width: 49%;
          margin-bottom: 10px;
        }
        .video-element:nth-child(n+3):nth-child(-n+5) {
          //margin-left: 0;
        }
        .video-element:nth-child(2n+1) {
          margin-left: 2%;
        }
    }
    @media (max-width: 575px){
        .video-element {
          width: 100%;
          margin-bottom: 30px;
        }
        .video-element-first-child {
          margin-bottom: 30px;
        }
        .video-element-first-child > div {
          flex-wrap: wrap;
        }
        .video-element-first-child .video-thumb {
          width: 100%;
        }
    }

    .flex-container {
      //display: flex;
    }
/*
    .d-flex:not(.flex-column)>* {
      min-width: 0;
    }
    .d-flex.flex-column>* {
      max-width: 100%;
    }
*/
    .center-content {
      margin-left: 0 !important;
      margin-right: 0 !important;
      box-sizing: border-box;
    }
    .side-content {
      //padding-top: 56.25%;
      cursor: pointer;
      //min-height: 100%;
    }


    @media (min-width:1200px) {
      .side-content {
        min-width: calc((100% - 1140px) / 2);
      }
      .footer-advertisement {
        display: none;
      }
    }
    @media (min-width:992px) and (max-width:1199px) {
      .side-content {
        min-width: calc((100% - 960px) / 2);
        visibility: hidden;
      }
      .footer-advertisement {
        //display: none;
      }
    }
    @media (min-width: 768px) and (max-width: 991px) {
      .side-content {
        min-width: calc((100% - 720px) / 2);
        visibility: hidden;
      }
      .footer-advertisement {
        //display: none;
      }
    }
    @media (min-width:576px) and (max-width: 767px) {
      .side-content {
        min-width: calc((100% - 540px) / 2);
        visibility: hidden;
      }
      .footer-advertisement {
        //display: none;
      }
    }
    @media (max-width:575px) {
      .side-content {
        display: none;
      }
    }
    .footer-advertisement {
      position: fixed;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 90px;
      z-index: 999;
      background-color: rgba(244,244,244,.2);
      background-attachment: fixed;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 100% auto;
      cursor: pointer;
    }
    .fa-close {
      position: absolute;
      left: 10px;
      top: -32px;
      width: 32px;
      height: 32px;
      opacity: 0.5;
      cursor: pointer;
    }
    .fa-close:hover {
      opacity: 1;
    }
    .fa-close:before,
    .fa-close:after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 33px;
      width: 2px;
      background-color: #333;
    }
    .fa-close:before {
      transform: rotate(45deg);
    }
    .fa-close:after {
      transform: rotate(-45deg);
    }

.posts-list div {
  width: 280px;
  height: 500px;
  background-color: antiquewhite;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.container .item1 {
  height: 200px;
}

.container .item4 {
  height: 800px;
}

.container .item6 {
  height: 600px;
}

.container .item11 {
  height: 400px;
}

</style>
