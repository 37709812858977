<template>
    <div class="wrapper no-content">
        <div>ページが見つかりません</div>
    </div>
</template>

<script>
  import {
    Breadcrumb,
    BreadcrumbItem,
  } from '@/components';
  import axios from "axios";
  import {API_ENDPOINT} from '@/constants';

  export default {
    name: 'tda',
    bodyClass: 'presentation-page',
    components: {
      [Breadcrumb.name]: Breadcrumb,
      [BreadcrumbItem.name]: BreadcrumbItem,
    },
    data() {
      return {
        html_content: ''
      }
    },
    async created() {
      try {
        document.title = 'ページが見つかりません | ポーカーナビ - 全国のポーカースポット、サークル、トーナメント情報を配信！'
      } catch (e) {
        this.html_content = '<div class="spinner py-5"></div>'
      } finally {
        //
      }
    },
  }
</script>
