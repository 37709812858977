<template>
    <div class="wrapper presentation-page maintenance">
        <div id="poker-spot" data-background-color="">
            <div class="space-100"></div>
            <div v-if="true" v-html="content_html" />

<div v-if="false" class="container">
  <h6 class="with-title-dark">12/23(水)14時～17時 メンテナンスのお知らせ</h6>
    <div class="row">
      <div class="col-md-12 mb-0">
        <p class="description">
いつもポーカーナビをご利用頂きありがとうございます。

システムメンテナンスのため、下記日時にてサイトへのアクセスを一時停止させていただきます。

■メンテナンス日時
12月23日(水)　14時～17時
※作業状況により、メンテナンス時間が前後する場合がございます。

皆様には大変ご不便をおかけしますことお詫び申し上げますとともに、
なにとぞご理解を賜りますよう、よろしくお願い申し上ます。
      </p>
    </div>
  </div>
</div>

        </div>
    </div>
</template>

<script>
  import axios from "axios";
  import {API_ENDPOINT} from '@/constants'

  export default {
    name: 'maitenance',
    bodyClass: 'presentation-page',
    data() {
      return {
        content_html: ''
      }
    },
    async created() {
      try {
        document.title = 'メンテナンス | ポーカーナビ - 全国のポーカースポット、サークル、トーナメント情報を配信！'

        await axios
          .get('https://storage.googleapis.com/pokernavi/maintenance/maintenance-20201223.txt')
          .then(response => {
            this.content_html = response.data
          })

      } catch (e) {
        this.content_html = '<div class="spinner"></div><div class="space-50"></div>'
        console.error(e)
      } finally {
        //
      }
    }
  }
</script>







