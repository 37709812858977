<template>
    <div class="wrapper presentation-page">

        <vue-backTop :height="600" :bottom="50" :right="30" @onClick="" @complete="">
            <div class="backtop">
                <i class="fa fa-spade display-5 text-info"></i>
            </div>
        </vue-backTop>

        <div data-background-color="white">
            <div class="space-100"></div>
            <div class="container">
                <div class="mb-5">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }"><span class="text-black-50"><i class="fa fa-home"></i></span></el-breadcrumb-item>
                        <el-breadcrumb-item><span class="text-black-50">{{ content.title }}</span></el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
            <div class="row">
                <div v-html="html_content" class="col-12"></div>
            </div>
            <div class="space-50"></div>
        </div>

        <div class="pt-4 mb-3">
            <div class="adsense-infeed text-center">
                <dfp-slot
                    path="/21867335047/pokernavi/pokernavi-footer"
                    :size="dfpSlotSize('div-gpt-ad-1580361234575-0')"
                    id="div-gpt-ad-1580361234575-0"
                />
            </div>
        </div>

    </div>
</template>

<script>
  import {
    Breadcrumb,
    BreadcrumbItem
  } from '@/components';
  import axios from "axios";
  import {API_ENDPOINT} from '@/constants';
  import DfpSlot from '../components/DfpSlot.vue'

  export default {
    name: 'content',
    bodyClass: 'presentation-page',
    components: {
      [Breadcrumb.name]: Breadcrumb,
      [BreadcrumbItem.name]: BreadcrumbItem,
      DfpSlot
    },
    methods: {
      dfpSlotSize: function(key) {
        let dfp_slots_sizes = this.dfp_slots[key]
        return dfp_slots_sizes['300']
      },
    },
    data() {
      return {
        html_content: '',
        title: '',
        content: {},
        dfp_slots: {
          'div-gpt-ad-1580361234575-0': {
            300: [300, 250]
          },
        }
      }
    },
    async created() {
      try {
        document.title = ' | ポーカーナビ - 全国のポーカースポット、サークル、トーナメント情報を配信！'
        await axios
            .get(API_ENDPOINT + `content/` + this.$route.params.id)
            .then(response => {
              this.content = response.data
              this.html_content = response.data.article
            })
      } catch (e) {
        this.html_content = '<div class="spinner"></div><div class="space-50"></div>'
      } finally {
        //
      }
    },
  }
</script>

<style scoped lang="scss">
    h5 {
      display: inline-block;
      margin: 0 auto;
      text-align: center;
      padding-bottom: 2px;
      border-bottom: 1px solid #333;
      font-weight: bold;
      font-style: italic;
    }
    .table_of_contents ul {
      list-style-type: none;
      padding-left: 0;

      li {
        cursor: pointer;
        padding-bottom: .3rem;
        padding-left: 1rem;
        margin-bottom: .8rem;
        border-bottom: 1px solid #ccc;
        font-size: .9rem;
      }
    }
</style>
