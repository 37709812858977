<template>
    <footer class="footer"
            :class="{[`footer-${type}`]: type}"
            :data-background-color="backgroundColor">
        <div class="container">
            <hr />
            <nav class="mb-3">
                <ul>
                    <li v-for="(link,key) in links" :key="key">
                        <a :href="link.href" :target="link.target">{{ link.title }}</a>
                    </li>
                </ul>
            </nav>
            <nav class="copyright">
                <div style="display:inline-block;line-height:2.2" class="mx-4">
                    <a href="https://twitter.com/PokerNavi_JP" target="_blank" class="footer-icon"><i class="fab fa-twitter" style="color:#55acee"></i></a>
                    <a href="https://www.facebook.com/pokernavi/" target="_blank" class="footer-icon"><i class="fab fa-facebook-square" style="color:#3b5998"></i></a>
                    <a href="http://nav.cx/j3ii98p" target="_blank" class="footer-icon"><i class="fab fa-line" style="color:rgb(0,198,0)"></i></a>
                </div>
            </nav>
            <div class="clearfix mb-3"></div>
        </div>

        <div class="container">
            <div class="text-center col-sm-8 mx-auto">
                <span class="d-block mb-2" style="line-height:1.2rem;font-size:12px">本サイト内の画像・商標・標章・商号に関する権利は当社または個々の権利の所有者に帰属します。</span>
                <span class="d-inline-block" style="line-height:1.2rem;font-size:12px">
                    <span class="mr-4">&copy; {{year}} ポーカーナビ</span>
                    <span>本サイトの内容、テキスト、画像等の無断転載・無断使用を固く禁じます。</span>
                </span>
                <div v-if="false" class="mb-2" style="font-size:12px">&copy; {{year}} ポーカーナビ</div>
            </div>
            <div clas="space-50"></div>
        </div>
    </footer>
</template>
<script>
  export default {
    props: {
      backgroundColor: String,
      type: String
    },
    data(){
      return {
        year: new Date().getFullYear(),
        links: [
            {
                id: 1,
                title: "運営会社",
                href: "https://www.at-tain.jp/",
                target: "_blank"
            },
            {
                id: 2,
                title: "利用規約",
                href: "/termsofservice2",
                target: ""
            },
            {
                id: 3,
                title: "プライバシーポリシー",
                href: "/privacy",
                target: ""
            },
            {
                id: 4,
                title: "お問い合わせ",
                href: "https://docs.google.com/forms/d/e/1FAIpQLSfkWuDqNTH-YzFT686BElGVpXQ7vcJ92eoTa9t37GRcDeRpEQ/viewform",
                target: "_blank"
            },
            {
                id: 5,
                title: "サイトマップ",
                href: "/sitemap",
                target: ""
            },
            {
                id: 6,
                title: "無料掲載登録",
                href: "https://news.poker-navi.jp/public/contents/regist/spot.html",
                target: "_blank"
            },
        ]
      }
    }
  }
</script>
<style scoped>
ul li:first-child:before{
  content: '' !important;
}
ul li:last-child{
  border: none;
}
ul li:last-child:after{
  content: '' !important;
}
ul li:last-child a:first-child{
  //margin-left: 2rem;
}
.copyright a{
  display: inline-block
}
.footer-icon{
  font-size: 1.2rem;
  margin-left: 1rem;
  margin-right: 1rem;
}
</style>
