<template>
    <div v-if="this.no_content" class="wrapper presentation-page no-content">
        <div class="spinner"></div>
    </div>
    <div v-else class="wrapper presentation-page">

        <vue-backTop v-if="false"></vue-backTop>
        <vue-backTop v-if="true" :height="600" :bottom="50" :right="30" @onClick="" @complete="">
            <div class="backtop">
                <i class="fa fa-spade display-5 text-info"></i>
            </div>
        </vue-backTop>

        <a v-if="true" :href="order_form" target="_blank" class="register-wrap">
            <div class="register">
               <span>無</span>
               <span>料</span>
               <span>掲</span>
               <span>載</span>
               <span>登</span>
               <span>録</span>
            </div>
        </a>

        <div id="poker-spot" data-background-color="white">
            <div class="space-100"></div>
            <div class="container">
                <div class="mb-5">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }"><span class="text-black-50"><i class="fa fa-home"></i></span></el-breadcrumb-item>
                        <el-breadcrumb-item>
                          <span v-if="page.title !== ''" class="text-black-50">{{ page.title }} の</span>
                          <span class="text-black-50">ポーカースポット一覧</span>
                        </el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>

            <div class="container" v-if="true">

                <div class="mb-0 text-right">
                    <social-sharing
                        :url="currentURL()"
                        title="ポーカースポット一覧 | ポーカーナビ - 全国のポーカースポット、サークル、トーナメント情報を配信！"
                        description="ポーカーナビがお届けするポーカースポット一覧です"
                        quote="ポーカーナビがお届けするポーカースポット一覧です"
                        :hashtags="getHashtags()"
                        twitter-user="PokerNavi_JP"
                        inline-template>
                        <div>
                            <network network="facebook">
                                <div class="sns_share fb">
                                    <i class="fab fa-facebook-f mr-1" style="color:#fff"></i>
                                    <span class="font-weight-bold" style="font-size:.8rem">Share</span>
                                </div>
                            </network>
                            <network network="twitter">
                                <div class="sns_share twitter">
                                    <i class="fab fa-twitter mr-1" style="color:#fff"></i>
                                    <span class="font-weight-bold" style="font-size:.7rem;vertial-align:middle">ツイート</span>
                                </div>
                            </network>
                        </div>
                    </social-sharing>
                </div>

                <h6 class="with-title-dark">スポット一覧</h6>
                <div class="row">
                    <div v-if="false" class="col-md-12">
                        <h6 class="with-title-dark"><i class="far fa-search mr-1"></i>条件からさがす</h6>
                    </div>
                    <div class="col-md-12">

                        <n-button v-for="tag_spot in tag_spots_filtered" :key="tag_spot.id" type="primary" size="sm" simple>
                            <router-link :to="{ name: 'spots', query: { tag: tag_spot.id }}"><span class="text-black-50">{{tag_spot.title}}</span></router-link>
                        </n-button>
                        <hr  class="mb-5" />
                        <div v-if="false" class="separator-line separator-primary mb-5"></div>

                        <collapse v-if="false" :multiple-active="false" class="" :active-index="0" :animationDuration=0>
                            <collapse-item no-icon name="1" class="mr-auto ml-auto">
                                <h6 v-if="false" class="mb-0 text-black pl-3" slot="title">
                                    <span style="padding:5px 10px;border:1px solid #ccc">PICKUP</span>
                                    <i class="fa fa-chevron-down text-black-50"></i>
                                </h6>
                                <n-button type="primary" size="sm" simple>
                                    <router-link :to="{ name: 'spots', query: { tag: 7 } }">東京</router-link>
                                </n-button>
                                <n-button type="primary" size="sm" simple>
                                    <router-link :to="{ name: 'spots', query: { tag: 11 } }">大阪</router-link>
                                </n-button>
                            </collapse-item>
                        </collapse>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="row">

                    <div v-if="spots.length == 0" class="mx-auto">
                        <pre class="text-center">登録されたデータはありません</pre>
                    </div>

                    <div v-else class="w-100">

                        <paginate
                              v-if="true"
                              v-model="currentPage"
                              :page-count="meta.total_pages"
                              :click-handler="changePage"
                              :page-range="pageRange"
                              :break-view-text="''"
                              :margin-pages="0"
                              :prev-text="'«'"
                              :next-text="'»'"
                              :initial-page="meta.current_page"
                              :force-page="meta.current_page"
                              :container-class="'vuejs-paginate paginationi-container justify-content-center mb-5 mt-3'"
                              :break-view-class="'vuejs-paginate-break-view'"
                              :page-class="'vuejs-paginate-page'"
                              :page-link-class="'vuejs-paginate-page-link'"
                              :prev-class="'vuejs-paginate-prev'"
                              :prev-link-class="'vuejs-paginate-prev-link'"
                              :next-class="'vuejs-paginate-next'"
                              :next-link-class="'vuejs-paginate-next-link'"
                              :hide-prev-next=true
                            >
                        </paginate>
                    </div>

                    <isotope ref="cpt" :options='isotopeOptions' v-images-loaded:on.progress="layout" :list="spots" class="_card-columns container cards-wide-device pull-left">
                        <div class="grid-sizer"></div>
                        <div v-for="(spot, i) in spots" :key="spot.id" class="isotope-card">
                            <div v-if="false && i == 4" class="adsense-infeed text-center mb-4">
                                <dfp-slot v-if="true"
                                    path="/21867335047/pokernavi/pokernavi-top-spots"
                                    :size="dfpSlotSize('div-gpt-ad-1580353644223-0')"
                                    id="div-gpt-ad-1580353644223-0"
                                />
                            </div>

                            <div v-if="i%20 == 4" class="adsense-infeed text-center mb-4">
                                <dfp-slot v-if="true"
                                    path="/21867335047/pokernavi/pokernavi-top-events"
                                    :size="dfpSlotSize('div-gpt-ad-1580356736019-0')"
                                    id="div-gpt-ad-1580356736019-0"
                                />
                            </div>

                            <router-link v-if="spot.unique_domain !== null && spot.unique_domain !== ''" :to="spot.unique_domain" tag="a" class="front" >
                                <div class="card"
                                     :key="i"
                                     :data-background-color="spot.charged_type == 10 ? '' : (spot.charged_type == 2 ? 'gray' : 'primary')">
                                    <div v-if="spot.thumbnail !== null && spot.thumbnail !== ''" class="card-image">
                                        <img :src="spot.thumbnail" class="img rounded" />
                                    </div>
                                    <div class="card-body" :class="{ 'add-new' : addNew(spot) }">
                                        <span v-if="addNew(spot)" class="dog-ear text-white"></span>
                                        <h6 class="category text-primary font-weight-normal">
                                            <span v-if="spot.online" :data-color="spot.charged_type == 10 ? '' : (spot.charged_type == 2 ? 'gray' : 'primary')">
                                                <i class="far fa-globe mr-2"></i>オンライン
                                            </span>
                                            <span v-else-if="spot.prefecture !== null" :data-color="spot.charged_type == 10 ? '' : (spot.charged_type == 2 ? 'gray' : 'primary')">
                                                <i class="far fa-map-marker-alt mr-2"></i>{{spot.prefecture.name}} {{spot.city}}
                                            </span>
                                        </h6>
                                        <h5 class="card-title font-weight-bold">
                                            <span :data-color="spot.charged_type == 10 ? '' : (spot.charged_type == 2 ? 'gray' : 'primary')">{{spot.name}}</span>
                                        </h5>
                                        <p v-if="spot.charged" class="card-description" v-html="$options.filters.readmore(spot.description, 150)"></p>
                                    </div>
                                </div>
                            </router-link>
                            <router-link v-else :to="{ name: 'spot', params: {id: spot.id}}" tag="a" class="front" >
                                <div class="card"
                                     :key="i"
                                     :data-background-color="spot.charged_type == 10 ? '' : (spot.charged_type == 2 ? 'gray' : 'primary')">
                                    <div v-if="spot.thumbnail !== null && spot.thumbnail !== ''" class="card-image">
                                        <img :src="spot.thumbnail" class="img rounded" />
                                    </div>
                                    <div class="card-body" :class="{ 'add-new' : addNew(spot) }">
                                        <span v-if="addNew(spot)" class="dog-ear text-white"></span>
                                        <h6 class="category text-primary font-weight-normal">
                                            <span v-if="spot.online" :data-color="spot.charged_type == 10 ? '' : (spot.charged_type == 2 ? 'gray' : 'primary')">
                                                <i class="far fa-globe mr-2"></i>オンライン
                                            </span>
                                            <span v-else-if="spot.prefecture !== null" :data-color="spot.charged_type == 10 ? '' : (spot.charged_type == 2 ? 'gray' : 'primary')">
                                                <i class="far fa-map-marker-alt mr-2"></i>{{spot.prefecture.name}} {{spot.city}}
                                            </span>
                                        </h6>
                                        <h5 class="card-title font-weight-bold">
                                            <span :data-color="spot.charged_type == 10 ? '' : (spot.charged_type == 2 ? 'gray' : 'primary')">{{spot.name}}</span>
                                        </h5>
                                        <p v-if="spot.charged" class="card-description" v-html="$options.filters.readmore(spot.description, 150)"></p>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </isotope>

                    <div class="w-100">
                        <paginate
                              v-if="true"
                              v-model="currentPage"
                              :page-count="meta.total_pages"
                              :click-handler="changePage"
                              :page-range="pageRange"
                              :break-view-text="''"
                              :margin-pages="0"
                              :prev-text="'«'"
                              :next-text="'»'"
                              :initial-page="meta.current_page"
                              :force-page="meta.current_page"
                              :container-class="'vuejs-paginate paginationi-container justify-content-center my-5'"
                              :break-view-class="'vuejs-paginate-break-view'"
                              :page-class="'vuejs-paginate-page'"
                              :page-link-class="'vuejs-paginate-page-link'"
                              :prev-class="'vuejs-paginate-prev'"
                              :prev-link-class="'vuejs-paginate-prev-link'"
                              :next-class="'vuejs-paginate-next'"
                              :next-link-class="'vuejs-paginate-next-link'"
                              :hide-prev-next=true
                            >
                        </paginate>
                    </div>

                    <div class="space-50"></div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
  import {
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Card,
    Collapse,
    CollapseItem,
    NukButton,
    Pagination,
  } from '@/components';
  import {
    Button,
    ButtonGroup,
    Carousel,
    CarouselItem,
    Loading,
    Table,
    TableColumn,
  } from 'element-ui';
  import axios from "axios";
  import dayjs from 'dayjs'
  import {API_ENDPOINT} from '@/constants'
  import DfpSlot from '../components/DfpSlot.vue'
  import isotope from 'vueisotope'
  import imagesLoaded from 'vue-images-loaded'

  export default {
    name: 'spots',
    bodyClass: 'presentation-page',
    components: {
      Badge,
      Card,
      Collapse,
      CollapseItem,
      [NukButton.name]: NukButton,
      [Button.name]: Button,
      [ButtonGroup.name]: ButtonGroup,
      [Breadcrumb.name]: Breadcrumb,
      [BreadcrumbItem.name]: BreadcrumbItem,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem,
      [Pagination.name]: Pagination,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      DfpSlot,
      isotope
    },
    directives: {
      imagesLoaded
    },
    methods: {
      layout () {
        this.$refs.cpt.layout('masonry');
      },
      dfpSlotSize: function(key) {
        let dfp_slots_sizes = this.dfp_slots[key]
        if (1200 <= window.innerWidth) {
          return dfp_slots_sizes['336']
        } else if(768 <= window.innerWidth && window.innerWidth < 1199) {
          return dfp_slots_sizes['336']
        } else if(518 < window.innerWidth && window.innerWidth < 767) {
          return dfp_slots_sizes['336']
        } else if (window.innerWidth <= 517) {
          return dfp_slots_sizes['336']
        }

      },
      addNew: function(spot) {
        let day7ago = dayjs(Date.now()).add(-7, 'days')
        if (spot.charged && (dayjs(spot.updated_at).diff(day7ago) > 0)) {
          return 'add-new';
        }
      },
      setOrange: function(image) {
        if (!image) {
          //return 'orange';
          return 'gray';
        }
      },
      setTypeBlog: function(index) {
        if (index%3 !== 0) {
          return 'blog';
        }
      },
      changePage: function(pageNum) {
        this.currentPage = Number(pageNum);

        if (this.$route.fullPath.match('tag|area|prefecture')) {
            let endpoint = this.$route.fullPath
            if (endpoint.match('&p=\d*')) {
                endpoint = endpoint.replace(/&p=\d*/, '')
            }
            if (this.currentPage > 1) {
                this.$router.push(endpoint + `&p=${pageNum}` )
            } else {
                this.$router.push(endpoint)
            }

        } else {
            if (this.currentPage > 1) {
                this.$router.push({ name: 'spots', query: { p: pageNum } })
            } else {
                this.$router.push({ name: 'spots' })
            }
        }
      },
      clickCallback: function(pageNum) {
      },
      getPosts: function(pageNum) {
        let endpoint = this.baseUrl+'spots/'
        document.title = '全国のポーカースポット一覧 | ポーカーナビ - 全国のポーカースポット、サークル、トーナメント情報を配信！'
        if (this.$route.query.tag) {
          endpoint = endpoint + '?tag=' + this.$route.query.tag
          axios
            .get(this.baseUrl + `tags/` + this.$route.query.tag)
            .then(response => {
              this.page.title = response.data.title
              this.page.description = response.data.description
              document.title = this.page.title + 'のポーカースポット一覧 | ポーカーナビ - 全国のポーカースポット、サークル、トーナメント情報を配信！'
              this.no_content = false
            })
            .catch(response => {
              console.log(response);
            });
        }
        if (this.$route.query.area) {
          endpoint = endpoint + '?area=' + this.$route.query.area
        }
        if (this.$route.query.prefecture) {
          endpoint = endpoint + '?prefecture=' + this.$route.query.prefecture
          axios
            .get(this.baseUrl + `prefectures/` + this.$route.query.prefecture)
            .then(response => {
              this.page.title = response.data.name
              document.title = this.page.title + 'のポーカースポット一覧 | ポーカーナビ - 全国のポーカースポット、サークル、トーナメント情報を配信！'
              this.no_content = false
            })
            .catch(response => {
              console.log(response);
            });
        }
        //console.log('==== this.$route.query.p : ' + this.$route.query.p)
        if (this.$route.query.p && this.$route.query.p > 1) {
            endpoint = endpoint + ( endpoint.match('tag|area|prefecture') ? '&' : '?' ) + `page=${this.$route.query.p}`
            //endpoint = endpoint + ( endpoint.match('tag|area|prefecture') ? '&' : '?' ) + ``
        }
        axios
          .get(endpoint)
          .then(response => {
            this.meta = response.data.meta
            this.spots = response.data.spots.filter(function(spot){
              return !spot.extra_spot
            })
            this.no_content = false
          })
          .catch(response => {
            console.log(response);
          });
        // layout ------------
        axios
          .get(API_ENDPOINT + `appconstant/7`)
          .then(response => {
            this.order_form = response.data.text_value
          })
      },
      setPages () {
        let numberOfPages = Math.ceil(this.spots.length / this.perPage);
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      addToFavorite: function(arrayPosts) {
        //this.favorites.push(postTitle);
        this.spots = arrayPosts
      },
    },
    async created() {
      try {
        this.loadingInstance = Loading.service({ fullscreen: true });
        if (this.$route.query.p)
            this.currentPage = Number(this.$route.query.p)

        this.getPosts();
        axios
          .get(API_ENDPOINT + `tags/?attribute=1`)
          .then(response => {
            this.tag_spots = response.data
          })
      } catch (e) {
        //
      } finally {
        this.loadingInstance.close()
      }
    },
    watch: {
      posts() {
        this.setPages();
      },
      spots: function() {
        this.$nextTick(() => {
            var positionY = sessionStorage.getItem('positionY')
            scrollTo(0, positionY);
            setTimeout(function(){
                scrollTo(0, positionY);
                }, 500);
            })
      }
    },
    computed: {
      tag_spots_filtered: function() {
        return this.tag_spots.filter(function(i) {
          return i.view_in_list;
        })
      },
      getItems: function () {
        let start = (this.currentPage - 1) * this.perPage;
        let end = this.currentPage * this.perPage;
        return this.spots.slice(start, end);
      },
      getPageCount: function () {
        return Math.ceil(this.spots.length / this.perPage);
      }
    },
    filters: {
      dayjs: function (date) {
        return dayjs(date).format('YYYY年MM月DD日 (ddd)');
      },
    },
    data() {
      return {
        no_content: true,
        spots: [],
        tag_spots: [],
        meta: {},
        order_form: '',
        loadingInstance: null,
        page: {
          title: '',
          description: ''
        },
        isotopeOptions: {
          masonry: {
            gutter: 20,
            fitWidth: true
          }
        },
        baseUrl: API_ENDPOINT,
        currentPage: 1,
        perPage: 20,
        pageRange: 5,
        dfp_slots: {
          'div-gpt-ad-1571972845074-0': { // 不明
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580282482771-0': { // 不明
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580355841225-0': { // circles
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580353644223-0': { // spots
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580356736019-0': { // events
            336: [336, 280]
          },
          'div-gpt-ad-1580361234575-0': { // footer
            300: [300, 250]
          },
        }
      }
    }
  }
</script>

<style scoped lang="scss">
</style>
