<template>
  <div class="twitter-widget">
    <v-timeline
      :id="twitter_id"
      :source-type="'profile'"
      :errorMessage="''"
      :options="{ 'height': twitterHeight, 'tweetLimit': 4, 'lang': 'ja' }" />
  </div>
</template>

<script>
import Timeline from 'vue-tweet-embed/timeline'

export default{
  components: {
    'v-timeline': Timeline,
  },
  created: function () {
    this.twitter_id = this.$parent.twitter_id
  },
  data() {
    return {
      twitter_id: '',
      twitterHeight: '600',
    }
  },
}
</script>
