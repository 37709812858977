<template>
    <div v-if="this.no_content" class="wrapper presentation-page no-content">
        <div class="mx-auto text-center">
            <div class="spinner"></div>
            <div class="space-50"></div>
        </div>
    </div>
    <div v-else class="wrapper presentation-page">
        <div id="poker-spot" data-background-color="white">
            <div class="space-100"></div>
            <div class="container">
                <div class="mb-5">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }"><span class="text-black-50"><i class="fa fa-home"></i></span></el-breadcrumb-item>
                        <el-breadcrumb-item><span class="text-black-50">{{ announcement.title }}</span></el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>

            <div class="cards">
                <div class="container">
                    <div class="mb-0 text-right">
                        <social-sharing
                            :url="currentURL()"
                            :title="getTitle(announcement)"
                            :description="getSubtitle(announcement)"
                            :quote="getSubtitle(announcement)"
                            :hashtags="getHashtags(announcement)"
                            twitter-user="PokerNavi_JP"
                            inline-template>
                            <div>
                                <network network="facebook">
                                    <div class="sns_share fb">
                                        <i class="fab fa-facebook-f mr-1" style="color:#fff"></i>
                                        <span class="font-weight-bold" style="font-size:.8rem">Share</span>
                                    </div>
                                </network>
                                <network network="twitter">
                                    <div class="sns_share twitter">
                                        <i class="fab fa-twitter mr-1" style="color:#fff"></i>
                                        <span class="font-weight-bold" style="font-size:.7rem;vertial-align:middle">ツイート</span>
                                    </div>
                                </network>
                            </div>
                        </social-sharing>
                    </div>
                </div>
                <div class="container">
                    <h6 class="with-title-dark" style="line-height:1.5rem">{{ announcement.title }}</h6>
                    <div class="pb-3">
                        <badge v-if="false" type="info" class="mr-3">{{ announcement.article_type.name }}</badge>
                        <span v-if="announcement.pubdate !== null && announcement.pubdate !== ''" class="pb-3">{{ announcement.pubdate | pubtime_format }} 更新</span>
                    </div>
                    <img v-if="announcement.image_header !== null && announcement.image_header !== ''" class="w-100"
                         :src="'' + announcement.image_header + ''" />
                </div>
            </div>
        </div>
        <div data-background-color="white">
            <div class="cards">
                <div class="container">
                    <div class="row">
                        <div v-if="announcement.sub_title !== null && announcement.sub_title !== ''" class="col-12">
                            <h4 class="mb-4 font-weight-bold">{{ announcement.sub_title }}</h4>
                        </div>
                        <div v-if="announcement.article !== null && announcement.article !== ''">
                            <p class="col-12 mb-5 description">{{ announcement.article }}</p>
                        </div>
                        <div v-if="announcement.internal_link !== null && announcement.internal_link !== ''" class="col-12 pb-2">
                            <a :href="announcement.internal_link">{{ announcement.internal_link_title }}</a>
                        </div>
                        <div v-if="announcement.external_link !== null && announcement.external_link !== ''" class="col-12 pb-2">
                            <a :href="announcement.external_link" target="_blank">{{ announcement.external_link_title }}</a>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div v-if="this.video !== null && this.video !== '' ">
                        <h6 class="with-title-dark">動画</h6>
                        <div class="youtube_wrap mb-4">
                            <iframe class="youtube_content" :src="'https://www.youtube.com/embed/' + this.video.youtube + ''" frameborder="0" allow="accelerometer;autoplay;encrypted-media;gyroscope;picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="slider-container" data-background-color="white">
            <div class="google-calendar-mask">
                <div class="container mb-3 mt-4">
                    <div v-if="sliders.length > 0" class="slider-image-wrap mb-3">
                        <el-carousel height="" type="" arrow="always">
                            <el-carousel-item v-for="(slider_image,key) in sliders" :key="key" class="carousel-item">
                                <div v-if="true" class="slider-image" :style="{ 'background-image':'url(' + slider_image + ')' }">
                                    <div v-if="false" class="slider-image clear-filter" filter-color="blue"></div>
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </div>
        </div>

        <div class="pt-4 mb-3">
            <div v-if="true" class="adsense-infeed text-center">
                <dfp-slot v-if="true"
                    path="/21867335047/pokernavi/pokernavi-footer"
                    :size="dfpSlotSize('div-gpt-ad-1580361234575-0')"
                    id="div-gpt-ad-1580361234575-0"
                />
            </div>
        </div>

    </div>

</template>
<script>
  import {
    Badge,
    Breadcrumb,
    BreadcrumbItem
  } from '@/components';
  import { API_ENDPOINT } from '@/constants';
  import { Carousel, CarouselItem, Loading } from 'element-ui';
  import axios from "axios";
  import dayjs from 'dayjs'
  import DfpSlot from '../components/DfpSlot.vue'

  export default {
    name: 'announcement',
    bodyClass: 'profile-page',
    components: {
      Badge,
      [Breadcrumb.name]: Breadcrumb,
      [BreadcrumbItem.name]: BreadcrumbItem,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem,
      DfpSlot
    },
    filters: {
      pubtime_format: function (date) {
        return dayjs(date).format('YYYY年MM月DD日 HH時mm分')
      },
    },
    created() {
      try {
        this.loading = true;
        this.loadingInstance = Loading.service({ fullscreen: true });
        this.getAnnouncement();
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingInstance.close();
      }
    },
    methods: {
      dfpSlotSize: function(key) {
        let dfp_slots_sizes = this.dfp_slots[key]
        return dfp_slots_sizes['300']
      },
      title: function() {
        return this.announcement.title
      },
      getAnnouncement: function() {
        axios
          .get(API_ENDPOINT + `announcements/` + this.$route.params.id + '?detail')
          .then(response => {
            this.announcement = response.data
            this.video = response.data.youtube
            this.sliders = response.data.sliders
            this.no_content = false
            this.loading = false;
          })
      }
    },
    watch: {
      announcement: function() {
        this.$nextTick(() => {
            var positionY = sessionStorage.getItem('positionY')
            scrollTo(0, positionY);
            setTimeout(function(){
                scrollTo(0, positionY);
                }, 500);
        })
        this.no_content = (this.announcement == null)
        document.title = this.announcement.title //' | ポーカーナビ | 全国のポーカースポット、サークル、トーナメント情報を配信！'
        if (this.announcement.sub_title !== null && this.announcement.sub_title !== '')
          document.title = this.announcement.title + ' | ' + this.announcement.sub_title
      }
    },
    data() {
      return {
        no_content: true,
        dfp_slots: {
          'div-gpt-ad-1571972845074-0': { // 不明
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580282482771-0': { // 不明
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580355841225-0': { // circles
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580353644223-0': { // spots
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580356736019-0': { // events
            336: [336, 280]
          },
          'div-gpt-ad-1580361234575-0': { // footer
            300: [300, 250]
          },
        },
        //componentKey:0,
        announcement: {},
        sliders: [],
        video: {},
        loading: false,
        loadingInstance: null,
      }
    },
  }
</script>
