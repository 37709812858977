import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./registerServiceWorker";
//plugins
import NowUIKit from './plugins/now-ui-kit';

// extra package
import smoothScroll from 'vue-smoothscroll';
import 'dayjs/locale/ja'
import dayjs from 'dayjs'
import VueAnalytics from 'vue-analytics'
import vueBacktopPack from 'vue-backtop-pack'

import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)

import SocialSharing from 'vue-social-sharing'
Vue.use(SocialSharing);

import MagicGrid from 'vue-magic-grid'
Vue.use(MagicGrid);

import {VueMasonryPlugin} from 'vue-masonry';
Vue.use(VueMasonryPlugin);

import Isotope from 'vueisotope'
Vue.use(Isotope);


Vue.use(VueAnalytics, {
  id: ['UA-143238064-1'],
  linkers: ['poker-navi.jp'],
  router
})
Vue.use(vueBacktopPack)

Vue.use(smoothScroll)

dayjs.locale('ja');
Vue.use(dayjs);

Vue.config.productionTip = false;
Vue.use(NowUIKit);

Vue.filter('readmore', function (str, limit) {
  if (!str) return '';
  return str.length > limit ? '<p class="inner-card description">' + str.slice(0, limit) + '<span class="readmore ml-1">.....</span></p>' : str;
});

Vue.filter('concat', function (str, limit) {
  if (!str) return '';
  return str.length > limit ? str.slice(0, limit) + '...' : str;
});

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");



