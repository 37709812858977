<template>
    <navbar position="fixed"
            type="default"
            :transparent="transparent"
            :color-on-scroll="colorOnScroll"
            :color-black="colorBlack"
            :class="{'navbar-visibility': colorBlack}"
            menu-classes="ml-auto">
        <template slot-scope="{toggle, isToggled}">
            <router-link class="navbar-brand" to="/">
                <div class="invision-logo"></div>
            </router-link>
        </template>
        <template slot="navbar-menu">
            <drop-down tag="li" title=" イベント" icon="fa fa-trophy" class="nav-item">
                <nav-link to="/events">
                    <i class="far fa-list mr-2"></i>大会一覧
                </nav-link>
                <nav-link v-if="false" to="/ticket">
                    <i class="far fa-info-circle mr-2"></i>会員向け情報
                </nav-link>
            </drop-down>

            <drop-down tag="li" title=" スポット" icon="fa fa-home" class="nav-item">
                <nav-link class="dropdown-item" to="/spots">
                    <i class="far fa-list mr-2"></i>ポーカースポット一覧
                </nav-link>
            </drop-down>
            <drop-down tag="li" title=" サークル" icon="fa fa-user-friends" class="nav-item">
                <nav-link class="dropdown-item" to="/circles">
                    <i class="far fa-list mr-2"></i>サークル一覧
                </nav-link>
            </drop-down>
            <drop-down v-if="true" tag="li" title=" 動画" icon="fab fa-youtube" class="nav-item">
                <nav-link class="dropdown-item" to="/videos">
                    <i class="far fa-list mr-2"></i>動画一覧
                </nav-link>
            </drop-down>
            <drop-down tag="li" title=" ポーカーの遊び方" icon="fa fa-file-signature" class="nav-item">
                <nav-link class="dropdown-item" to="/rule">
                    <i class="far fa-file-signature mr-2"></i>基本ルール
                </nav-link>
                <nav-link class="dropdown-item" to="/tda">
                    <i class="far fa-file-signature mr-2"></i>TDA2019
                </nav-link>
            </drop-down>
            <drop-down tag="li" title=" ポーカーナビ" icon="fa fa-spade" class="nav-item mr-3">
                <a href="https://www.at-tain.jp/" target="_blank" class="dropdown-item">
                    <i class="far fa-building mr-2"></i>運営会社
                </a>
                <nav-link class="dropdown-item" to="/termsofservice2">
                    <i class="far fa-file-signature mr-2"></i>利用規約
                </nav-link>
                <nav-link class="dropdown-item" to="/privacy">
                    <i class="far fa-user-shield mr-2"></i>プライバシーポリシー
                </nav-link>
                <a href="/sitemap" class="dropdown-item">
                    <i class="far fa-sitemap mr-2"></i>サイトマップ
                </a>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSfkWuDqNTH-YzFT686BElGVpXQ7vcJ92eoTa9t37GRcDeRpEQ/viewform" target="_blank" class="dropdown-item">
                    <i class="far fa-paper-plane mr-2"></i>お問い合わせ
                </a>
            </drop-down>
            <li class="nav-item mb-3"></li>
            <li class="nav-item mb-3 mainnav-icons">
                <div class="mx-auto px-3">
                    <a href="https://twitter.com/PokerNavi_JP" target="_blank" class="mainnav-icon mx-2 btn btn-icon btn-lg btn-round btn-twitter">
                        <i class="fab fa-twitter"></i>
                    </a>
                    <a href="https://www.facebook.com/pokernavi/" target="_blank" class="mainnav-icon mx-2 btn btn-lg btn-round btn-icon btn-facebook">
                        <i class="fab fa-facebook-square"></i>
                    </a>
                    <a href="http://nav.cx/j3ii98p" target="_blank" class="mainnav-icon mx-2 btn btn-icon btn-lg btn-round btn-line">
                        <i class="fab fa-line"></i>
                    </a>
                </div>
            </li>
            <li class="nav-item">
                <a class="nav-link btn btn-primary" href="https://news.poker-navi.jp/public/contents/regist/spot.html" target="_blank"><i class="far fa-paper-plane mr-2"></i><p>無料掲載登録</p></a>
            </li>
        </template>
    </navbar>
</template>

<script>
  import { DropDown, NavbarToggleButton, Navbar, NavLink } from '@/components';
  export default {
    name: 'main-navbar',
    props: {
      transparent: Boolean,
      colorOnScroll: Number,
      colorBlack: Boolean,
    },
    components: {
      DropDown,
      Navbar,
      NavbarToggleButton,
      NavLink,
    }
  }
</script>

<style scoped>
.mainnav-icon{
  display: inline-block;
  font-size: 1.2rem;
}
</style>
