<template>
    <div v-if="true">
        <div v-if="this.no_content" class="wrapper profile-page no-content">
            <div class="spinner"></div>
        </div>
        <div v-else class="wrapper profile-page">
            <div class="page-header page-header-smalls clear-filter" filter-color="black">
                <div v-if="event.charged && event !== null && event.image_header !== null" class="page-header-image" 
                     :style="'background-image: url(' + event.image_header +')'">
                </div>
                <div class="rellax-text-container rellax-text">
                    <div v-if="event.charged == 1 && event.logo !== null" class="photo-container mb-2">
                        <div class="photo-container-inner" :style="{ 'background-image': 'url('+ event.logo +')' }"></div>
                    </div>
                    <h1 class="h1-seo mb-2 col-sm-6 mr-auto ml-auto" data-rellax-speed="-2">{{ event.title }}</h1>
                    <h6 class="category">{{ event.alias }}</h6>
                </div>
            </div>

            <a :href="order_form" target="_blank" class="register-wrap">
                <div class="register">
                   <span>無</span>
                   <span>料</span>
                   <span>掲</span>
                   <span>載</span>
                   <span>登</span>
                   <span>録</span>
                </div>
            </a>

            <div class="section section-components" data-background-color="white">
                <div class="container pt-3">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }"><span class="text-white-50s"><i class="fa fa-home"></i></span></el-breadcrumb-item>
                        <el-breadcrumb-item><span class="">{{event.title}}</span></el-breadcrumb-item>
                    </el-breadcrumb>
                </div>

                <alert type="primary" class="mt-3 py-2" v-if="false && expired(event.end)">
                    <div class="container font-weight-bold">
                        <i class="far fa-times-circle mr-3"></i>
                        <span class="">このイベントは終了しました</span>
                    </div>
                </alert>

                <div class="container pt-5">
                    <div v-if="event.link_target_in_detail !== null && (event.link_target_in_detail == 2 || event.link_target_in_detail == 3) && ((event.internal_link !== null && event.internal_link !== '') || (event.external_link !== null && event.external_link !== ''))" class="text-center mb-2">
                        <a @click="eventRowClick(event)" class="btn btn-lg btn-simples btn-info btn-rounds text-white font-weight-bold px-2 col-md-8 col-xm-12 col-lg-6">
                            <span v-if="event.link_title !== null && event.link_title !== ''">{{ $options.filters.concat(event.link_title, 22) }}</span>
                            <span v-else-if="event.internal_link !== null && event.internal_link !== ''">{{ event.internal_link }}</span>
                            <span v-else-if="event.external_link !== null && event.external_link !== ''">{{ event.external_link }}</span>
                            <span v-else>トピックスへ</span>
                            <span v-if="false" v-html="$options.filters.readmore(event.link_title, 12)"></span>
                        </a>
                    </div>

                    <div v-if="event.link_from_detail && event.on_sale_link !== null && event.on_sale_link !== '' && pubNotExpired(event)" class="text-center mb-2">
                        <a :href="event.on_sale_link" class="btn btn-lg btn-simples btn-info btn-rounds text-white font-weight-bold px-2 col-md-8 col-xm-12 col-lg-6" target="_blank">
                            <span>{{ (event.link_text_in_detail !== null && event.link_text_in_detail !== '') ? event.link_text_in_detail : 'イベントチケット' }}</span>
                        </a>
                    </div>

                    <div class="space-50"></div>

                    <div class="mb-0 text-right">
                        <social-sharing
                            :url="currentURL()"
                            :title="getTitle(this.event)"
                            :description="getOverview(this.event)"
                            :quote="getOverview(this.event)"
                            :hashtags="getHashtags(this.event)"
                            twitter-user="PokerNavi_JP"
                            inline-template>
                            <div>
                                <network network="facebook">
                                    <div class="sns_share fb">
                                        <i class="fab fa-facebook-f mr-1" style="color:#fff"></i>
                                        <span class="font-weight-bold" style="font-size:.8rem">Share</span>
                                    </div>
                                </network>
                                <network network="twitter">
                                    <div class="sns_share twitter">
                                        <i class="fab fa-twitter mr-1" style="color:#fff"></i>
                                        <span class="font-weight-bold" style="font-size:.7rem;vertial-align:middle">ツイート</span>
                                    </div>
                                </network>
                            </div>
                        </social-sharing>
                    </div>

                    <h6 class="with-title-dark">開催概要</h6>
                    <div>
                        <div class="row mb-3">
                            <div class="col-md-12 mb-3">
                                <div>
                                    <table class="table mb-4">
                                        <thead>
                                            <tr>
                                                <th>開始</th>
                                                <th>決勝</th>
                                                <th>開催場所</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style="min-width:80px">
                                                    <span v-if="event.start !== null">{{ event.start | yyyy }}<br />{{ event.start | MMdd }}<br /></span>
                                                    <span v-else>-</span>
                                                </td>
                                                <td style="min-width:80px">
                                                    <span v-if="event.end !== null">{{ event.end | yyyy }}<br />{{ event.end | MMdd }}</span>
                                                    <span v-else>-</span>
                                                </td>
                                                <td>
                                                    <a v-if="event.access !== null && event.access !== ''" :href="'https://www.google.com/maps/search/?api=1&query=' + event.access" target="_blank">
                                                        <i class="far fa-map-marker-alt mr-2"></i>{{ event.access }}
                                                    </a>
                                                    <span v-else>-</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p v-if="event.charged == 1 && event.overview !== null && event.overview !== ''" class="col-12 mb-3 description">{{event.overview}}</p>
                                    <div v-if="event.charged == 1 && event.web !== null && event.web !== ''" class="col-12 mb-3">
                                        <a :href="event.web" target="_blank"><i class="far fa-globe mr-2"></i><strong>{{event.title}} 公式サイト</strong></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="sub_events.length > 0" class="">
                        <h6 class="with-title-dark"><i v-if="false" class="far fa-calendar-check mr-2"></i>関連トーナメント</h6>

                        <div v-if="false" class="table-responsive pn-tables mb-4 text-black">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="font-weight-bold">決勝 FINAL</th>
                                        <th class="font-weight-bold">大会</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="event in event.sub_events" @click="toEventDetail(event)">
                                        <td class="w-25">
                                            <div class="px-2">
                                                <span v-if="event.end !== null" class="small">{{ event.end | yyyy }}<br />{{ event.end | mmddd }}</span>
                                                <span v-else>日時未定</span>
                                            </div>
                                        </td>
                                        <td class="w-75">
                                            <div class="px-2">
                                                <span v-if="event.title !== null && event.title !== ''">
                                                    {{ event.title }}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <el-table v-if="true" :data="event.sub_events" @row-click="toEventDetail" class="mb-5">
                            <el-table-column label="決勝 FINAL" min-width="100" align="left">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.end !== null">{{ scope.row.end | yyyy }}<br />{{ scope.row.end | mmddd }}</span>
                                    <span v-else>日時未定</span>
                                </template>
                            </el-table-column>
                            <el-table-column min-width="180"
                                             align="left"
                                             prop="title"
                                             label="大会">
                            </el-table-column>
                        </el-table>
                    </div>

                    <div v-if="event.charged == 1">
                        <div class="row mb-3">
                            <div v-if="event.result" class="col-md-12 mb-3">
                                <h6 class="with-title-dark">大会結果</h6>
                                <p class="col-12 description">{{event.result}}</p>
                            </div>
                        </div>
                      <div class="row mb-3">
                          <div class="col-md-12 mb-3">
                              <div v-if="event.entry">
                                  <h6 class="with-title-dark">参加方法</h6>
                                  <p class="col-12 description">{{event.entry}}</p>
                              </div>
                          </div>
                      </div>
                      <div v-if="event.rules !== null && event.rules !== ''" class="row mb-3">
                          <div class="col-md-12 mb-3">
                              <div v-if="event.rules">
                                  <h6 class="with-title-dark">ストラクチャー</h6>
                                  <p class="col-12 description">{{event.rules}}</p>
                              </div>
                          </div>
                      </div>
                      <div v-if="event.prize !== null && event.prize !== ''" class="row mb-3">
                          <div class="col-md-12 mb-3">
                              <div v-if="event.prize">
                                  <h6 class="with-title-dark">賞品</h6>
                                  <p class="col-12 description">{{event.prize}}</p>
                              </div>
                          </div>
                      </div>
                      <div v-if="event.memo_public !== null && event.memo_public !== ''" class="row mb-3">
                          <div class="col-md-12 mb-3">
                              <div v-if="event.memo_public">
                                  <h6 class="with-title-dark">備考</h6>
                                  <p class="col-12 description">{{event.memo_public}}</p>
                              </div>
                          </div>
                      </div>
                      <div v-if="event.caution" class="slider-container mb-5">
                          <p class="blockquote blockquote-primary">
                              <small><p class="description">{{event.caution}}</p></small>
                          </p>
                      </div>
                      <div v-if="event.youtube !== null && event.youtube !== '' && event.youtube_enabled">
                          <h6 class="with-title-dark">VIDEO</h6>
                          <div class="youtube_wrap mb-2">
                              <iframe class="youtube_content" :src="'https://www.youtube.com/embed/' + event.youtube + ''" frameborder="0" allow="accelerometer;autoplay;encrypted-media;gyroscope;picture-in-picture" allowfullscreen></iframe>
                          </div>
                      </div>
                      <div v-if="sliders.length > 0" class="slider-image-wrap mb-2">
                          <el-carousel height="" type="" arrow="always">
                              <el-carousel-item v-for="(slider_image,key) in sliders" :key="key" class="carousel-item">
                                  <div class="slider-image" :style="{ 'background-image':'url(' + slider_image + ')' }"></div>
                              </el-carousel-item>
                          </el-carousel>
                      </div>
                    </div>

                    <div v-if="event.charged == 1 && event.flyer && event.flyer_view_in_detail" class="justify-content-center text-center mb-5">
                        <img v-lazy="'' + event.flyer +''" />
                    </div>
                </div>

                <div v-if="event_spots_filtered.length > 0" class="mb-3">
                    <div class="container pb-3">
                        <h6 class="with-title-dark">開催スポット</h6>
                        <div class="row">

                            <div v-if="false" class="table-responsive mb-4 text-black col-12">
                                <table class="table table-hover">
                                    <thead class="thead-light">
                                        <tr>
                                            <th scope="col" class="font-weight-bold text-center">ROUND</th>
                                            <th scope="col" class="font-weight-bold text-center">開催</th>
                                            <th scope="col" class="font-weight-bold text-center">開催スポット</th>
                                            <th scope="col" class="font-weight-bold text-center">開始</th>
                                            <th scope="col" class="font-weight-bold text-center">結果</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="row in event_spots_filtered" @click="handleRowClick(row)">
                                            <td class="text-center td-xs">
                                                <div class="px-2">
                                                    <badge v-if="row.game_round" type="default" class="mr-0">{{ row.game_round }}</badge>
                                                </div>
                                            </td>
                                            <td class="td-sm">
                                                <div class="px-2 small">
                                                    <span v-if="row.start_at !== null">{{ row.start_at | yyyy }}<br />{{ row.start_at | mmddd }}</span>
                                                    <span v-else>---</span>
                                                </div>
                                            </td>
                                            <td class="td-md">
                                                <div class="px-2">
                                                    <i class="far fa-map-marker-alt mr-2 text-primary"></i>{{ row.prefecture }}<br /><strong>{{ row.name }}</strong>
                                                </div>
                                            </td>
                                            <td class="text-center td-lg">
                                                <div class="px-2">
                                                    <span v-if="row.start_at !== null">{{ row.start_at | hhmm }}</span>
                                                    <span v-else>---</span>
                                                </div>
                                            </td>
                                            <td class="td-xl">
                                                <div class="px-2">
                                                    <p class="description">{{ row.result }}</p>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <el-table
                                v-if="true"
                                :data="event_spots_filtered"
                                @row-click="handleRowClick"
                                class="">
                                <el-table-column label="ROUND" min-width="80" align="center">
                                    <template slot-scope="scope">
                                        <badge v-if="scope.row.game_round" type="default" class="mr-0">{{ scope.row.game_round }}</badge>
                                    </template>
                                </el-table-column>
                                <el-table-column label="開催" min-width="100" align="left">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.start_at !== null">{{ scope.row.start_at | yyyy }}<br />{{ scope.row.start_at | mmddd }}</span>
                                        <span v-else>---</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="開催スポット" min-width="160" align="left">
                                    <template slot-scope="scope">
                                        <i class="far fa-map-marker-alt mr-2 text-primary"></i>{{ scope.row.prefecture }}<br /><strong>{{ scope.row.name }}</strong>
                                    </template>
                                </el-table-column>
                                <el-table-column label="開始" min-width="80" align="left">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.start_at !== null">{{ scope.row.start_at | hhmm }}</span>
                                        <span v-else>---</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="結果" min-width="300" align="left" fit="false">
                                    <template slot-scope="scope">
                                        <div class="eventspot-results">
                                            <p v-if="false" class="description">{{ scope.row.result }}</p>
                                            <span v-if="true" v-html="scope.row.result"></span>
                                        </div>
                                        <p v-if="false" class="description">{{ scope.row.result }}</p>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
                <div v-if="event_satellites_filtered.length > 0">
                    <div class="container pb-3">
                        <div class="mx-auto">
                            <el-table
                                :data="event_satellites_filtered"
                                @row-click="handleRowClick"
                                class="">
                                <el-table-column label="ROUND" min-width="70" align="center">
                                    <template slot-scope="scope">
                                        <badge v-if="scope.row.game_round" type="default" class="mr-0">{{ scope.row.game_round }}</badge>
                                    </template>
                                </el-table-column>
                                <el-table-column label="開催" min-width="100" align="left">
                                    <template slot-scope="scope">
                                        {{ scope.row.start_at | yyyy }}<br />{{ scope.row.start_at | mmddd }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="開催スポット" min-width="160" align="left">
                                    <template slot-scope="scope">
                                        <i class="far fa-map-marker-alt mr-2 text-primary"></i>{{ scope.row.prefecture }}<br /><strong>{{ scope.row.name }}</strong>
                                    </template>
                                </el-table-column>
                                <el-table-column label="開始" min-width="80" align="left">
                                    <template slot-scope="scope">{{ scope.row.start_at | hhmm }}</template>
                                </el-table-column>
                                <el-table-column label="結果" min-width="160" align="left">
                                    <template slot-scope="scope">
                                        <div class="eventspot-results">
                                            <span v-if="true" v-html="scope.row.result"></span>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>

                <div class="space-20"></div>
            </div>

            <div class="" data-background-color="white">
                <div v-if="event.charged && event_spots.length > 0" class="container pb-4">
                    <div class="separator-line separator-primary mt-0 mb-5"></div>
                    <div v-if="event_spots_meta.total_pages > 1" class="pagination-container justify-content-center mb-3">
                        <eventspot-pagination type="primary"
                                      :total="event_spots_meta.count"
                                      :currentPage.sync="event_spots_meta.current_pages"
                                      :page-count="event_spots_meta.total_pages"
                                      :ids="event_spot_ids"
                                      v-model="eventPagination.simple"
                                      @fav="replaceEventSpots"
                                      ></eventspot-pagination>
                    </div>
                    <div v-else class="space-20"></div>

                    <div class="card-columns">
                        <div class="cards">
                            <div v-for="(spot, k) in event_spots" :key="spot.id">
                                <router-link v-if="spot.unique_domain !== null && spot.unique_domain !== ''" :to="'/' +spot.unique_domain" tag="a">
                                    <a>
                                        <card :color="spot.charged_type == 10 ? '' : (spot.charged_type == 2 ? 'gray' : 'orange')"
                                              :cardBodyClasses="addNew(spot)" >
                                            <img v-if="spot.charged && spot.thumbnail !== null" slot="image" class="img rounded" v-lazy="spot.thumbnail" />
                                            <h6 class="category text-primary font-weight-normal text-left">
                                                <span v-if="spot.prefecture !== null">
                                                    <i class="far fa-map-marker-alt mr-2"></i>{{spot.prefecture.name}} {{spot.city}}
                                                </span>
                                                <span v-if="spot.online">
                                                    <i class="far fa-globe mr-2"></i>オンライン
                                                </span>
                                            </h6>
                                            <h5 class="card-title font-weight-bold">{{spot.name}}</h5>
                                        </card>
                                    </a>
                                </router-link>
                                <router-link v-else :to="{ name: 'spot', params: { id: spot.id } }" tag="a">
                                    <a>
                                        <card :color="spot.charged_type == 10 ? '' : (spot.charged_type == 2 ? 'gray' : 'orange')"
                                              :cardBodyClasses="addNew(spot)" >
                                            <img v-if="spot.charged && spot.thumbnail !== null" slot="image" class="img rounded" v-lazy="spot.thumbnail" />
                                            <h6 class="category text-primary font-weight-normal text-left">
                                                <span v-if="spot.prefecture !== null">
                                                    <i class="far fa-map-marker-alt mr-2"></i>{{spot.prefecture.name}} {{spot.city}}
                                                </span>
                                                <span v-if="spot.online">
                                                    <i class="far fa-globe mr-2"></i>オンライン
                                                </span>
                                            </h6>
                                            <h5 class="card-title font-weight-bold">{{spot.name}}</h5>
                                        </card>
                                    </a>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="event.charged && event_circles.length > 0" class="container pb-4">
                    <div v-if="event_circles_meta.total_pages > 1" class="pagination-container justify-content-center mb-3">
                        <eventcircle-pagination type="primary"
                                      :total="event_circles_meta.count"
                                      :currentPage.sync="event_circles_meta.current_pages"
                                      :page-count="event_circles_meta.total_pages"
                                      :ids="event_circle_ids"
                                      v-model="pagination.simple"
                                      @fav="replaceEventCircles"
                                      ></eventcircle-pagination>
                    </div>
                    <div v-else class="space-20"></div>

                    <div v-if="event_circles.length > 0" class="card-columns pb-3">
                        <div class="cards">
                            <div v-for="(circle, k) in event_circles" :key="circle.id">
                                <router-link :to="{ name: 'circle', params: { id: circle.id } }" tag="a">
                                    <a>
                                        <card :color="circle.contract_type == 10 ? '' : (circle.contract_type == 2 ? 'gray' : 'orange')"
                                              :cardBodyClasses="addNew(circle)" >
                                            <h6 class="category text-primary font-weight-normal text-left">
                                                <span v-if="circle.prefecture !== null">
                                                    <i class="far fa-map-marker-alt mr-2"></i>{{circle.prefecture.name}} {{circle.city}}
                                                </span>
                                            </h6>
                                            <h5 class="card-title font-weight-bold">{{circle.name}}</h5>
                                        </card>
                                    </a>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="event.charged && snsViewable(event)">
                <div class="section pb-3" data-background-color="white">
                    <div class="container mx-auto">
                        <div v-if="bothViewable(event)">
                            <div class="row">
                                <div class="col-md-7 pb-5">
                                    <iframe :src="'https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23e3e7ff&amp;ctz=Asia%2FTokyo&amp;src=' +event.google_calendar+ '&amp;color=%23ffffff&amp;showTitle=0&amp;showNav=1&amp;showPrint=0&amp;showTabs=1&amp;showCalendars=0&amp;showTz=0'+getCalendarView(event)"
                                            style="border-width:0;width:100%" class="pb-2" height="600" frameborder="0" scrolling="no"></iframe>
                                </div>
                                <div class="col-md-5" id="fbpage1">
                                    <div class="fb-page" :data-href="'' + facebook_page_url + ''" data-tabs="events" :data-width="getFbWidgetWidth()" data-height="600" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-hide-dta="true" data-show-facepile="true"><blockquote :cite="'' + facebook_page_url + ''" class="fb-xfbml-parse-ignore"></blockquote></div>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="onlyFbViewable(event)">
                                <div class="" id="fbpage1">
                                    <div class="fb-page" :data-href="'' + facebook_page_url + ''" data-tabs="events" :data-width="getFbWidgetWidth()" data-height="600" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-hide-dta="true" data-show-facepile="true"><blockquote :cite="'' + facebook_page_url + ''" class="fb-xfbml-parse-ignore"></blockquote></div>
                                </div>
                        </div>
                        <div v-else-if="onlyCalendarViewable(event)">
                            <div class="pb-5">
                                <iframe :src="'https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23e3e7ff&amp;ctz=Asia%2FTokyo&amp;src=' +event.google_calendar+ '&amp;color=%23ffffff&amp;showTitle=0&amp;showNav=1&amp;showPrint=0&amp;showTabs=1&amp;showCalendars=0&amp;showTz=0'+getCalendarView(event)"
                                        style="border-width:0;width:100%" class="pb-2" height="600" frameborder="0" scrolling="no"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="event.charged && ((event.facebook !== null && event.facebook_timeline) || (event.twitter !== null && event.twitter_enabled))">
                <div class="section pb-3" data-background-color="white">
                    <div class="container mx-auto">
                        <div v-if="(event.twitter !== null && event.twitter_enabled) && (event.facebook == null || !event.facebook_timeline)">
                            <div class="row">
                                <div v-if="event.twitter !== null && event.twitter_enabled" class="col-12 mb-5">
                                    <h6 class="with-title-dark">TWITTER</h6>
                                    <twitter></twitter>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="(event.facebook !== null && event.facebook_timeline) && (event.twitter == null || !event.twitter_enabled)">
                            <div class="row">
                                <div class="col-12 mb-5">
                                    <h6 class="with-title-dark">FACEBOOK</h6>
                                    <div id="fbpage2">
                                      <div class="fb-page" :data-href="'' + facebook_page_url + ''" data-tabs="timeline" :data-width="getFbWidgetWidth()" data-height="1500" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-hide-dta="true" data-show-facepile="true"><blockquote :cite="'' + facebook_page_url + ''" class="fb-xfbml-parse-ignore"></blockquote></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="row">
                                <div v-if="event.twitter !== null && event.twitter_enabled" class="col-md-7 mb-5">
                                    <h6 class="with-title-dark">TWITTER</h6>
                                    <twitter></twitter>
                                </div>
                                <div v-if="event.facebook !== null && event.facebook_timeline" class="col-md-5 mb-5">
                                    <h6 class="with-title-dark">FACEBOOK</h6>
                                    <div id="fbpage3">
                                      <div class="fb-page" :data-href="'' + facebook_page_url + ''" data-tabs="timeline" :data-width="getFbWidgetWidth()" data-height="1500" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-hide-dta="true" data-show-facepile="true"><blockquote :cite="'' + facebook_page_url + ''" class="fb-xfbml-parse-ignore"></blockquote></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div><!-- social end -->

        </div>
    </div>
</template>
<script>
  import {
    Alert,
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Card,
    Collapse,
    CollapseItem, 
    EventspotPagination,
    EventcirclePagination,
  } from '@/components';
  import Twitter from '../components/Twitter';
  import { API_ENDPOINT } from '@/constants'
  import {
    Loading,
    Carousel,
    CarouselItem,
    Table,
    TableColumn,
  } from 'element-ui';
  import dayjs from 'dayjs'
  import axios from "axios";

  export default {
    name: 'event',
    bodyClass: 'profile-page',
    components: {
      Alert,
      Card,
      Collapse,
      CollapseItem,
      Twitter,
      Badge,
      [EventspotPagination.name]: EventspotPagination,
      [EventcirclePagination.name]: EventcirclePagination,
      [Breadcrumb.name]: Breadcrumb,
      [BreadcrumbItem.name]: BreadcrumbItem,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
    },
    filters: {
      yyyy: function (date) {
        return dayjs(date).format('YYYY年');
      },
      mmddd: function (date) {
        return dayjs(date).format('M月D日 (ddd)');
      },
      hhmm: function (date) {
        return dayjs(date).format('HH:mm');
      },
      MMdd: function (date) {
        return dayjs(date).format('MM月DD日')
      },
    },
    async created() {
      try {
        this.loadingInstance = Loading.service({ fullscreen: true });
        await axios
          .get(API_ENDPOINT + `events/` + this.$route.params.id)
          .then(response => {
            this.event = response.data
            this.sliders = response.data.images
            this.spots = response.data.spots;
            this.satellites = response.data.satellites;
            this.sub_events = response.data.sub_events;
            this.event_targets = response.data.event_targets;
            this.twitter_id = response.data.twitter
            this.facebook_id = response.data.facebook
            this.facebook_page_url = 'https://www.facebook.com/' + response.data.facebook
            this.calendar_view_event = response.data.calendar_view_event

            response.data.event_targets.filter(tag => {
              if (tag.attribute === 1) {
                this.event_spot_ids = tag.spots
                axios
                  .get(API_ENDPOINT + `spots/?extract=` + this.event_spot_ids.join('&extract='))
                  .then(res => {
                    this.event_spots_meta = res.data.meta
                    this.event_spots = res.data.spots
                  })
              } else if (tag.attribute === 2) {
                this.event_circle_ids = tag.organizations
                axios
                  .get(API_ENDPOINT + `organizations/?extract=` + this.event_circle_ids.join('&extract='))
                  .then(res => {
                    this.event_circles_meta = res.data.meta
                    this.event_circles = res.data.organizations
                  })
              }
            });
            this.no_content = false
          })
        // layout ------------
        await axios
          .get(API_ENDPOINT + `appconstant/7`)
          .then(response => {
            this.order_form = response.data.text_value
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.loadingInstance.close();
      }
    },
    data() {
      return {
        no_content: true,
        event: {},
        twitter_id: '',
        facebook_id: '',
        facebook_page_url: '',
        sliders: [],
        spots: [],
        satellites: [],
        sub_events: [],
        event_targets: [],
        event_spots: [],
        event_spots_meta: '',
        event_spot_ids: {
          type: Array,
          default: []
        },
        event_circles: [],
        event_circles_meta: '',
        event_circle_ids: {
          type: Array,
          default: []
        },
        facebook_event_available: 0,
        calendar_view_event: 1,
        loadingInstance: null,
        order_form: '',
        currentPage: 1,
        pagination: {
            simple: 1,
            default: 2,
            full: 3
        },
        eventPagination: {
            simple: 1,
        },
      }
    },
    updated() {
      this.$nextTick(()=>{
        if (window.FB && window.FB.XFBML) {
          if (document.getElementById('fbpage1'))
            window.FB.XFBML.parse(document.getElementById('fbpage1'));
          if (document.getElementById('fbpage2'))
            window.FB.XFBML.parse(document.getElementById('fbpage2'));
          if (document.getElementById('fbpage3'))
            window.FB.XFBML.parse(document.getElementById('fbpage3'));
          if (document.getElementById('fbpage4'))
            window.FB.XFBML.parse(document.getElementById('fbpage4'));
          if (document.getElementById('fbpage5'))
            window.FB.XFBML.parse(document.getElementById('fbpage5'));
        }
      });
    },
    watch: {
      event: function() {
        this.$nextTick(() => {
            var positionY = sessionStorage.getItem('positionY')
            scrollTo(0, positionY);
            setTimeout(function(){
                scrollTo(0, positionY);
                }, 500);
            })
        this.no_content = (this.event == null)
        document.title = this.event.title
        if (this.event.alias !== null)
          document.title = this.event.title + ' | ' + this.event.alias

      },
      posts() {
        this.setPages();
      },
    },
    methods: {
      addNew: function(spot) {
        let day7ago = dayjs(Date.now()).add(-7, 'days')
        if (spot.charged && (dayjs(spot.updated_at).diff(day7ago) > 0)) {
          return 'add-new';
        }
      },
      pubNotExpired: function(event) {
        let is_after  = event.on_sale_pub_from !== null && dayjs(event.on_sale_pub_from).isBefore(dayjs()) //公開開始日時が設定されていて、かつ現在時刻より前
        let is_before = event.on_sale_pub_to !== null && dayjs(event.on_sale_pub_to).isAfter(dayjs())      //公開終了日時が設定されていて、かつ現在時刻より後
        let pub_until   = event.on_sale_pub_from == null && is_before
        let pub_endless = event.on_sale_pub_to == null && is_after
        let pub_between = is_after && is_before
        return pub_endless || pub_between || pub_until
      },
      snsViewable: function(event){
        let facebook_viewable = event.facebook !== null && event.facebook_event
        let google_calendar_viewable = event.google_calendar !== null && event.google_calendar_view >= 1
        return facebook_viewable || google_calendar_viewable;
      },
      bothViewable: function(event){
        let facebook_viewable = event.facebook !== null && event.facebook_event
        let google_calendar_viewable = event.google_calendar !== null && event.google_calendar_view >= 1
        return facebook_viewable && google_calendar_viewable;
      },
      onlyCalendarViewable: function(event){
        let facebook_not_viewable = event.facebook == null || !event.facebook_event
        let google_calendar_viewable = event.google_calendar !== null && event.google_calendar_view >= 1
        return facebook_not_viewable && google_calendar_viewable;
      },
      onlyFbViewable: function(event){
        let facebook_viewable = event.facebook !== null && event.facebook_event
        let google_calendar_not_viewable = event.google_calendar == null || event.google_calendar_view == null
        return facebook_viewable && google_calendar_not_viewable;
      },
      handleRowClick(spot) {
        if (spot.unique_domain !== null && spot.unique_domain !== '') {
          this.$router.push('/' +spot.unique_domain);
        } else {
          this.$router.push({ name: 'spot', params: { id: spot.id } });
        }
      },
      toEventDetail(subevent) {
        if (subevent.unique_domain !== null && subevent.unique_domain !== '') {
          this.$router.push('/' +subevent.unique_domain);
        } else {
          this.$router.push({ name: 'event', params: { id: subevent.id } });
        }
      },
      eventRowClick: function(event) {
        if (event.link_target_in_detail == 2) {
          var relative_path = event.internal_link.replace(/(https?:\/\/[^\/]+)/g, '')
          this.$router.push(relative_path)
        } else if (event.link_target_in_detail == 3) {
          window.open(event.external_link, "_blank");
        }
      },
      getCalendarView: function(event) {
        return event.google_calendar_view == 2 ? '&mode=AGENDA' : '';
      },
      getFbWidgetWidth: function() {
        if (window.innerWidth > 1200) {
          return 445;
        } else if (991 < window.innerWidth && window.innerWidth <= 1200) {
          return 370;
        } else if (768 < window.innerWidth && window.innerWidth <= 991) {
          return 270;
        } else if (window.innerWidth <= 533) {
          return window.innerWidth - 30;
        } else if (533 < window.innerWidth && window.innerWidth <= 767) {
          return 500;
        }
      },
      expired: function(end) {
        return dayjs(end).isBefore(dayjs());
      },
      setPages () {
        let numberOfPages = Math.ceil(this.event_spots.length / this.perPage);
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      replaceEventSpots: function(eventSpots) {
        this.event_spots = eventSpots
      },
      replaceEventCircles: function(eventCircles) {
        this.event_circles = eventCircles
      },
      changePage: function(e) {
        this.getPosts();
      },
    },
    computed: {
      event_logo_src(){
        return event.logo
      },
      event_spots_filtered: function() {
        return this.spots.filter(function(i) {
          return i.published;
        })
      },
      event_satellites_filtered: function() {
        return this.satellites.filter(function(i) {
          return i.published;
        })
      }
    },
  }
</script>
<style scoped lang="scss">
</style>
