import { render, staticRenderFns } from "./Spots.vue?vue&type=template&id=57739afa&scoped=true&"
import script from "./Spots.vue?vue&type=script&lang=js&"
export * from "./Spots.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57739afa",
  null
  
)

export default component.exports