import Vue from "vue";
import Router from "vue-router";
import Presentation from "./pages/Presentation.vue";
import MainNavbar from "./layout/MainNavbar.vue";
import MainFooter from "./layout/MainFooter.vue";

import SpotPage from "./pages/contents/Spot.vue";
import SpotsPage from "./pages/contents/Spots.vue";
import EventPage from "./pages/contents/Event.vue";
import EventsPage from "./pages/contents/Events.vue";
import CirclePage from "./pages/contents/Circle.vue";
import CirclesPage from "./pages/contents/Circles.vue";
import VideoPage from "./pages/contents/Video.vue";
import VideosPage from "./pages/contents/Videos.vue";
import TagsPage from "./pages/contents/Tags.vue";
import PrivacyPage from "./pages/contents/Privacy.vue";
import TermsOfService2Page from "./pages/contents/TermsOfService2.vue";
import TdaRulePage from "./pages/contents/Tda.vue";
import PrimaryRulePage from "./pages/contents/Rule.vue";
import SitemapPage from "./pages/contents/HtmlSitemap.vue";
import AnnouncementPage from "./pages/contents/Announcement.vue";
import AnnouncementsPage from "./pages/contents/Announcements.vue";
import Maintenance from "./pages/contents/Maintenance.vue";
import SalesPage from "./pages/contents/Sales.vue";
import OriginalContent from "./pages/contents/Blank.vue";

import SpotAlias from "./pages/contents/SpotAlias.vue";
import EventAlias from "./pages/contents/EventAlias.vue";
import CircleAlias from "./pages/contents/CircleAlias.vue";
import NotFound from "./pages/notfound.vue";

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    // メンテナンス時には、このルーティングのみを有効とする
/*
    {
      path: "*",
      name: "maintenance",
      components: {
        default: Maintenance,
        header: MainNavbar,
        footer: MainFooter
      },
      props: {
        header: {
          transparent: false,
          colorBlack: false
        }
      }
    },
*/
    {
      path: "/",
      name: "presentation",
      components: {default: Presentation, header: MainNavbar, footer: MainFooter},
      props: {header: {colorOnScroll: 100}}
    },
    {
      path: "/eventalias",
      components: {default: EventAlias, header: MainNavbar, footer: MainFooter},
      props: {header: {colorOnScroll: 40}},
      alias: [
        '/ajpc',
        '/ajpc-ladies',
        '/pokerkoshien2019',
        '/pokergrandprix-3rd',
        '/pokergrandprix-4th',
        '/sponichipokertournament2021',
        '/sponichipokertournament2022',
        '/sponichipokertournament2023',
        '/pokerkoshien2020'
      ]
    },
    {
      path: "/event/:id",
      name: "event",
      components: {default: EventPage, header: MainNavbar, footer: MainFooter},
      props: {header: {colorOnScroll: 40}}
    },
    {
      path: "/events",
      name: "events",
      components: {default: EventsPage, header: MainNavbar, footer: MainFooter},
      //props: {header: {colorOnScroll: 450}}
      props: {
        header: {
          transparent: false,
          colorBlack: false
        }
      }
    },
    {
      path: "/spotalias",
      components: {default: SpotAlias, header: MainNavbar, footer: MainFooter},
      props: {header: {colorOnScroll: 40}},
      alias: [
        '/hathaway',
        '/gutshot',
        '/kk-kajino',
        '/bigslick',
        '/gravity',
        '/gutshot-uenoikenohata',
        '/color',
        '/conapoke'
      ]
    },
    {
      path: "/spot/:id",
      name: "spot",
      components: {default: SpotPage, header: MainNavbar, footer: MainFooter},
      props: {header: {colorOnScroll: 40}},
    },
    {
      path: "/spots",
      name: "spots",
      components: {default: SpotsPage, header: MainNavbar, footer: MainFooter},
      //props: {header: {colorOnScroll: 450}}
      props: {
        header: {
          transparent: false,
          colorBlack: false
        }
      }
    },
    {
      path: "/announcement/:id",
      name: "announcement",
      components: {default: AnnouncementPage, header: MainNavbar, footer: MainFooter},
      props: {
        header: {
          transparent: false,
          colorBlack: false
        }
      }
    },
    {
      path: "/announcements",
      name: "announcements",
      components: {default: AnnouncementsPage, header: MainNavbar, footer: MainFooter},
      props: {
        header: {
          transparent: false,
          colorBlack: false
        }
      }
    },
    {
      path: "/circlealias",
      components: {default: CircleAlias, header: MainNavbar, footer: MainFooter},
      props: {header: {colorOnScroll: 40}},
      alias: [
        '/sample-circle',
        '/clubhaibi',
        '/bigfishku',
        '/okubo_poker',
        '/nestling',
        '/bookend',
        '/kyushuopenpoker',
        '/tus_poker',
        '/sample-circle-test'
      ]
    },
    {
      path: "/circle/:id",
      name: "circle",
      components: {default: CirclePage, header: MainNavbar, footer: MainFooter},
      props: {header: {colorOnScroll: 40}}
    },
    {
      path: "/circles",
      name: "circles",
      components: {default: CirclesPage, header: MainNavbar, footer: MainFooter},
      props: {
        header: {
          transparent: false,
          colorBlack: false
        }
      }
    },
    {
      path: "/video/:id",
      name: "video",
      components: {default: VideoPage, header: MainNavbar, footer: MainFooter},
      props: {
        header: {
          transparent: false,
          colorBlack: false
        }
      }
    },
    {
      path: "/videos",
      name: "videos",
      components: {default: VideosPage, header: MainNavbar, footer: MainFooter},
      props: {
        header: {
          transparent: false,
          colorBlack: false
        }
      }
    },
    {
      path: "/ticket",
      name: "ticket",
      components: {default: SalesPage, header: MainNavbar, footer: MainFooter},
      props: {
        header: {
          transparent: false,
          colorBlack: false
        }
      }
    },
    {
      path: "/privacy",
      name: "privacy",
      components: {default: PrivacyPage, header: MainNavbar, footer: MainFooter},
      props: {
        header: {
          transparent: false,
          colorBlack: false
        }
      }
    },
    {
      path: "/termsofservice2",
      name: "termsofservice2",
      components: {default: TermsOfService2Page, header: MainNavbar, footer: MainFooter},
      props: {
        header: {
          transparent: false,
          colorBlack: false
        }
      }
    },
    {
      path: "/tda",
      name: "tda",
      components: {default: TdaRulePage, header: MainNavbar, footer: MainFooter},
      props: {
        header: {
          transparent: false,
          colorBlack: false
        }
      }
    },
    {
      path: "/rule",
      name: "rule",
      components: {default: PrimaryRulePage, header: MainNavbar, footer: MainFooter},
      props: {
        header: {
          transparent: false,
          colorBlack: false
        }
      }
    },
    {
      path: "/content/:id",
      name: "content",
      components: {default: OriginalContent, header: MainNavbar, footer: MainFooter},
      props: {
        header: {
          transparent: false,
          colorBlack: false
        }
      }
    },
    {
      path: "/sitemap",
      name: "sitemap",
      components: {default: SitemapPage, header: MainNavbar, footer: MainFooter},
      props: {
        header: {
          transparent: false,
          colorBlack: false
        }
      }
    },
    {
      path: "*",
      name: "notfound",
      components: {default: NotFound, header: MainNavbar, footer: MainFooter},
      props: {
        header: {
          transparent: false,
          colorBlack: false
        }
      }
    },
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      sessionStorage.setItem('positionY', savedPosition.y);
      return savedPosition
    } else {
      sessionStorage.setItem('positionY', 0);
      return { x: 0, y: 0 }
    }
  },
});

/*
router.beforeEach((to, from, next) => {
  console.log('============================ global: beforeEach');
  console.log(to)
  console.log(from)

  var custom_domains = /aaaaa|bbbbb/g;
  if (!to.path.match(custom_domains))
    return;

  if (to.path.match(/aaaaa/g)) {
    console.log("================ brefore router.push")
    router.push({ name: 'spot', params: { id: '148' } })
    next({
      path: "/spot/148",
      name: "spot",
      components: {default: SpotPage, header: MainNavbar, footer: MainFooter},
      props: {header: {colorOnScroll: 40}}
    })
  }
});
*/

/*
    // リダイレクトとして機能してしまう
    router.beforeEach((to, from, next) => {
      if(to.path === '/aaaaa') {
        next({ path : '/spot/148', ninjaPath : '/aaaaa' })
        //next("/spot/148")
      }else{
        next();
      }
    })
*/

    /*
router.beforeEach((to, from, next) => {
   console.log('beforeEach')
   if (document.getElementById('facebook-jssdk')) {
     var element = document.getElementById('facebook-jssdk');
     element.parentNode.removeChild(element);
     console.log('remove facebook-jssdk');
    */
     /*
     (function (d, s, id) {
       var js, fjs = d.getElementsByTagName(s)[0];
       if (d.getElementById(id)) return;
       js = d.createElement(s);
       js.id = id;
       js.key = Date.now();
       js.src = 'https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v3.2&rdm='+Date.now();
       fjs.parentNode.insertBefore(js, fjs);
     }(document, 'script', 'facebook-jssdk'));
   }
   next();
});
     */

export default router;
