<template>
    <div v-if="this.loading" class="wrapper profile-page no-content">
        <div>コンテンツを読込中です</div>
    </div>
    <div v-else>
        <div v-if="this.no_content" class="wrapper profile-page no-content">
            <div class="spinner"></div>
        </div>
        <div v-else class="wrapper profile-page">

            <div class="page-header page-header-smalls clear-filter" filter-color="black">
                <div v-if="spot.charged_type === 1" class="page-header-image" style="background-image: url('https://storage.googleapis.com/pokernavi/images/shutterstock_295358264.jpg')">
                </div>
                <div v-else-if="spot.charged_type >= 2">
                    <div v-if="spot.image_header !== null" class="page-header-image"
                         :style="{ 'background-image': 'url('+ spot.image_header +')' }">
                    </div>
                    <div v-else class="page-header-image" style="background-image: url('https://storage.googleapis.com/pokernavi/images/bgheader_charged_type_1.jpg')"></div>
                </div>

                <div class="rellax-text-container rellax-text">
                    <div v-if="spot.charged_type >= 2 && spot.logo !== null" class="photo-container mb-2">
                        <div class="photo-container-inner" :style="{ 'background-image': 'url('+ spot.logo +')' }"></div>
                    </div>
                    <div v-else class="mb-5"></div>
                    <h1 class="h1-seo mb-2 col-sm-6 mr-auto ml-auto" data-rellax-speed="-2">{{ spot.name }}</h1>
                    <h6 class="category">{{ spot.alias }}</h6>
                </div>
            </div>
            <a v-if="spot.charged_type === 1" :href="order_form" target="_blank" class="register-wrap">
                <div class="register">
                   <span>無</span>
                   <span>料</span>
                   <span>掲</span>
                   <span>載</span>
                   <span>登</span>
                   <span>録</span>
                </div>
            </a>

            <div class="section section-components" data-background-color="white">
                <div class="container pt-3 mb-5">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }"><span class="text-white-50s"><i class="fa fa-home"></i></span></el-breadcrumb-item>
                        <el-breadcrumb-item>
                            <span>{{spot.name}}<span v-if="spot.alias !== null && spot.alias !== ''" class="mx-0">｜</span>{{spot.alias}}</span>
                        </el-breadcrumb-item>
                    </el-breadcrumb>
                </div>

                <div class="container">
                    <div class="mb-0 text-right">
                        <social-sharing
                            :url="currentURL()"
                            :title="getName(spot)"
                            :description="getDescription(spot.charged ? spot : '')"
                            :quote="getDescription(spot.charged ? spot : '')"
                            :hashtags="getHashtags(spot)"
                            twitter-user="PokerNavi_JP"
                            inline-template>
                            <div>
                                <network network="facebook">
                                    <div class="sns_share fb">
                                        <i class="fab fa-facebook-f mr-1" style="color:#fff"></i>
                                        <span class="font-weight-bold" style="font-size:.8rem">Share</span>
                                    </div>
                                </network>
                                <network network="twitter">
                                    <div class="sns_share twitter">
                                        <i class="fab fa-twitter mr-1" style="color:#fff"></i>
                                        <span class="font-weight-bold" style="font-size:.7rem;vertial-align:middle">ツイート</span>
                                    </div>
                                </network>
                            </div>
                        </social-sharing>
                    </div>
                </div>

                <div class="container pb-3">
                        <p class="blockquote blockquote-primary">
                            <strong class="font-weight-bold" style="font-size:1.5rem">{{spot.name}}</strong><br />
                            <small v-if="spot.alias !== null && spot.alias !== ''">{{ spot.alias }}<br /></small>
                            <small><br />
                                <span v-if="spot.online">
                                    <i class="far fa-globe mr-2"></i>オンライン
                                </span>
                                <span v-else>
                                    <span v-if="false">
                                        [要件]
                                        未登録店舗：店舗名, 都道府県市町村
                                        無料登録店舗：店舗名, 都道府県市町村, 番地以下も表示
                                        有料登録店舗：無料登録店舗 + GoogleMapへのリンク
                                    </span>
                                    <a v-if="spot.charged_type === 10"
                                       :href="'https://www.google.com/maps/search/?api=1&query=' + search_address"
                                        target="_blank">
                                        <i class="far fa-map-marker-alt mr-2"></i>
                                        {{spot.prefecture ? spot.prefecture.name : ''}}{{spot.city}}
                                        <span v-if="true" class="">{{ spot.address }}{{ spot.buildings_name }}</span>
                                    </a>
                                    <span v-else>
                                        <i class="far fa-map-marker-alt mr-2"></i>
                                        {{spot.prefecture ? spot.prefecture.name : ''}}{{spot.city}}
                                        <span v-if="spot.charged_type === 2" class="">
                                            {{ spot.address }}{{ spot.buildings_name }}
                                        </span>
                                    </span>
                                </span>
                                <span v-if="spot.charged_type === 2 && spot.phone_enabled && spot.phone !== ''"><br />
                                    <i class="fa fa-phone mr-2"></i>{{spot.phone}}
                                </span>
                            </small>
                        </p>
                    <div v-if="spot.charged_type === 1" class="text-center ml-auto mr-auto mb-5">
                        <a :href="order_form" target="_blank" class="btn btn-lg btn-simples btn-primary btn-rounds text-white mb-4">
                            <i class="fa fa-share mr-2"></i>無料店舗登録はコチラ
                        </a>
                        <div>
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfkWuDqNTH-YzFT686BElGVpXQ7vcJ92eoTa9t37GRcDeRpEQ/viewform" target="_blank">掲載店舗の閉店/移転、その他お問合せについて</a>
                        </div>
                    </div>
                </div>

                <div v-if="spot.charged && (todays_events.length > 0 || future_events.length > 0)" class="container">
                    <div class="row">
                        <div class="col-md-12 ml-auto mr-auto">

                            <div v-if="todays_events.length > 0">
                                <h6 class="with-title-dark">本日開催｜{{ Date.now() | yyyyMMdd}}</h6>
                                <div v-if="todays_events.length > 0" >
                                    <el-table :data="todays_events" class="mb-5">
                                        <el-table-column label="開始" min-width="70" align="center">
                                            <template slot-scope="scope">{{ scope.row.start | HHmm }}</template>
                                        </el-table-column>
                                        <el-table-column label="終了" min-width="70" align="center">
                                            <template slot-scope="scope">{{ scope.row.end | HHmm }}</template>
                                        </el-table-column>
                                        <el-table-column min-width="220"
                                                         align="left"
                                                         prop="title"
                                                         label="イベント">
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>

                            <div v-if="future_events.length > 0">
                                <h6 class="with-title-dark">今後の開催</h6>
                                <el-table v-if="" :data="future_events" class="mb-5">
                                    <el-table-column label="日時" min-width="90" align="center">
                                        <template slot-scope="scope">{{ scope.row.start | MMdd }}</template>
                                    </el-table-column>
                                    <el-table-column label="開始" min-width="70" align="center">
                                        <template slot-scope="scope">{{ scope.row.start | HHmm }}</template>
                                    </el-table-column>
                                    <el-table-column label="終了" min-width="70" align="center">
                                        <template slot-scope="scope">{{ scope.row.end | HHmm }}</template>
                                    </el-table-column>
                                    <el-table-column min-width="220"
                                                     align="left"
                                                     prop="title"
                                                     label="イベント">
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </div>
                </div><!-- event end -->
            </div>


            <div class="slider-container" data-background-color="white">
                <div class="google-calendar-mask">

                    <div v-if="spot.charged && tournaments.length > 0" class="container mb-4">
                        <h6 class="with-title-dark"><i v-if="false" class="far fa-id-card mr-2"></i>開催中の大会</h6>
                        <div class="cards">
                            <div v-if="tournaments.length > 0" class="card-columns mb-5">
                                <div v-for="(tournament, i) in tournaments" :key="tournament.id">
                                    <router-link v-if="tournament.unique_domain !== null && tournament.unique_domain !== ''" :to="'/' + tournament.unique_domain" tag="a">
                                        <a>
                                            <card :color="setOrange(tournament.charged ? tournament.thumbnail : '')"
                                                  :cardBodyClasses="addNew(tournament)" >
                                                <img v-if="tournament.charged && tournament.thumbnail !== null" slot="image" class="img rounded" v-lazy="tournament.thumbnail">
                                                <h6 class="category text-primary font-weight-normal mb-4 text-left">
                                                    <small class="mr-1 " style="border:1px solid #ccc;padding:5px 10px;">決勝</small>
                                                    <span v-if="tournament.end !== null" class="py-1 px-2" style="border-bottom:1px solid #ccc">
                                                        {{tournament.end | yyyyMMddd }}
                                                    </span>
                                                    <span v-else class="py-1 px-2" style="">---</span>
                                                </h6>
                                                <h5 class="card-title font-weight-bold">{{tournament.title}}</h5>
                                                <p v-if="tournament.charged && false" class="card-description" v-html="$options.filters.readmore(tournament.overview, 100)"></p>
                                            </card>
                                        </a>
                                    </router-link>
                                    <router-link v-else :to="{ name: 'event', params: { id: tournament.id }}" tag="a">
                                        <a>
                                            <card :color="setOrange(tournament.charged ? tournament.thumbnail : '')"
                                                  :cardBodyClasses="addNew(tournament)" >
                                                <img v-if="tournament.charged && tournament.thumbnail !== null" slot="image" class="img rounded" v-lazy="tournament.thumbnail">
                                                <h6 class="category text-primary font-weight-normal mb-4 text-left">
                                                    <small class="mr-1 " style="border:1px solid #ccc;padding:5px 10px;">決勝</small>
                                                    <span v-if="tournament.end !== null" class="py-1 px-2" style="border-bottom:1px solid #ccc">
                                                        {{tournament.end | yyyyMMddd }}
                                                    </span>
                                                    <span v-else class="py-1 px-2" style="">---</span>
                                                </h6>
                                                <h5 class="card-title font-weight-bold">{{tournament.title}}</h5>
                                                <p v-if="tournament.charged && false" class="card-description" v-html="$options.filters.readmore(tournament.overview, 100)"></p>

                                            </card>
                                        </a>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container mb-3">
                        <div v-if="spot.charged && spot.youtube !== null && spot.youtube !== '' && spot.youtube_enabled">
                            <h6 class="with-title-dark"><i v-if="false" class="far fa-id-card mr-2"></i>VIDEO</h6>
                            <div class="youtube_wrap mb-4">
                                <iframe class="youtube_content" :src="'https://www.youtube.com/embed/' + spot.youtube + ''" frameborder="0" allow="accelerometer;autoplay;encrypted-media;gyroscope;picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>

                        <div v-if="spot.charged && sliders.length > 0" class="slider-image-wrap mb-4">
                                <el-carousel height="" type="" arrow="always">
                                    <el-carousel-item v-for="(slider_image,key) in sliders" :key="key" class="carousel-item">
                                        <!--  no-repeat center center;background-size:cover; とすると表示できない -->
                                        <div v-if="true" class="slider-image" :style="{ 'background-image':'url(' + slider_image + ')' }">
                                            <div v-if="false" class="slider-image clear-filter" filter-color="blue"></div>
                                        </div>
                                    </el-carousel-item>
                                </el-carousel>
                            <div class="slider-element">
                            </div>
                        </div>

                        <div v-if="spot.charged" class="row mb-4">
                            <div :class="getGridLeft(spot) + ' mb-3'">
                                <h6 v-if="spot.announcement !== null && spot.announcement !== ''" class="with-title-dark">お知らせ</h6>
                                <div v-if="spot.announcement !== null && spot.announcement !== ''">
                                    <p class="col-12 mb-5 description">{{spot.announcement}}</p>
                                </div>
                                <div v-if="spot.system !== null && spot.system !== ''">
                                    <h6 class="with-title-dark"><i v-if="false" class="far fa-yen-sign mr-2"></i>システム</h6>
                                    <p class="col-12 description">{{spot.system}}</p>
                                </div>
                            </div>
                            <div :class="getGridRight(spot) + ' mb-3'">
                                <div v-if="spot.open_close !== null && spot.open_close !== ''">
                                    <h6 class="with-title-dark"><i v-if="false" class="far fa-clock mr-2"></i>営業時間</h6>
                                    <p class="mb-5 col-12 description">{{spot.open_close}}</p>
                                </div>
                                <div v-if="(spot.access !== null && spot.access !== '') || (spot.access_url !== null && spot.access_url !== '')" class="mb-5">
                                    <h6 class="with-title-dark"><i v-if="false" class="far fa-map-marked-alt mr-2"></i>アクセス</h6>
                                    <p class="col-12 description mb-4">{{spot.access}}</p>
                                    <p v-if="spot.access_url !== null && spot.access_url !== ''" class="col-12 description mb-4">
                                      <i class="fa fa-external-link mr-2"></i>
                                      <a :href="spot.access_url" target="_blank">
                                        <strong>
                                          <span v-if="spot.access_url_text">{{ spot.access_url_text }}</span>
                                          <span v-else>{{spot.access_url}}</span>
                                        </strong>
                                      </a>
                                    </p>
                                    <p v-if="spot.access_url2 !== null && spot.access_url2 !== ''" class="col-12 description mb-4">
                                      <i class="fa fa-external-link mr-2"></i>
                                      <a :href="spot.access_url2" target="_blank">
                                        <strong>
                                          <span v-if="spot.access_url2_text">{{ spot.access_url2_text }}</span>
                                          <span v-else>{{spot.access_url2}}</span>
                                        </strong>
                                      </a>
                                    </p>
                                </div>
                                <div v-if="spot.charged && (
                                            (spot.phone_enabled && spot.phone !== null && spot.phone !== '')
                                            || (spot.email_enabled && spot.email !== null && spot.email !== '')
                                            || (spot.web !== null && spot.web !== '')
                                            || (spot.desc_url !== null && spot.desc_url !== '')
                                            || (spot.desc_url2 !== null && spot.desc_url2 !== '')
                                            || (spot.desc_url3 !== null && spot.desc_url3 !== '')
                                            || (spot.desc_url4 !== null && spot.desc_url4 !== '')
                                            || (spot.desc_url5 !== null && spot.desc_url5 !== '')
                                )">
                                    <h6 class="with-title-dark">基本情報</h6>
                                    <p v-if="spot.phone_enabled && spot.phone !== null && spot.phone !== ''" class="col-12 description mb-4"><i class="fa fa-phone mr-2"></i>{{spot.phone}}</p>
                                    <p v-if="spot.email_enabled && spot.email !== null && spot.email !== ''" class="col-12 description mb-4"><i class="fa fa-envelope mr-2"></i>{{spot.email}}</p>
                                    <p v-if="spot.charged && spot.web !== null && spot.web !== ''" class="col-12 description mb-4"><i class="fa fa-globe mr-2"></i><a :href="spot.web" target="_blank"><strong>{{spot.name}} 公式サイト</strong></a></p>
                                    <p v-if="spot.desc_url !== null && spot.desc_url !== ''" class="col-12 description mb-4">
                                      <i class="fa fa-globe mr-2"></i>
                                      <a :href="spot.desc_url" target="_blank">
                                        <strong>
                                          <span v-if="spot.desc_url_text">{{ spot.desc_url_text }}</span>
                                          <span v-else>{{spot.desc_url}}</span>
                                        </strong>
                                      </a>
                                    </p>
                                    <p v-if="spot.desc_url2 !== null && spot.desc_url2 !== ''" class="col-12 description mb-4">
                                      <i class="fa fa-globe mr-2"></i>
                                      <a :href="spot.desc_url2" target="_blank">
                                        <strong>
                                          <span v-if="spot.desc_url2_text">{{ spot.desc_url2_text }}</span>
                                          <span v-else>{{spot.desc_url2}}</span>
                                        </strong>
                                      </a>
                                    </p>
                                    <p v-if="spot.desc_url3 !== null && spot.desc_url3 !== ''" class="col-12 description mb-4">
                                      <i class="fa fa-globe mr-2"></i>
                                      <a :href="spot.desc_url3" target="_blank">
                                        <strong>
                                          <span v-if="spot.desc_url3_text">{{ spot.desc_url3_text }}</span>
                                          <span v-else>{{spot.desc_url3}}</span>
                                        </strong>
                                      </a>
                                    </p>
                                    <p v-if="spot.desc_url4 !== null && spot.desc_url4 !== ''" class="col-12 description mb-4">
                                      <i class="fa fa-globe mr-2"></i>
                                      <a :href="spot.desc_url4" target="_blank">
                                        <strong>
                                          <span v-if="spot.desc_url4_text">{{ spot.desc_url4_text }}</span>
                                          <span v-else>{{spot.desc_url4}}</span>
                                        </strong>
                                      </a>
                                    </p>
                                    <p v-if="spot.desc_url5 !== null && spot.desc_url5 !== ''" class="col-12 description mb-4">
                                      <i class="fa fa-globe mr-2"></i>
                                      <a :href="spot.desc_url5" target="_blank">
                                        <strong>
                                          <span v-if="spot.desc_url5_text">{{ spot.desc_url5_text }}</span>
                                          <span v-else>{{spot.desc_url5}}</span>
                                        </strong>
                                      </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="false && spot.google_calendar_enabled && spot.google_calendar !== null && spot.google_calendar !== ''" class="container">
                        <div class="pb-5">
                            <iframe :src="'https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23e3e7ff&amp;ctz=Asia%2FTokyo&amp;src=' +spot.google_calendar+ '&amp;color=%23ffffff&amp;showTitle=0&amp;showNav=1&amp;showPrint=0&amp;showTabs=0&amp;showCalendars=0&amp;showTz=0'"
                                    style="border-width:0;width:100%" class="pb-2" height="600" frameborder="0" scrolling="no"></iframe>
                        </div>
                    </div>


                    <div v-if="spot.charged && snsViewable(spot)">
                        <div class="section pb-3" data-background-color="white">
                            <div class="container mx-auto">
                                <div v-if="bothViewable(spot)">
                                    <div class="row">
                                        <div class="col-md-7 pb-5">
                                            <h6 v-if="false" class="with-title mb-3 pl-3">CALENDAR</h6>
                                            <iframe :src="'https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23e3e7ff&amp;ctz=Asia%2FTokyo&amp;src=' +spot.google_calendar+ '&amp;color=%23ffffff&amp;showTitle=0&amp;showNav=1&amp;showPrint=0&amp;showTabs=1&amp;showCalendars=0&amp;showTz=0'+getCalendarView(spot)"
                                                    style="border-width:0;width:100%" class="pb-2" height="600" frameborder="0" scrolling="no"></iframe>
                                        </div>
                                        <div class="col-md-5" id="fbpage1">
                                            <h6 v-if="false" class="with-title mb-3 pl-3">EVENTS</h6>
                                            <div class="fb-page" :data-href="'' + facebook_page_url + ''" data-tabs="events" :data-width="getFbWidgetWidth()" data-height="600" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-hide-dta="true" data-show-facepile="true"><blockquote :cite="'' + facebook_page_url + ''" class="fb-xfbml-parse-ignore"></blockquote></div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="onlyFbViewable(spot)">
                                        <div class="" id="fbpage1">
                                            <h6 v-if="false" class="with-title mb-3 pl-3">EVENTS</h6>
                                            <div class="fb-page" :data-href="'' + facebook_page_url + ''" data-tabs="events" :data-width="getFbWidgetWidth()" data-height="600" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-hide-dta="true" data-show-facepile="true"><blockquote :cite="'' + facebook_page_url + ''" class="fb-xfbml-parse-ignore"></blockquote></div>
                                        </div>
                                </div>
                                <div v-else-if="onlyCalendarViewable(spot)">
                                    <h6 v-if="false" class="with-title mb-3 pl-3">CALENDAR</h6>
                                    <div class="pb-5">
                                        <iframe :src="'https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23e3e7ff&amp;ctz=Asia%2FTokyo&amp;src=' +spot.google_calendar+ '&amp;color=%23ffffff&amp;showTitle=0&amp;showNav=1&amp;showPrint=0&amp;showTabs=1&amp;showCalendars=0&amp;showTz=0'+getCalendarView(spot)"
                                                style="border-width:0;width:100%" class="pb-2" height="600" frameborder="0" scrolling="no"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="spot.charged && ((spot.facebook_timeline && spot.facebook !== null ) || (spot.twitter_enabled && spot.twitter !== null))">
                <div class="section mb-3" data-background-color="white">
                    <div class="container mx-auto">
                        <div v-if="spot.twitter_enabled && spot.twitter !== null && (spot.facebook == null || !spot.facebook_timeline)">
                            <div class="row">
                                <div v-if="spot.twitter_enabled && spot.twitter !== null" class="col-12 mb-5">
                                    <h6 class="with-title-dark">TWITTER</h6>
                                    <div class="col-12">
                                        <twitter v-if="true"></twitter>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="spot.facebook_timeline && spot.facebook !== null && (spot.twitter == null || !spot.twitter_enabled)">
                            <div class="row">
                                <div class="col-12 mb-5">
                                    <h6 class="with-title-dark">FACEBOOK</h6>
                                    <div id="fbpage" class="">
                                      <div class="fb-page" :data-href="'' + facebook_page_url + ''" data-tabs="timeline" :data-width="getFbWidgetWidth()" data-height="1500" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-hide-dta="true" data-show-facepile="true"><blockquote :cite="'' + facebook_page_url + ''" class="fb-xfbml-parse-ignore"></blockquote></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="row">
                                <div v-if="spot.twitter_enabled && spot.twitter !== null" class="col-md-7 mb-5">
                                    <h6 class="with-title-dark">TWITTER</h6>
                                    <twitter v-if="true"></twitter>
                                </div>
                                <div v-if="spot.facebook_timeline && spot.facebook !== null" class="col-md-5 mb-5">
                                    <h6 class="with-title-dark">FACEBOOK</h6>
                                    <div id="fbpage" class="">
                                      <div class="fb-page" :data-href="'' + facebook_page_url + ''" data-tabs="timeline" :data-width="getFbWidgetWidth()" data-height="1500" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-hide-dta="true" data-show-facepile="true"><blockquote :cite="'' + facebook_page_url + ''" class="fb-xfbml-parse-ignore"></blockquote></div>
                                    </div>
                                </div>
                                <div v-if="false && spot.facebook_timeline && spot.facebook !== null" class="col-12 mb-5">
                                    <h6 class="with-title-dark"><i v-if="false" class="fab fa-facebook-f mr-2"></i>FACEBOOK</h6>
                                    <comment v-for="(fbpost, index) in spot.fbposts"
                                             :key="index"
                                             author=""
                                             :date="fbpost.time | yyyyMMddd"
                                             :avatar="getAvatar(fbpost.image, spot.logo)"
                                             :comment="fbpost.text"
                                             :post_image="fbpost.image"
                                             :replies="fbpost.replies">
                                        <card type="blog">
                                            <img slot="image" class="img rounded" :src="fbpost.image">
                                        </card>
                                        <template slot="actions">
                                            <div :class="{'btn-default': fbpost.likes == 0}" class="btn btn-neutral pull-right">
                                                <i v-if="fbpost.likes > 0" class="fa fa-heart"></i>
                                                <i v-else="" class="far fa-heart"></i>
                                                {{fbpost.likes}}
                                            </div>
                                        </template>
                                    </comment>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div><!-- social end -->

            <div v-if="!spot.charged" class="pt-4 mb-3">
                <div class="adsense-infeed text-center">
                    <dfp-slot
                        path="/21867335047/pokernavi/pokernavi-footer"
                        :size="dfpSlotSize('div-gpt-ad-1580361234575-0')"
                        id="div-gpt-ad-1580361234575-0"
                    />
                </div>
            </div>

        </div>
    </div>
</template>
<script>
  import {
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Card,
    Collapse,
    CollapseItem,
  } from '@/components';
  import {
    Carousel,
    CarouselItem,
    Loading,
    Table,
    TableColumn,
  } from 'element-ui';
  import axios from "axios";
  import dayjs from 'dayjs'
  import Twitter from '../components/Twitter';
  import { API_ENDPOINT } from '@/constants'
  import DfpSlot from '../components/DfpSlot.vue'

  export default {
    name: 'spot',
    bodyClass: 'profile-page',
    components: {
      Badge,
      Card,
      Collapse,
      CollapseItem,
      Twitter,
      [Breadcrumb.name]: Breadcrumb,
      [BreadcrumbItem.name]: BreadcrumbItem,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      DfpSlot
    },
    props: {
      id: Number,
    },
    filters: {
      MMdd: function (date) {
        return dayjs(date).format('MM月DD日')
      },
      HHmm: function (date) {
        return dayjs(date).format('HH:mm')
      },
      yyyyMMdd: function (date) {
        return dayjs(date).format('YYYY年MM月DD日')
      },
      yyyyMMddd: function (date) {
        return dayjs(date).format('YYYY年M月D日 (ddd)');
      },
    },
    async created() {
      try {
        this.loading = true;
        this.loadingInstance = Loading.service({ fullscreen: true });
        this.getSpot();
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false;
        this.loadingInstance.close();
      }
    },
    updated() {
      this.$nextTick(()=>{
        if (window.FB && window.FB.XFBML) {
          if (document.getElementById('fbpage')) {
            window.FB.XFBML.parse(document.getElementById('fbpage'));
          }
        }
      });
    },
    methods: {
      dfpSlotSize: function(key) {
        let dfp_slots_sizes = this.dfp_slots[key]
          return dfp_slots_sizes['300']
/*
        if (1200 <= window.innerWidth) {
          return dfp_slots_sizes['336']
        } else if(767 < window.innerWidth && window.innerWidth < 1199) {
          return dfp_slots_sizes['336']
        } else if (window.innerWidth <= 517) {
          return dfp_slots_sizes['336']
        }
*/
      },
      addNew: function(tournament) {
        let day5ago = dayjs(Date.now()).add(-5, 'days')
        if (tournament.charged && tournament.is_new && (dayjs(tournament.updated_at).diff(day5ago) > 0)) {
          return 'add-new';
        }
      },
      setOrange: function(image) {
        if (!image) {
          return 'orange';
        }
      },
      getAvatar: function(post_image, logo) {
        return post_image == '' ? logo : '';
      },
      eventViewable: function(tournament) {
        return dayjs(tournament.end).isAfter(dayjs().add(-6, 'month'))
      },
      snsViewable: function(spot){
        let facebook_viewable = spot.facebook !== null && spot.facebook_event
        let google_calendar_viewable = spot.google_calendar !== null && spot.google_calendar_view >= 1
        return facebook_viewable || google_calendar_viewable;
      },
      bothViewable: function(spot){
        let facebook_viewable = spot.facebook !== null && spot.facebook_event
        let google_calendar_viewable = spot.google_calendar !== null && spot.google_calendar_view >= 1
        return facebook_viewable && google_calendar_viewable;
      },
      onlyCalendarViewable: function(spot){
        let facebook_not_viewable = spot.facebook == null || !spot.facebook_event
        let google_calendar_viewable = spot.google_calendar !== null && spot.google_calendar_view >= 1
        return facebook_not_viewable && google_calendar_viewable;
      },
      onlyFbViewable: function(spot){
        let facebook_viewable = spot.facebook !== null && spot.facebook_event
        let google_calendar_not_viewable = spot.google_calendar == null || spot.google_calendar_view == null
        return facebook_viewable && google_calendar_not_viewable;
      },
      getCalendarView: function(spot) {
        return spot.google_calendar_view == 2 ? '&mode=AGENDA' : '';
      },
      getFbWidgetWidth: function() {
        if (window.innerWidth > 1200) {
          return 445;
        } else if (991 < window.innerWidth && window.innerWidth <= 1200) {
          return 370;
        } else if (768 < window.innerWidth && window.innerWidth <= 991) {
          return 270;
        } else if (window.innerWidth <= 533) {
          return window.innerWidth - 30;
        } else if (533 < window.innerWidth && window.innerWidth <= 767) {
          return 500;
        }
      },
      title: function() {
        return this.spot.name
      },
      getFbTag: function(fb_account) {
        return '<div class="fb-page" data-href="https://www.facebook.com/' + fb_account + '" data-tabs="timeline" data-width="500" data-height="1500" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-hide-dta="true" data-show-facepile="true"><blockquote v-bind:cite="https://www.facebook.com/' + fb_account + '" class="fb-xfbml-parse-ignore"></blockquote></div><span style="visibility:hidden">' + Date.now() + '</span>';
      },
      getGridLeft: function(spot) {
        let announcementExists = spot.announcement !== null && spot.announcement !== '';
        let systemExists = spot.system !== null && spot.system !== '';
        let openCloseExists = spot.open_close !== null && spot.open_close !== '';
        let accessExists = (spot.access !== null && spot.access !== '') || (spot.access_url !== null && spot.access_url !== '');
        let infoExists = spot.charged && ((spot.phone_enabled && spot.phone !== null && spot.phone !== '')
                          || (spot.email_enabled && spot.email !== null && spot.email !== '')
                          || (spot.web !== null && spot.web !== '')
                          || (spot.desc_url !== null && spot.desc_url !== '')
                          || (spot.desc_url2 !== null && spot.desc_url2 !== '')
                          || (spot.desc_url3 !== null && spot.desc_url3 !== '')
                          || (spot.desc_url4 !== null && spot.desc_url4 !== '')
                          || (spot.desc_url5 !== null && spot.desc_url5 !== ''));
        if (announcementExists || systemExists) {
          return (openCloseExists || accessExists || infoExists) ? 'col-md-7' : 'col-12';
        } else {
          return 'd-none';
        }
      },
      getGridRight: function(spot) {
        let announcementExists = spot.announcement !== null && spot.announcement !== '';
        let systemExists = spot.system !== null && spot.system !== '';
        let openCloseExists = spot.open_close !== null && spot.open_close !== '';
        let accessExists = (spot.access !== null && spot.access !== '') || (spot.access_url !== null && spot.access_url !== '');
        let infoExists = spot.charged && ((spot.phone_enabled && spot.phone !== null && spot.phone !== '')
                          || (spot.email_enabled && spot.email !== null && spot.email !== '')
                          || (spot.web !== null && spot.web !== '')
                          || (spot.desc_url !== null && spot.desc_url !== '')
                          || (spot.desc_url2 !== null && spot.desc_url2 !== '')
                          || (spot.desc_url3 !== null && spot.desc_url3 !== '')
                          || (spot.desc_url4 !== null && spot.desc_url4 !== '')
                          || (spot.desc_url5 !== null && spot.desc_url5 !== ''));
        if (announcementExists || systemExists) {
          return (openCloseExists || accessExists || infoExists) ? 'col-md-5' : 'd-none';
        } else {
          return (openCloseExists || accessExists || infoExists) ? 'col-12' : 'd-none';
        }
      },
      getSpot: function() {
        axios
          .get(API_ENDPOINT + `spots/` + this.$route.params.id + '?detail')
          .then(response => {
            this.spot = response.data

            this.sliders = response.data.images
            this.events = response.data.events
            this.twitter_id = response.data.twitter
            this.facebook_id = response.data.facebook
            this.facebook_page_url = 'https://www.facebook.com/' + response.data.facebook
            this.componentKey = Date.now()
            this.calendar_view_event = response.data.calendar_view_event
            this.tournaments = response.data.tournaments

            if (this.events !== null) {
              let today = dayjs(Date.now()).format('YYYY/MM/DD')
              for (let [key, value] of Object.entries(this.events)) {
                key == today
                  ? (this.todays_events = value)
                  : Array.prototype.push.apply(this.future_events, value);
              }
            }
            this.search_address = this.spot.prefecture !== null ? this.spot.prefecture.name : '';
            this.search_address = this.search_address + (this.spot.city || '');
            this.search_address = this.search_address + (this.spot.address || '');
            this.search_address = this.search_address + (this.spot.buildings_name || '');
            this.no_content = false
          })
          .catch (function(error) {
            //
          })
        // layout ------------
        axios
          .get(API_ENDPOINT + `appconstant/7`)
          .then(response => {
            this.order_form = response.data.text_value
        })
      }
    },
    watch: {
      spot: function() {
        this.$nextTick(() => {
            var positionY = sessionStorage.getItem('positionY')
            scrollTo(0, positionY);
            setTimeout(function(){
                scrollTo(0, positionY);
                }, 500);
        })
        this.no_content = (this.spot == null)
        document.title = this.spot.name //' | ポーカーナビ | 全国のポーカースポット、サークル、トーナメント情報を配信！'
        if (this.spot.alias !== null)
          document.title = this.spot.name + ' | ' + this.spot.alias
      }
    },
    data() {
      return {
        no_content: true,
        componentKey:0,
        spot: {},
        search_address: '',
        sliders: [],
        events: [],
        todays_events: [],
        future_events: [],
        tournaments: [],
        twitter_id: '',
        facebook_id: '',
        facebook_page_url: '',
        loading: false,
        loadingInstance: null,
        calendar_view_event: 0,
        form: {
          firstName: '',
          email: '',
          notRobot: false
        },
        modals: {
          notice: false
        },
        order_form: '',
        dfp_slots: {
          'div-gpt-ad-1571972845074-0': { // 不明
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580282482771-0': { // 不明
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580355841225-0': { // circles
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580353644223-0': { // spots
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580356736019-0': { // events
            336: [336, 280]
          },
          'div-gpt-ad-1580361234575-0': { // footer
            300: [300, 250]
          },
        },
/*
        dfp_slots: {
          'div-gpt-ad-1571972845074-0': { // 不明
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580282482771-0': { // 不明
            300: [300, 250],
            336: [336, 280]
          },
        },
*/
        options: {
          styles: [{
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [{
              "color": "#e9e9e9"
            }, {
              "lightness": 17
            }]
          }, {
            "featureType": "landscape",
            "elementType": "geometry",
            "stylers": [{
              "color": "#f5f5f5"
            }, {
              "lightness": 20
            }]
          }, {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [{
              "color": "#ffffff"
            }, {
              "lightness": 17
            }]
          }, {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [{
              "color": "#ffffff"
            }, {
              "lightness": 29
            }, {
              "weight": 0.2
            }]
          }, {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [{
              "color": "#ffffff"
            }, {
              "lightness": 18
            }]
          }, {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [{
              "color": "#ffffff"
            }, {
              "lightness": 16
            }]
          }, {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [{
              "color": "#f5f5f5"
            }, {
              "lightness": 21
            }]
          }, {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [{
              "color": "#dedede"
            }, {
              "lightness": 21
            }]
          }, {
            "elementType": "labels.text.stroke",
            "stylers": [{
              "visibility": "on"
            }, {
              "color": "#ffffff"
            }, {
              "lightness": 16
            }]
          }, {
            "elementType": "labels.text.fill",
            "stylers": [{
              "saturation": 36
            }, {
              "color": "#333333"
            }, {
              "lightness": 40
            }]
          }, {
            "elementType": "labels.icon",
            "stylers": [{
              "visibility": "off"
            }]
          }, {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [{
              "color": "#f2f2f2"
            }, {
              "lightness": 19
            }]
          }, {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [{
              "color": "#fefefe"
            }, {
              "lightness": 20
            }]
          }, {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [{
              "color": "#fefefe"
            }, {
              "lightness": 17
            }, {
              "weight": 1.2
            }]
          }]

        }
      }
    },
  }
</script>
