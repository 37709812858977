
const GlobalMixins = {
  install (Vue) {
    Vue.mixin({
      mounted() {
        let {bodyClass} = this.$options;
        if(bodyClass) {
          document.body.classList.add(bodyClass);
        }
        /*
        let {title} = this.$options
        if (title) {
          title = typeof title === 'function' ? title.call(this) : title
          document.title = `${title}`
        }
        */
      },
      beforeDestroy() {
        let {bodyClass} = this.$options;
        if(bodyClass) {
          document.body.classList.remove(bodyClass);
        }
      },
      data() {
        return {
          isAdvertise: false,
        }
      },
      methods: {
        currentURL: function(){
          return location.href;
        },
        getTitle: function(obj) {
          //console.log("title : "+obj.title)
          if (!obj)
            return;
          return obj.title;
        },
        getName: function(obj) {
          //console.log("name : "+obj.name)
          if (!obj)
            return;
          return obj.name;
        },
        getSubtitle: function(obj) {
          //console.log("subtitle : "+obj.sub_title)
          if (!obj)
            return;
          return obj.sub_title;
        },
        getOverview: function(obj) {
          //console.log("overview : "+obj.overview)
          if (!obj)
            return;
          return obj.overview;
        },
        getDescription: function(obj) {
          //console.log("description : "+obj.description)
          if (!obj)
            return;
          return obj.description;
        },
        getAnnouncement: function(obj) {
          //console.log("announcement : "+obj.announcement)
          if (!obj)
            return;
          return obj.announcement;
        },
        getHashtags: function(obj) {
          if (obj !== null)
            return "ポーカー,ポーカーナビ";

          let tag = obj.title ? obj.title : obj.name;
          return tag.replace(/\s+/g, '') + ",ポーカー,ポーカーナビ";
        },
      }
    })
  }
}

export default GlobalMixins
