<template>
    <div class="wrapper presentation-page">

        <vue-backTop v-if="true" :height="600" :bottom="50" :right="30" @onClick="" @complete="">
            <div class="backtop">
                <i class="fa fa-spade display-5 text-info"></i>
            </div>
        </vue-backTop>

        <div id="poker-spot" data-background-color="white">
            <div class="space-100"></div>
            <div class="container">
                <div class="mb-5">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }"><span class="text-black-50"><i class="fa fa-home"></i></span></el-breadcrumb-item>
                        <el-breadcrumb-item><span class="text-black-50">ポーカーの遊び方 - 基本ルール</span></el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
            <div class="cards">
                <div class="container">
                    <div class="row">
                        <div class="col-12">

                            <h6 class="with-title-dark">目次</h6>
                            <div class="table_of_contents mb-5">
                                <ul>
                                    <li @click="scrollTo1"><i class="fa"></i>ポーカーのやり方</li>
                                    <li @click="scrollTo2">ゲームの概要</li>
                                    <li @click="scrollTo3">ゲームに必要なもの</li>
                                    <li @click="scrollTo4">数字の強さ</li>
                                    <li @click="scrollTo5">ゲームの流れ</li>
                                    <li @click="scrollTo6">ゲームのシミュレーション</li>
                                    <li @click="scrollTo7">補足</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div v-html="html_content" class="col-12"></div>

<!--
                            <div id="rule_1" class="space-80"></div>
                            <h6 class="with-title-dark">ポーカーのやり方</h6>
                            <h6 class="">基本ルールと進行</h6>
                            <p class="card-description mb-4">代表的なポーカーゲーム「テキサスホールデム」日本でポーカーと言えばカードをチェンジしながら手役を作っていくドローポーカーや徐々に個別のプレーヤーにカードを配って出来上がる手役を競うセブンカードスタッドをイメージしがちですが今世界で流行っているポーカーはホールデムです。これは初心者にもわかり易く、とけ込み易く、まただれにでもチャンスのあるポーカーです。それでも確かにうまくなるというレベルアップ性のあるゲーム展開にはまる人が大変多いです。代表的なポーカーゲーム「テキサスホールデム」</p>

                            <h6 class="">ゲームの目的</h6>
                            <p class="card-description mb-4">ポーカーゲームの最大の目的は、ゲームに勝利しポットに集められたチップ全てを手に入れる事です。<br />勝利する方法は2つあります。<br />○ショーダウンまで残ったプレーヤーの中で最高位の手役を完成したプレーヤーが勝者となる。<br />○他のプレーヤー全てをフォールドに追い込み、自動的に勝利者となる。</p>
                            <p class="card-description mb-5">プレーヤーにとって理想的な勝利とは、高位の手役を完成しても他のプレーヤーに悟られずに多くのプレーヤーをショーダウンまで引き連れ、より多くのチップをポットに集め勝利する事です。また、手役が完成していなくても強気のアクションを繰り返し高位の手役の可能性を持ったプレーヤーをフォールドに追い込みショーダウンを待たずして勝利する事です。</p>

                            <div id="rule_2" class="space-80"></div>
                            <h6 class="with-title-dark">ゲームの概要</h6>

                            <h6 class="">ゲームの概要</h6>
                            <p class="card-description mb-4">ホールデムの最大の特徴はコミュニティカード（共通カード）の5枚を利用して手役を完成させる事です。</p>
                            <p class="card-description mb-4">プレーヤーはそれぞれ伏せて配られた2枚のカードとテーブル中央に表向きで並べられるコミュニティーカード（共通カード）5枚によってより高い手役を競います。ポーカーゲームの最大の目的は、ゲームに勝利しポットに集められたチップ全てを手に入れる事です。</p>
                            
                            <div class="col-12 col-md-8">
                                <img src="https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/images/primary_rule_001.png" class="mb-4" style="width:40%">
                            </div>
                            <p class="card-description mb-4">最初2枚は自分の手元へ来ます。他のプレイヤーに見られないように確認する。<br />最初のベティングラウンドは伏せて配られた2枚のカードによって行われます。</p>
                            <div class="col-12 col-md-8">
                                <img src="https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/images/primary_rule_002.png" class="mb-4" style="width:60%">
                            </div>
                            <p class="card-description mb-4">コミュニティカードは参加している全てのプレイヤーがこれらのカードを共通に使用し、自分の手元の2枚と組み合わせ手役を作ります。<br />コミュニティカードが並べられると2回目のベティングラウンドが始まります。<br />ベティングラウンドが終了すると、さらに1枚のコミュニティカードが表向きに並べられます。</p>
                            <div class="col-12 col-md-8">
                                <img src="https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/images/primary_rule_003.png" class="mb-4" style="width:80%">
                            </div>
                            <p class="card-description mb-4">コミュニティカードの4枚目です。<br />これをターンと言います。<br />3回目のベティングラウンドが始まります。<br />ベティングラウンドが終了すると、さらに1枚コミュニティカードが表向きに並べられ最大5枚まで並べられます。</p>
                            <div class="col-12 col-md-8">
                                <img src="https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/images/primary_rule_004.png" class="mb-4" style="100%">
                            </div>
                            <p class="card-description mb-4">コミュニティカードの5枚目です。<br />これをリバーと言います。<br />4回目のベティングラウンドが終了すればいよいよショーダウンです。<br />ショーダウンでは各プレーヤーに伏せて配られた2枚のカードとテーブル中央に並べられたコミュニティカード5枚を足した7枚で出来る最高位の手役によって勝負します。<br />7枚の中の5枚で完成させる手役は普通のポーカ－と同じで最高位がロイヤルストレートフラッシュ、最下位がハイカード（ノーハンド）です。</p>
                            <div class="col-12 col-md-8">
                                <img src="https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/images/primary_rule_005.png" class="mb-4">
                            </div>
                            <p class="card-description mb-5">ちなみに参考画像の最終的な手役は<br />8のワンペア<br />A、J、10のキッカー

                            <div id="rule_3" class="space-80"></div>
                            <h6 class="with-title-dark">ゲームに必要なもの</h6>

                            <div v-if="false" class="card-columns mb-5">
                                <card :color="'gray'">
                                    <img slot="image" class="img rounded" src="https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/images/primary_rule_007.png" />
                                    <h5 class="card-title font-weight-bold">テーブル</h5>
                                    <p class="card-description">ホールデムでは1組（52枚、ジョーカーを除く）のカードが使用されます。<br />参加人数／1テーブル2人から最大10名位までが参加できます。<br />それ以上ですとテーブルを増やせば何人でも参加可能です。</p>
                                </card>
                            </div>


                            <div class="grid-container2 mb-5">
                                <div class="grid">
                                    <card :color="'gray'">
                                        <img slot="image" class="img rounded" src="https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/images/primary_rule_007.png" />
                                        <h5 class="card-title">テーブル</h5>
                                        <p class="card-description description">通常は専用テーブルを使用します。</p>
                                    </card>
                                </div>
                                <div class="grid">
                                    <card :color="'gray'">
                                        <img slot="image" class="img rounded" src="https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/images/primary_rule_009.png" />
                                        <h5 class="card-title">ポーカーチップ</h5>
                                        <p class="card-description description">各自プレイヤーの持ちチップ。</p>
                                    </card>
                                </div>
                                <div class="grid">
                                    <card :color="'gray'">
                                        <h5 class="card-title">カード</h5>
                                        <p class="card-description description">ホールデムでは1組（52枚、ジョーカーを除く）のカードが使用されます。<br />参加人数／1テーブル2人から最大10名位までが参加できます。<br />それ以上ですとテーブルを増やせば何人でも参加可能です。</p>
                                        <div class="col-10 col-sm-10 col-md-10 col-lg-10">
                                            <img style="width:100%" class="img rounded" src="https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/images/primary_rule_006.png">
                                        </div>
                                    </card>
                                </div>
                                <div class="grid">
                                    <card :color="'gray'">
                                        <h5 class="card-title">ディーラーボタン</h5>
                                        <p class="card-description description">アクションをする順番を決定する白いプラスチックの丸い印。<br />プレーヤーの有利不利を無くすために時計回りに順次移動する。</p>
                                        <div class="col-4 col-xm-4 col-md-4 col-lg-4">
                                            <img style="width:100%" class="img rounded" src="https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/images/primary_rule_008.png">
                                        </div>
                                    </card>
                                </div>
                            </div>

                            <div id="rule_4" class="space-80"></div>
                            <h6 class="with-title-dark">数字の強さ</h6>
                            <div class="grid-container mb-3">
                                <div class="grid">
                                    <card :color="'gray'">
                                        <h5 v-if="false" class="card-title">ハイカード</h5>
                                        <p class="card-description description">A（エース）一番強い～2一番弱い</p>
                                        <div>
                                            <img style="width:40%" class="img rounded" src="https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/images/primary_rule_010.png">
                                        </div>
                                    </card>
                                </div>
                            </div>
                            <h6 class="mb-4">ポーカー手役の順位</h6>
                            <div class="grid-container mb-5">
                                <div class="grid">
                                    <card :color="'gray'">
                                        <h5 class="card-title">ハイカード</h5>
                                        <p class="card-description description">他に役の無い状態</p>
                                        <div>
                                            <img style="width:100%" class="img rounded" src="https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/images/primary_rule_011.png">
                                        </div>
                                    </card>
                                </div>
                                <div class="grid">
                                    <card :color="'gray'">
                                        <h5 class="card-title">ワンペア</h5>
                                        <p class="card-description description">1個ペア（同じ数字2枚）がある＋キッカー3枚</p>
                                        <div>
                                            <img style="width:40%" class="img rounded" src="https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/images/primary_rule_012.png">
                                        </div>
                                    </card>
                                </div>
                                <div class="grid">
                                    <card :color="'gray'">
                                        <h5 class="card-title">ツーペア</h5>
                                        <p class="card-description description">2個ペアがある＋キッカー1枚</p>
                                        <div>
                                            <img style="width:80%" class="img rounded" src="https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/images/primary_rule_013.png">
                                        </div>
                                    </card>
                                </div>
                                <div class="grid">
                                    <card :color="'gray'">
                                        <h5 class="card-title">スリーカード</h5>
                                        <p class="card-description description">同じ数字のカードが3つある＋キッカー2枚</p>
                                        <div>
                                            <img style="width:60%" class="img rounded" src="https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/images/primary_rule_014.png">
                                        </div>
                                    </card>
                                </div>
                                <div class="grid">
                                    <card :color="'gray'">
                                        <h5 class="card-title">ストレート</h5>
                                        <p class="card-description description">連番で5枚　スーツ（マーク）に制限無し</p>
                                        <div>
                                            <img style="width:100%" class="img rounded" src="https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/images/primary_rule_015.png">
                                        </div>
                                    </card>
                                </div>
                                <div class="grid">
                                    <card :color="'gray'">
                                        <h5 class="card-title">フラッシュ</h5>
                                        <p class="card-description description">同じスーツ（マーク）が5枚</p>
                                        <div>
                                            <img style="width:100%" class="img rounded" src="https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/images/primary_rule_016.png">
                                        </div>
                                    </card>
                                </div>
                                <div class="grid">
                                    <card :color="'gray'">
                                        <h5 class="card-title">フルハウス</h5>
                                        <p class="card-description description">同じ数字3枚と他にもう1つペア</p>
                                        <div>
                                            <img style="width:100%" class="img rounded" src="https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/images/primary_rule_017.png">
                                        </div>
                                    </card>
                                </div>
                                <div class="grid">
                                    <card :color="'gray'">
                                        <h5 class="card-title">フォーカード</h5>
                                        <p class="card-description description">同じ数字4枚＋キッカー1枚</p>
                                        <div>
                                            <img style="width:80%" class="img rounded" src="https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/images/primary_rule_018.png">
                                        </div>
                                    </card>
                                </div>
                                <div class="grid">
                                    <card :color="'gray'">
                                        <h5 class="card-title">ストレートフラッシュ</h5>
                                        <p class="card-description description">ストレートでかつフラッシュ</p>
                                        <div>
                                            <img style="width:100%" class="img rounded" src="https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/images/primary_rule_019.png">
                                        </div>
                                    </card>
                                </div>
                                <div class="grid">
                                    <card :color="'gray'">
                                        <h5 class="card-title">ロイヤルストレートフラッシュ</h5>
                                        <p class="card-description description">A、K、Q、J、10のストレートでかつフラッシュが最強のハンドになります</p>
                                        <div>
                                            <img style="width:100%" class="img rounded" src="https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/images/primary_rule_020.png">
                                        </div>
                                    </card>
                                </div>
                            </div>

                            <div id="rule_5" class="space-80"></div>
                            <h6 class="with-title-dark">ゲームの流れ</h6>
                            <img class="mb-4" src="https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/images/primary_rule_021.png">
                            <p class="card-description mb-4">（上図参照）ホールデムはアンティを必要としません。ブラインドベットをプレーヤーに強制します。カードはディーラーによりディーラーズボタンの左隣のプレーヤーから時計回りにカードを2枚伏せて配られます。<br />これにより1ラウンドが始まります。<br />全てのアクションはディーラーズボタン（プレーヤー3）の左隣のプレーヤー（プレーヤー4）から始め時計回りに順々に行っていきます。</p>
                            <h6 class="">ラウンド1</h6>
                            <p class="card-description mb-4">（ゲームのレートや進行によってブラインドの＄のチップ額は変わります。ここでは＄1-＄2としています。）<br />1ラウンドではディーラーズボタン（プレーヤー3）の左隣のプレーヤー（プレーヤー4）からゲーム開始時に配られた2枚のカードだけでベティングしていきます。<br />最初のブラインドベットをするプレーヤーをスモールブラインド（プレーヤー4）、次にブラインドベットをするプレーヤーをビックブラインド（プレーヤー5）と呼んでいます。<br />スモールブラインドは＄1、ビックブラインドは＄2を強制的にポットに積まれています。ブラインドベットに続き各プレーヤーは時計回りにコール、ベット、レイズ、リレイズ、フォールド（降りる）のどれかのアクションを選択し、各プレーヤーのチップ額の合った時点で1ラウンドは終了します。</p>
                            <h6 class="">ラウンド2</h6>
                            <p class="card-description mb-4">2ラウンドでは、フロップと呼ばれるコミュニティカード（共通カード）3枚がテーブル中央にオープンされます。<br />フロップがオープンされると2ラウンドの開始です。<br />2ラウンドはプレーヤー4から時計回りにベティングを開始します。もしプレーヤー4がフォールドしている場合は、その左隣のプレイヤー（プレイヤー5）からベティングを開始します。<br />各プレーヤーのチップ額の合った時点で2ラウンドのベティングは終了します。<br />この時のレートは＄1-＄2</p>
                            <h6 class="">ラウンド3</h6>
                            <p class="card-description mb-4">3ラウンドではターンと呼ばれる4枚目のコミュニティカードがオープンされます。<br />ターンがオープンされると3ラウンドの開始です。<br />3ラウンドも2ラウンドと同様に進行していきます。<br />3ラウンドが終了すると5枚目のコミュニティカードをオープンし4ラウンドが開始されます。（レイズ、リレイズ、チェック、コール、フォールド）<br />この時のレートは＄2-＄4（つまり3ラウンドからリスクが高くなるわけです）</p>
                            <h6 class="">ラウンド4</h6>
                            <p class="card-description mb-4">4ラウンドではリバーと呼ばれる5枚目のコミュニティカードがオープンされます。<br />リバーがオープンされると4ラウンドの開始です。<br />4ラウンドは3ラウンドと同様に行われ各プレーヤーのチップ額が合った時点でゲームは終了しショーダウンを待つだけです。<br />もし一人のプレーヤーを残して他のプレーヤー全員がフォールドしてしまった場合はショーダウンせずに残ったプレーヤーの勝利と成ります。（レイズ、リレイズ、チェック、コール、フォールド）<br />この時のレートは＄2-＄4</p>
                            <h6 class="">ショウダウン</h6>
                            <p class="card-description mb-5">ショウダウンでは4ラウンド終了までフォールドしないで生き残ったプレーヤーが手役を見せ合い、最高位の手役を完成させたプレーヤーがポット全てのチップを得る事が出来ます。<br />もし最高位の手役を完成させたプレーヤーが複数いた場合はその人数でポットを山分けします。<br />ショウダウンが終了するとディーラーズボタンを次の左隣のプレーヤーに移動して同様に『1ラウンド』からのプレーを繰り返します。</p>

                            <div id="rule_6" class="space-80"></div>
                            <h6 class="with-title-dark">ゲームのシミュレーション</h6>
                            <img class="mb-4" src="https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/images/primary_rule_022.png">
                            <p class="card-description mb-4">上図を参考に実際のゲームをシミュレーションしてみましょう！<br />（各アクションはあくまでも個人的なものです）</p>
                            <h6 class="">ラウンド1</h6>
                            <p class="card-description mb-4">プレーヤー4のスモールブラインド＄1とプレーヤー5のビックブラインド＄2をテーブル中央側に置いたら各プレーヤーにカードが2枚ずつ配られます。カードは伏せてテーブルの上で他のプレーヤーに見られないように確認します。<br />手札2枚のカードはチップ等でプロテクトしておくと良いでしょう。</p>
                            <p class="card-description mb-4">プレーヤー4と5は強制ベットの為プレーヤー1からアクション！<br />プレーヤー1はコール＄2を前に出す<br />プレーヤー2はレイズ＄2を上乗せした合計＄4を出す<br />プレーヤー3はコール＄4を出す<br />プレーヤー4はコール＄3を出す（＄1は既に払っているので差額）<br />プレーヤー5はフォールド（ゲーム前に出したビックブラインド＄2はポットに入るので戻りません）<br />プレーヤー1はチップ額を合せるためコール＄2を前に出す<br />1ラウンド終了</p>
                            <p class="card-description mb-4">アクティブプレーヤーは4名</p>

                            <h6 class="">ラウンド2</h6>
                            <p class="card-description mb-4">フロップはスペード4、ダイヤ9、ダイヤK</p>
                            <p class="card-description mb-4">プレーヤー4はチェック<br />プレイヤー5はフォールド（降りる）しているので<br />プレイヤー1はチェック<br />プレイヤー2はベット＄2出す<br />プレイヤー3はコール＄2出す<br />プレーヤー4はフォールド<br />プレイヤー1はコール＄2出す</p>
                            <p class="card-description mb-4">アクティブプレーヤーは3名</p>
                            <p class="card-description mb-4">2ラウンド終了時点の各プレーヤーのハンド<br />
                              <span class="mr-4">プレーヤー1</span><span>9のワンペア・A、K、4のキッカー</span><br />
                              <span class="mr-4">プレーヤー2</span><span>4のスリーカード・K、9のキッカー</span><br />
                              <span class="mr-4">プレーヤー3</span><span>Kのワンペア・9、4、2のキッカー</span>
                            </p>

                            <p class="card-description mb-4">2ラウンド終了時コミュニティカードのみで見た場合のナッツハンド（負け無しのカード）<br />
                              <span class="mr-4">ナッツ</span><span class="mr-4">K、K</span><span>Kのスリーカード</span><br />
                              <span class="mr-4">セカンドナッツ</span><span class="mr-4">9、9</span><span>9のスリーカード</span>
                            </p>

                            <h6 class="">ラウンド3</h6>
                            <p class="card-description mb-4">ターンはスペードの9</p>
                            <p class="card-description mb-4">プレーヤー4がフォールドなので<br />プレーヤー1がレイズ＄4<br />プレーヤー2リレイズ＄8<br />プレーヤー3がコール＄8<br />プレーヤー1がコール＄4</p>
                            <p class="card-description mb-4">アクティブブレーヤーは3名</p>
                            
                            <p class="card-description mb-4">3ラウンド終了時点の各プレーヤーのハンド<br />
                              <span class="mr-4">プレーヤー1</span><span>9のスリーカード・A、K、のキッカー</span><br />
                              <span class="mr-4">プレーヤー2</span><span>4のフルハウス</span><br />
                              <span class="mr-4">プレーヤー3</span><span>Kと9のツーペア・4のキッカー</span><br />フラッシュリーチ（出来ても負けますが…）
                            </p>
                            
                            <p class="card-description mb-4">3ラウンド終了時コミュニティカードのみで見た場合のナッツハンド（負け無しのカード）<br />
                              <span class="mr-4">ナッツ</span><span class="mr-4">9、9</span><span>9のフォーカード</span><br />
                              <span class="mr-4">セカンドナッツ</span><span class="mr-4">K、K</span><span>Kのフルハウス</span>
                            </p>

                            <h6 class="">ラウンド4</h6>
                            <p class="card-description mb-4">リバーはスペードのA</p>
                            <p class="card-description mb-4">プレーヤー1レイズ＄4<br />プレーヤー2コール＄4<br />プレーヤー3コール＄4</p>
                            <p class="card-description mb-4">アクティブプレーヤーは3名</p>

                            <p class="card-description mb-4">4ラウンド終了時点の各プレーヤーのハンド<br />
                              <span class="mr-4">プレーヤー</span><span>9のフルハウス</span><br />
                              <span class="mr-4">プレーヤー2</span><span>4のフルハウス</span><br />
                              <span class="mr-4">プレーヤー3</span><span>Aのハイフラッシュ</span>
                            </p>
                              
                            <p class="card-description mb-4">4ラウンド終了時コミュニティカードのみで見た場合のナッツハンド（負け無しのカード）<br />
                              <span class="mr-4">ナッツ</span><span class="mr-4">9、9</span><span>9のフォーカード</span><br />
                              <span class="mr-4">セカンドナッツ</span><span class="mr-4">A、A</span><span>Aのフルハウス</span>
                            </p>

                            <h6 class="">ショウダウン</h6>
                            <p class="card-description mb-4">残った3名でショウダウン<br />プレイヤー1は9、9、9、A、Aで9のフルハウス<br />プレーヤー2は4、4、4、9、9で4のフルハウス<br />プレーヤー3はA、K、9、4、2のAのハイフラッシュ<br />勝者はプレーヤー1と言う結果になりました。<br />1ゲーム中のチップ全てがプレーヤー1のものになります。</p>

                            <p class="card-description mb-5">このようにラウンドが進むごとにハンドが変化します。<br /> 各ベディング時のアクションで参加しているプレーヤーのハンドを予測しながらゲームを楽しみます。</p>

                            <div id="rule_7" class="space-80"></div>
                            <h6 class="with-title-dark">補足</h6>
                            <h6 class="">同じ役の勝敗について</h6>
                            <p class="card-description mb-4">役の部分について（ワンペアならペアの部分）カードの強い方が勝ち。<br />カードの強さの順序は、A-K-Q-J-10-9-8-7-6-5-4-3-2。<br />2ペアならとりあえず高い方のペアのついて比べ、それでもダメなら低い方のカードで比べる。<br />フルハウスなら、3枚カードのある方の数字でます比べる。<br />1で決まらない場合には、役以外の部分（俗に言うキッカー※）の強さで勝負する。<br />2でも決まらないときは引き分けです。<br />※キッカーについて：自分の使う札5枚のうち、役に関係してこないカードの事。</p>

                            <h6 class="">ストレートの注意</h6>
                            <p class="card-description mb-4">ストレートの数字のつながり方は『A-K-Q-J-10-9-8-7-6-5-4-3-2-A』となっている。<br />つまり、Q-K-A-2-3ではストレートにならない。</p>

                            <h6 class="">プレー中の注意事項</h6>
                            <p class="card-description mb-4">プレーは公平にするというのが基本です。<br />誰かの不利になったりフェアーではないことはつつしみましょう。<br />ポーカーをより楽しむ為には手札に関係する様な会話は望ましくありません。<br />もちろん戦略として会話しながらプレーするプレーヤーもいますがフォールドしたプレーヤーが自身の伏せカードが何であったかを教える事は違反です。<br />また、自分のチップを隠す、カードを隠すなどの行為は不正につながります。<br />自分の手札2枚はテーブルから外に出さないなどのマナーを守ってプレーを楽しみましょう。</p>

                            <div class="space-50"></div>
                            <div class="space-50"></div>
                            <p class="card-description mb-4"><a href="http://www.poker.or.jp/">非営利特定法人 日本ポーカー協会（http://www.poker.or.jp/）</a>「ゲームの進行とルール」より転載</p>
                           
-->
                        </div>
                    </div>
                    <div class="space-50"></div>
                    <!-- <div class="separator-line separator-primary"></div> -->
                </div>
            </div>

        </div>

    </div>
</template>

<script>
  import {
    Breadcrumb,
    BreadcrumbItem,
    Card
  } from '@/components';
  import axios from "axios";
  import {API_ENDPOINT} from '@/constants';

  export default {
    name: 'rule',
    bodyClass: 'presentation-page',
    components: {
      Card,
      [Breadcrumb.name]: Breadcrumb,
      [BreadcrumbItem.name]: BreadcrumbItem,
    },
    data() {
      return {
        html_content: ''
      }
    },
    async created() {
      try {
        document.title = 'ポーカーの遊び方 - 基本ルール | ポーカーナビ - 全国のポーカースポット、サークル、トーナメント情報を配信！'
        await axios
            .get(API_ENDPOINT + `appconstant/4`)
            .then(response => {
              this.html_content = response.data.text_value
            })
/*
      await axios
          .get('https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/contents/rules_20200615.txt')
          .then(response => {
            this.html_content = response.data
          })
*/
      } catch (e) {
        this.html_content = '<div class="spinner"></div><div class="space-50"></div>'
      } finally {
        //
      }
    },
    methods: {
      scrollTo1() {
        event.preventDefault()
        this.$SmoothScroll(
          document.querySelector('#rule_1'),
          400,
          null,
          null,
          'y'
        )
      },
      scrollTo2() {
        event.preventDefault()
        this.$SmoothScroll(
          document.querySelector('#rule_2'),
          400,
          null,
          null,
          'y'
        )
      },
      scrollTo3() {
        event.preventDefault()
        this.$SmoothScroll(
          document.querySelector('#rule_3'),
          400,
          null,
          null,
          'y'
        )
      },
      scrollTo4() {
        event.preventDefault()
        this.$SmoothScroll(
          document.querySelector('#rule_4'),
          400,
          null,
          null,
          'y'
        )
      },
      scrollTo5() {
        event.preventDefault()
        this.$SmoothScroll(
          document.querySelector('#rule_5'),
          400,
          null,
          null,
          'y'
        )
      },
      scrollTo6() {
        event.preventDefault()
        this.$SmoothScroll(
          document.querySelector('#rule_6'),
          400,
          null,
          null,
          'y'
        )
      },
      scrollTo7() {
        event.preventDefault()
        this.$SmoothScroll(
          document.querySelector('#rule_7'),
          400,
          null,
          null,
          'y'
        )
      },
    },
  }
</script>

<style scoped lang="scss">
    h5 {
      display: inline-block;
      margin: 0 auto;
      text-align: center;
      padding-bottom: 2px;
      border-bottom: 1px solid #333;
      font-weight: bold;
      font-style: italic;
    }
    .table_of_contents ul {
      list-style-type: none;
      padding-left: 0;

      li {
        cursor: pointer;
        padding-bottom: .3rem;
        padding-left: 1rem;
        margin-bottom: .8rem;
        border-bottom: 1px solid #ccc;
        font-size: .9rem;
      }
    }
</style>
