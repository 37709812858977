<template>
    <div>
        <div v-if="this.no_content" class="wrapper presentation-page no-content">
            <div class="mx-auto text-center">
                <div class="spinner"></div>
                <div class="space-50"></div>
            </div>
        </div>
        <div v-else class="wrapper presentation-page">

            <div class="section section-components" data-background-color="white">
                <div class="container mb-3 pt-3">
                    <el-breadcrumb separator-class="el-icon-arrow-right" class="">
                        <el-breadcrumb-item :to="{ path: '/' }"><span class="text-white-50s"><i class="fa fa-home"></i></span></el-breadcrumb-item>
                        <el-breadcrumb-item><span class="">TOP</span></el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>


            <div id="poker-spot" data-background-color="white" class="">
                <div class="space-70"></div>
                <div class="container">
                    <h6 class="with-title-dark">サイトマップ</h6>
                    <div class="row">
                        <div class="col-md-12 mb-0">

                            <div class="mb-3">
                                <h6 class="mb-3 text-black" slot="title">
                                    <router-link :to="{ name: 'events', params: {}}" tag="a" class="front" >
                                        <a class=""><span class="mr-2">&#8226;</span>ポーカー大会一覧</a>
                                    </router-link>
                                </h6>
                                <h6 class="mb-3 text-black" slot="title">
                                    <router-link :to="{ name: 'circles', params: {}}" tag="a" class="front" >
                                        <a class=""><span class="mr-2">&#8226;</span>ポーカーサークル一覧</a>
                                    </router-link>
                                </h6>
                                <h6 class="mb-3 text-black" slot="title"><span class="mr-2">&#8226;</span>ポーカースポット一覧</h6>
                                <div class="ml-5" v-for="area in areas ">
                                    <n-button v-for="prefecture in area.prefectures" :key="prefecture.id" type="primary" size="sm" simple>
                                        <router-link :to="{ name: 'spots', query: { prefecture: prefecture.id }}">
                                            <span class="text-black">{{prefecture.name.replace(/県|府/, '').replace(/東京都/, '東京')}}</span>
                                        </router-link>
                                    </n-button>
                                </div>
                                <n-button type="primary" size="sm" simple class="ml-5 mb-5">
                                    <router-link :to="{ name: 'spots', query: { tag: 15 } }">
                                        <span class="text-black">オンライン</span>
                                    </router-link>
                                </n-button>
                                <h6 class="mb-3 text-black" slot="title">
                                    <router-link :to="{ name: 'videos', params: {}}" tag="a" class="front" >
                                        <a class=""><span class="mr-2">&#8226;</span>動画一覧</a>
                                    </router-link>
                                </h6>
                                <h6 class="mb-3 text-black" slot="title">
                                    <router-link :to="{ name: 'rule', params: {}}" tag="a" class="front" >
                                        <a class=""><span class="mr-2">&#8226;</span>基本ルール</a>
                                    </router-link>
                                </h6>
                                <h6 class="mb-3 text-black" slot="title">
                                    <router-link :to="{ name: 'tda', params: {}}" tag="a" class="front" >
                                        <a class=""><span class="mr-2">&#8226;</span>TDA2019</a>
                                    </router-link>
                                </h6>
                                <div v-if="false" class="separator-line separator-primary mb-3"></div>

                                <h6 class="mb-3 text-black" slot="title">
                                    <router-link :to="{ name: 'privacy', params: {}}" tag="a" class="front" >
                                        <a class=""><span class="mr-2">&#8226;</span>プライバシーポリシー</a>
                                    </router-link>
                                </h6>
                                <h6 class="mb-3 text-black" slot="title">
                                    <router-link :to="{ name: 'termsofservice2', params: {}}" tag="a" class="front" >
                                        <span class="mr-2">&#8226;</span>利用規約
                                    </router-link>
                                </h6>

                            </div>
                            <div class="space-100"></div>

                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
  import {
    Alert,
    Breadcrumb,
    BreadcrumbItem,
    NukButton,
    Card,
    Badge,
    Collapse,
    CollapseItem,
  } from '@/components';
  import {
    Button,
    ButtonGroup,
    Carousel,
    CarouselItem,
    Loading,
    Table,
    TableColumn,
  } from 'element-ui';
  import axios from "axios";
  import dayjs from 'dayjs'
  import {API_ENDPOINT} from '@/constants'

  export default {
    name: 'sitemap',
    bodyClass: 'presentation-page',
    components: {
      Alert,
      Badge,
      Card,
      Collapse,
      CollapseItem,
      [NukButton.name]: NukButton,
      [Button.name]: Button,
      [ButtonGroup.name]: ButtonGroup,
      [Breadcrumb.name]: Breadcrumb,
      [BreadcrumbItem.name]: BreadcrumbItem,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
    },
    created: function() {
      try {
        this.loadingInstance = Loading.service({ fullscreen: true });

        document.title = 'サイトマップ | ポーカーナビ - 全国のポーカースポット、サークル、トーナメント情報を配信！'
        // prefectures ----------
        axios
          .get(API_ENDPOINT + `area`)
          .then(response => {
            this.areas = response.data
            this.no_content = false
          })

        /*
        // spots ----------------
        axios
          .get(API_ENDPOINT + `spots/?page=1&random`)
          .then(response => (this.spots = response.data.spots))

        // events ---------------
        axios
          .get(API_ENDPOINT + `events`)
          .then(response => {
            this.events = response.data.events
          })
        axios
          .get(API_ENDPOINT + `events/?pickup`)
          .then(response => {
            this.pickup_events = response.data.events
            this.pickup_events_count = response.data.meta.count
          })


        // circles ------------
        axios
          .get(API_ENDPOINT + `organizations/?attribute=2&tag=1&random`)
          .then(response => {
            this.circles = response.data.organizations
          })

        // videos ------------
        axios
          .get(API_ENDPOINT + `videos/`)
          .then(response => {
            this.videos = response.data
          })
        */

        // tag_spots ------------
        axios
          .get(API_ENDPOINT + `tags/?attribute=1`)
          .then(response => {
            this.tag_spots = response.data
          })


      } catch (e) {
        console.error(e)
      } finally {
        this.loadingInstance.close();
      }
    },
    computed: {
      tag_spots_filtered: function() {
        return this.tag_spots.filter(function(i) {
          return i.view_in_list;
        })
      },
      tag_circles_filtered: function() {
        return this.tag_circles.filter(function(i) {
          return i.view_in_list;
        })
      }
    },
    updated() {
      this.$nextTick(()=>{
      });
    },
    watch: {
      areas: function() {
        this.$nextTick(() => {
            var positionY = sessionStorage.getItem('positionY')
            scrollTo(0, positionY);
            setTimeout(function(){
                scrollTo(0, positionY);
                }, 500);
            })
            this.no_content = (this.areas == null)
      }
    },
    data() {
      return {
        no_content: true,
        spots: [],
        areas: [],
        prefectures: [],
        tag_spots: [],
        tag_circles: [],
        events: [],
        pickup_events: [],
        pickup_events_count: '',
        circles: [],
        videos: [],
        loadingInstance: null,
        a8script: '<script type=\"text/javascript\" src=\"//rot0.a8.net/jsa/94c1f99c9867b2f177529215a0fde259/c6f057b86584942e415435ffb1fa93d4.js\"><\/script>',
        vhtml: '<span style=\"color:red\">aaaaaaaaaaaaaaaaaaaaaaaaaa<\/span>',
        vcontent: '',
      }
    },
    filters: {
      dayjs: function (date) {
        return dayjs(date).format('YYYY年M月D日 (ddd)');
      },
      ymd: function (date) {
        return dayjs(date).format('YYYY-M-D (ddd)');
      },
      yyyy: function (date) {
        return dayjs(date).format('YYYY年');
      },
      mmddd: function (date) {
        return dayjs(date).format('M月D日 (ddd)');
      },
    },
  }
</script>

<style scoped lang="scss">
    .github-buttons {
        display: flex;
        align-content: center;
    }
    .vcontent >>> iframe {
      width: 100%;
      border: none;
      overflow: hidden;
    }
</style>
