<template>
    <div class="wrapper presentation-page">

        <div id="poker-spot" data-background-color="white">
            <div class="space-100"></div>
            <div class="container">
                <div class="mb-4">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }"><span class="text-black-50"><i class="fa fa-home"></i></span></el-breadcrumb-item>
                        <el-breadcrumb-item>
                            <span class="text-black-50"><span class="mr-0">{{ tags[$route.params['id']-1].title }}</span>のポーカースポット一覧</span>
                        </el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>

            <h5 class="with-title-bg">
                <div class="container">
                    <i class="fa fa-home mr-2"></i><span class="mr-0">{{ tags[$route.params['id']-1].title }}</span>のポーカースポット一覧
                </div>
            </h5>

            <div class="cards">
                <div class="container">
                    <div v-if="tags.length > 0" class="row">
                        <div v-for="tag in tags" :key="spot.id" class="float-left col-md-6 offset-md-0 col-lg-4 offset-lg-0">
                            <router-link :to="{ name: 'spot', params: {id: spot.id}}" tag="a" class="front" >
                            <a>
                                <card v-bind:color="setOrange(spot.id)"
                                      v-bind:type="setTypeBlog(spot.id)"
                                      v-bind:cardBodyClasses="addNew(spot.id)" >
                                    <img slot="image" class="img rounded" v-bind:src="spot.image">
                                    <h6 class="category text-primary">
                                        <i class="far fa-map-marker-alt mr-2"></i>{{spot.address.prefecture}} {{spot.address.city}}
                                    </h6>
                                    <h5 class="card-title">{{spot.name}}</h5>
                                    <p class="card-description">{{spot.info}}</p>
                                </card>
                            </a>
                            </router-link>
                        </div>
                    </div>
                    <div v-else class="row">
                        <div class=" container justify-content-center text-center">
                            <p>登録済みのデータはありません</p>
                        </div>
                    </div>

                    <div class="space-50"></div>
                    <!-- <div class="separator-line separator-primary"></div> -->
                </div>
            </div>

        </div>

    </div>
</template>

<script>
  import {
    Breadcrumb,
    BreadcrumbItem,
    Card,
    Collapse,
    CollapseItem,
    Pagination,
  } from '@/components';
  import {
    Button,
    ButtonGroup,
  } from 'element-ui';
  import axios from "axios";

  export default {
    name: 'tags',
    bodyClass: 'presentation-page',
    components: {
      Card,
      Collapse,
      CollapseItem,
      [Button.name]: Button,
      [ButtonGroup.name]: ButtonGroup,
      [Breadcrumb.name]: Breadcrumb,
      [BreadcrumbItem.name]: BreadcrumbItem,
      [Pagination.name]: Pagination,
    },
    methods: {
      addNew: function(index) {
        return 'add-new';
      },
      setOrange: function(image) {
        if (!image) {
          return 'orange';
        }
      },
      setTypeBlog: function(index) {
        if (index%3 !== 0) {
          return 'blog';
        }
      },
    },
    async created() {
      try {
        await xios
          .get(`https://ec.breezebox.co.jp/api/v1/organizations/?tag=1`)
          .then(response => {
            this.tags = response.data.organizations
          })
      } catch(e) {
        console.error(e)
      }
    },
    data() {
      return {
        tags: [],
        pagination: {
            simple: 1,
            default: 2,
            full: 3
        }
      }
    }
  }
</script>
