<template>
    <div class="wrapper presentation-page">

        <vue-backTop v-if="true" :height="600" :bottom="50" :right="30" @onClick="" @complete="">
            <div class="backtop">
                <i class="fa fa-spade display-5 text-info"></i>
            </div>
        </vue-backTop>

        <div id="poker-spot" data-background-color="white">
            <div class="space-100"></div>
            <div class="container">
                <div class="mb-5">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }"><span class="text-black-50"><i class="fa fa-home"></i></span></el-breadcrumb-item>
                        <el-breadcrumb-item><span class="text-black-50">ポーカーの遊び方 - POKER TOURNAMENT DIRECTORS ASSN. 2019年版 ルール</span></el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
            <div class="cards tda" v-html="html_content">

<!--
                <div class="container">
                    <h6 class="with-title-dark">POKER TOURNAMENT DIRECTORS ASSN. <span class="text-danger">2019年版</span> ルール</h6>
                    <div class="row">
                        <div class="col-md-4 mb-4">
                            <img class="mx-auto text-center" src="https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/images/poker_tda.png" alt="poker_tda" />
                        </div>
                        <div class="col-md-8 pt-3 text-center" style="line-height:1.5rem;font-size:1.1rem">
                            <div class="font-weight-bold" style="font-size: 1.4rem">POKER TOURNAMENT DIRECTORS ASSN</div>
                            <div class="font-weight-bold">
                                <span class="text-danger">2019年度版</span>ルール, Version <span class="text-danger">1.0, 2019年9月17日</span>
                            </div>
                            <div class="font-weight-bold" style="">
                                <span class="">ロングフォーム版：</span>
                            </div>
                            <div class="font-weight-bold" style="">
                                <span class="font-weight-normal">推奨手順と追補例解を含む</span>
                            </div>
                            <div class="text-danger" style="">赤字の斜体は2017年からの変更</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">

                            <p class="card-description mb-3">
<a href="https://www.pokertda.com/">Poker TDA</a>は、2001年に設立されたポーカー業界の任意団体である。ポーカートーナメントルールの世界的な統一性を高めることが使命である。Poker TDAルールは、ハウスルールを補足する。ハウスルールとの競合があったときは、ハウスルールを適用する。
                            </p>
                            <div class="text-center">
                            <h5 class="text-center mb-5">一般概念</h5>
                            </div>

                            <h6 class="">1: フロアの決定</h6>
                            <p class="card-description mb-4">意思決定における最優先事項は、ゲームにおける徹底した利益と公平性である。通常とは異なる状況では、公平性を考慮した常識的な決定が、技術的なルールよりも優先されることがある。フロアの決定は最終となる。</p>

                            <h6 class="">2: プレイヤーの責務</h6>
                            <p class="card-description mb-4">プレイヤーは、レジストレーションデータおよびシートの割り当てを確認し、ハンドをプロテクトし、自身の意図を明確にし、アクションを追い、順序にそって適切な用語とジェスチャーを使ってアクションし、アクションを起こす権利を守り、カードを見えるようにし、チップを正しく積み、ライブハンドを持っているときは着席状態を保ち、ショーダウンのときはすべてのカードを適切に開示し、ミスがあるときは口頭で指摘し、正当なときにはクロックを要求し、速やかにテーブル移動し、他人と相談せず（ワンプレイヤーワンハンド）、ルールを習得した上でそれを順守し、適切なエチケットを守り、トーナメントの全般的秩序維持に貢献する責務を負う。</p>

                            <h6 class="">3: 公式用語とジェスチャー</h6>
                            <p class="card-description mb-4">ベットの公式用語は、シンプルかつ明白で由緒ある宣言である、ベット、レイズ、コール、フォールド、チェック、オールイン、コンプリート、ポット（ポットリミットのみ）である。地域の用語も、この基準を満たすことがある。また、プレイヤーがアクションに直面したときは、ジェスチャーの使用に注意する必要がある: テーブルへのタップはチェックとみなされる。それらの意志を明確にすることはプレイヤーの責任である。標準的ではない用語やジェスチャーの使用は、プレイヤーのリスクであり、プレイヤーの意図しない判断につながる可能性がある。第2項と第42項も参照。</p>

                            <h6 class="">4: プレイヤーの本人確認</h6>
                            <p class="card-description mb-4"><span class="text-danger">プレイヤーの衣服またはそのほかの携行品は、そのプレイヤーの本人確認を不明瞭なものにしたり、ゲームの妨げになるものであってはならない。ハウスの定める基準がTDの判断にのみ従って適用される。</span></p>

                            <h6 class="">5: 電子機器および通信</h6>
                            <p class="card-description mb-4">テーブルでの通話は禁じられる。呼び出し音や音楽、<span class="text-danger">画像、動画、</span>そのほかは、ほかの人に聞こえないよう、かつ邪魔にならないようにするべきである。ベッティングアプリやチャートなどは、ライブハンドを持つプレイヤーは<span class="text-danger">使用してはならない。</span>そのほかの機器や道具、写真撮影やビデオ撮影、通信は、迷惑とならず、<span class="text-danger">ゲームを遅らせず、競争上の優位性にならない</span>ようにし、ハウス規則およびゲーミング規制法に従うものとする。</p>

                            <h6 class="">6: 公式言語</h6>
                            <p class="card-description mb-4">ハウスは、テーブルで認める言語を明確に掲示しアナウンスする。</p>


                            <div class="text-center">
                            <h5 class="mb-5 text-center">シーティング、テーブルブレイクとバランシング</h5>
                            </div>

                            <h6 class="">7: ランダムで正確な席</h6>
                            <p class="card-description mb-4">トーナメントとサテライトの席は、ランダムに割り当てる。プレイヤーが正しいチップを持ち、誤った席で開始してしまったときは、今の合計チップを持って正しい席に移動する。</p>

                            <h6 class="">8: 空席待ち、遅れた参加申し込みとリエントリ</h6>
                            <ul>
                            <li>オルタネイト（空席待ち）、プレイヤーの遅れた参加申し込みとリエントリはフルスタックが与えられる。彼らには、同じシートプールから同じ手順でテーブルと席をランダムに引き、そのあと新しいプレイヤーとしてスモールブラインド（SB）とボタンのあいだをのぞくところに配置する。</li>
                            <li>リエントリイベントで、プレイヤーがチップを放棄し新しいチップを購入することが許されているとき、放棄したチップはプレイから取りのぞく。</li>
                            </ul>

                            <h6 class="">9: 特別な支援</h6>
                            <p class="card-description mb-4">特別な支援が必要なプレイヤーには、可能な限り便宜が図られる。</p>

                            <h6 class="">10: テーブルブレイク</h6>
                            <p class="card-description mb-4">ブレイクするテーブルのプレイヤーには、2段階のランダムな手順により新しいテーブルと席が割り当てられる。彼らはSBとボタンのあいだをのぞいた、SBまたはビッグブラインド（BB）、ボタンを含む、どの席にも着くことができる。追補例解を参照。</p>

                            <h6 class="">11: バランシングテーブルとプレイの中断</h6>
                            <ul>
                            <li>フロップゲームとミックスゲームのバランシングは、BBになるプレイヤーを、シングルBBを含む最も不利なポジションに移動する。たとえ、席が2回連続でBBになったとしてもそのようにする。最も不利なポジションがSBになることはない。スタッドでは、ポジションに従って移動する（ショートテーブルで、最後に空いた席から埋める）。</li>
                            <li>（HORSEなどの）ミックスゲームでは、ホールデムからスタッドにゲームが変わるときは、ホールデムのラストハンドのあとに、次のハンドがホールデムであるときのボタン位置に移動させ、スタッド中はそこに留め置く。スタッドで移動するプレイヤーは、ホールデムのときのBBとする。ホールデムを再開するときは、ボタンは留め置いたところから始まる。</li>
                            <li>どのテーブルからプレイヤーを移動させるかは、あらかじめ決められた手順により決める。</li>
                            <li>フルテーブルのプレイで、最もプレイヤーのいるテーブルより3人以上少なくなったテーブルではプレイを中断する。ほかの形式（6ハンデッドやターボ）では、TDの裁量でプレイを中断する。TDはプレイの中断を免除することがあり、免除はミスディールではない。イベントの進行上、TDの裁量でより緻密にバランシングされることがある。</li>
                            </ul>
                            <p class="card-description mb-4"><span class="text-danger">[ファイナルテーブルのプレイヤー数に関するルールは、推奨手順 RP-9に移動]</span></p>


                            <div class="text-center">
                            <h5 class="mb-5 text-center">ポット／ショーダウン</h5>
                            </div>

                            <h6 class="">12: 宣言。ショーダウン時のカードスピーク</h6>
                            <p class="card-description mb-4">勝敗は（ハンドの内容に関する口頭での宣言に関わらず）カードスピークにより決定する。ハンドの価値を口頭で宣言する義務はないが、意図的にハンドを誤って宣言したときは、ペナルティーが課せられることがある。ディーラーは、ショーダウンでハンドの強さを読み、アナウンスすべきである。すべてのプレイヤーは、ハンドの有無に関わらず、役判定やポット計算、ポットの授与のミスを発言すべきである。</p>

                            <h6 class="">13: カードの開示、勝利したハンドの無効化（キル）</h6>
                            <ul>
                            <li>適切な2枚の開示は、1) テーブルの上ですべてのカードを表向きにし、2) ディーラーとプレイヤーがはっきりと読むことができるようにすることである。「すべてのカード」とは、ホールデムは2枚のホールカード、オマハは4枚のホールカード、7スタッドは7枚すべてのカードを指す。</li>
                            <li>ショーダウンにおいてプレイヤーは、読まれるまでハンドをプロテクトしなければならない（第65項も参照）。すべてのカードを完全に表にせず、自身が勝ったと思ってマックをするとき、その行動は本人のリスクとなる。ハンドが100%取り戻すことや識別ができず、TDが明確には読みとれないと判定したときは、そのプレイヤーはポットを得る主張ができない。ハンドが十分に開示されたか否かに関するTDの判定は、最終である。</li>
                            <li>適切に開示され、明らかに勝者であるカードを、ディーラはキルできない。</li>
                            </ul>

                            <h6 class="">14: ショーダウン時のライブカード</h6>
                            <p class="card-description mb-4">開示されなかったカードを捨てても自動的にキルされない。カードが100%識別し取り戻すことができるときは、プレイヤーは考えを変えることができる。カードがマックの山に入れられたり、取り戻せなくなったり、特定ができなくなったときは、ディーラーによってそのカードはキルされる。</p>

                            <h6 class="">15: ショーダウンとカードの破棄の反則</h6>
                            <ul>
                            <li>プレイヤーが、勝利のために必要な1枚のカードだけを開示したとき、ディーラーはそのプレイヤーに対し、すべてのカードを開示するよう促す。そのプレイヤーがそれを拒んだときは、フロアが呼ばれる。</li>
                            <li>プレイヤーがベットし（ほかのプレイヤーが残っているのを忘れ）、勝ったと思いカードを捨てたとき、ディーラーはそのカードを持ち、フロアを呼ぶ（第58項の例外）。カードがマックされて、100%の確実性を持って、それを取り戻したり、特定することができないとき、そのプレイヤーには、コールされたベット分が補償される権利はない。そのプレイヤーがベットやレイズを打ち出して、誰にもコールされていないときは、コールされなかったベット分は返される。</li>
                            </ul>

                            <h6 class="">16: オールイン時のホールカードのフェイスアップ</h6>
                            <p class="card-description mb-4">誰かがオールインし、ハンドに参加中のすべてのプレイヤーのすべてのベッティングアクションが完了すると、すべてのハンドは遅らせることなく開示される。オールインをしたプレイヤーも、コールをしたプレイヤーも、ハンドをマックすることはできない。メインポット、サイドポットに参加中のすべてのハンドは、開示されなくてはならず、すべて有効である。追補例解を参照。</p>

                            <h6 class="">17: オールインのないショーダウンとショーダウンの順序</h6>
                            <ul>
                            <li>オールイン以外のショーダウンは、カードが自発的に開示されないときや捨てられたときには、TDがその順序を指定することがある。最後のベッティングラウンド（最後のストリート）において、最後にアグレッシブなアクションを起こしたプレイヤーが、最初に開示する。最後のストリートでベットがなかったとき、最初にアクションを起こすプレイヤーが、最初にハンドを開示しなければならない（例: フロップゲームにおけるボタンの左のプレイヤー、スタッドにおけるハイハンドのプレイヤー、ラズにおけるローハンドのプレイヤーなど）。</li>
                            <li>オールインのないショーダウンで、1人以外がカードを開示せずにマックしたとき、そのプレイヤーが勝利する。ライブカードを持つ最後のプレイヤーが勝ち、そのプレイヤーはハンドを開示しなくて良い。</li>
                            </ul>

                            <h6 class="">18: ハンドの開示要求</h6>
                            <ul>
                            <li>ショーダウン時にカードを持っていないプレイヤーおよびハンドを開示することなく伏せたままマックしたプレイヤーは、ハンドの開示を求めるあらゆる権利または特権を失う。</li>
                            <li>リバーでベットがあったとき、どのコーラーも、カードを保持しているか開示しているときに、要求を行うことで、最後にベットやレイズを行ったプレイヤーのハンドを<span class="text-danger">開示させる</span>不可譲の権利（「ハンドを見るために支払った」）を有する。コールした別のコーラーのハンドの開示要求や、リバーベットがなかったときなど、そのほかのときはTDの裁量に委ねられる。追補例解を参照（2013年採用）。</li>
                            </ul>

                            <h6 class="">19: ショーダウン時のボードプレイ</h6>
                            <p class="card-description mb-4">ボードプレイをするとき、プレイヤーは、ハンドをすべて開示しないとポットの一部を獲得することができない（第13項-Aを参照）。</p>

                            <h6 class="">20: 余ったチップの付与</h6>
                            <p class="card-description mb-4">まず端数チップは、そのとき存在する最も小さいデノミで分けておく。A) ボードゲームで2つ以上のハイハンドやローハンドがあるとき、余ったチップは、ボタンの左側から見て最初のプレイヤーに与える。B) スタッド、ラズ、スタッド／8で2つ以上のハイハンドやローハンドがあるとき、余ったチップは、スートの強弱を含む最も強い5枚のハイカードプレイヤーに与える。C) ハイロースプリットでは、ポット全体の端数は、ハイに与える。D) ハイもローも全く同じとき（例: オマハ8におけるウィール）は、ポットを可能な限り公平に分ける。追補例解を参照。</p>

                            <h6 class="">21: サイドポット</h6>
                            <p class="card-description mb-4">サイドポットはそれぞれ別々に分ける。</p>

                            <h6 class="">22: 争いのあるハンドとポット</h6>
                            <p class="card-description mb-4">開示されたハンドの判定は、次のハンドが始まるまで異議をとなえることができる（第23項を参照）。ポットの計算や授与でのミスは、次のハンドで実質的なアクション(SA)が起こるまで異議を唱えることができる。ハンドが休憩時間に入ってから終了したときは、異議を申し立てる権利はポットの授与から1分で消滅する。</p>

                            <div class="text-center">
                            <h5 class="mb-5 text-center">一般手続</h5>
                            </div>

                            <h6 class="">23: 新しいハンドおよびレベル</h6>
                            <p class="card-description mb-4">新しいレベルは、クロックがゼロになったあとにそれが<span class="text-danger">アナウンスされたときから開始される。</span>その次のハンドから新しいレベルが適用される。<span class="text-danger">ハンドは、</span>最初のリフル、オートシャッフラーのボタン押下、ディーラー交代のタイミングで<span class="text-danger">始まる。</span><span class="text-danger">もし前のレベルでのハンドが誤って開始されたときは、SAが起きていれば前のレベルでハンドは継続される（第36項）。</span></p>

                            <h6 class="">24: チップレース、予定されていたカラーアップ</h6>
                            <ul>
                            <li>予定されたカラーアップをするとき、チップレースはシート1からはじめ、最大1枚のチップがプレイヤーに与えられる形で行う。チップレースでトーナメントから敗退することはない。最後のチップをレースで失ったプレイヤーには、プレイに使用されている最も小さいデノミのチップが1枚与えられる。</li>
                            <li>プレイヤーはすべてのチップが完全に見えるように置かねばならず、また、チップレースに立ち会うことが推奨される。</li>
                            <li>チップレースのあと、除外されたデノミのチップを持っていたとき、同じ価値のいまのデノミのチップに両替される。除外されたデノミのチップが、そのときの最小のデノミのチップに満たないとき、それは交換されず、そのチップを失う。 </li>
                            </ul>

                            <h6 class="">25: カードとチップは、見やすく数えやすく管理しやすくすること。裁量によるカラーアップ</h6>
                            <ul>
                            <li>プレイヤー、<span class="text-danger">ディーラー、およびフロア</span>は、<span class="text-danger">チップ</span>量を妥当に見積もることが認められている。そのため、チップは数えやすいスタックで保持されるべきである。TDAは、<span class="text-danger">同じデノミ</span>のチップを<span class="text-danger">垂直に</span>積んだ20枚ずつのスタックを標準として推奨する。プレイヤーは、高いデノミのチップを常時視認および判別ができるように置かなければならない。<span class="text-danger">もし、フロアがチップスタックを見てその価値を素早く見積もることが出来ないとき、プレイヤーからもそれは不可能である可能性が高い。</span></li>
                            <li>TDはプレイ中のチップ数とデノミを管理し、<span class="text-danger">1人または複数プレイヤー</span>のカラーアップをその裁量で<span class="text-danger">いつでも</span>行うことができる。TDの裁量で行われるカラーアップは、アナウンスされなくてはならない。</li>
                            <li>プレイヤーは、ライブハンドを常に視認可能な状態で保持しなければならない。</li>
                            </ul>

                            <h6 class="">26: デックチェンジ</h6>
                            <p class="card-description mb-4">デックチェンジは、ディーラーの交代またはレベルアップのとき、もしくはハウスの指定通りに行う。プレイヤーはデックチェンジを要求できない。</p>

                            <h6 class="">27: リバイ</h6>
                            <p class="card-description mb-4">プレイヤーは、ハンドをプレイせずにやり過ごしてはいけない。新しいハンドの前にリバイの意思が表明されたとき、そのプレイヤーは、チップが後ろにあるとしてプレイし、リバイをしなければならない。</p>

                            <h6 class="">28: ラビットハンティング</h6>
                            <p class="card-description mb-4">ラビットハンティング（ハンドが終わっていなければ来ていたカードを明らかにすること）は、認められない。</p>

                            <h6 class="">29: クロック要求</h6>
                            <p class="card-description mb-4"><span class="text-danger">プレイヤーは、妥当なゲームのペースを維持するためにも、タイムリーにアクションを起こすべきである。</span>TDの判断による妥当な時間が経過したとき、TDはクロックを宣言したり、そのイベントに参加しているいずれかのプレイヤーによるクロック要求を承認できる。<span class="text-danger">プレイヤーがクロックを要求するためには、自分のシートに着席していなくてはならない（第30項）。</span>クロック要求をされたプレイヤーは、25秒とそれに続く5秒のカウントダウンのうちにアクションを起こさなければいけない。そのプレイヤーが、ベットに直面した状況で時間が経過したとき、デッドハンドとなる。ベットに直面していない状況であるとき、ハンドはチェックしたものとみなす。ちょうどのタイミングのとき、プレイヤーのアクションが認められる。TDは、ゲームのフォーマットに合わせるためや常習的な遅延行為を阻止するために、アクションまでの時間を調整したり、別の手続きをとることも認められる。第2項と第70項も参照。</p>


                            <div class="text-center">
                            <h5 class="mb-5 text-center">プレイヤーの存在／ハンドの資格</h5>
                            </div>

                            <h6 class="">30: 席にいることとライブハンド</h6>
                            <p class="card-description mb-4">プレイヤーがライブハンドを持つためには、最初のディールですべてのプレイヤーに最後のカードが配られたときに、席にいる必要がある。そのとき席についていないプレイヤーはハンドを見ることはできず、キルされる。そのプレイヤーのブラインドとアンティはポットに入り、スタッドタイプのゲームではブリングインのカードが配られたとき、ブリングインをポストしなければならない。プレイヤーがクロックを要求するときは、着席している必要がある。「席に着いている」とは、椅子に手が届く範囲にいることを意味する。本ルールはプレイヤーに対して、ハンドの参加中に席を離れることを推奨するものではない。</p>

                            <h6 class="">31: アクション継続中にテーブルにいること</h6>
                            <p class="card-description mb-4">ライブハンドを持つプレイヤー（オールインしているプレイヤーや、ベットの機会がないプレイヤーを含む）は、すべてのベットラウンドとショーダウンが終了するまでのあいだ、テーブルにいなければならない。テーブルを離れることは、ハンドをプロテクトし、アクションを追うことと相容れない行為であり、ペナルティの対象となる。</p>


                            <div class="text-center">
                            <h5 class="mb-5 text-center">ボタン／ブラインド</h5>
                            </div>

                            <h6 class="">32: デッドボタン</h6>
                            <p class="card-description mb-4">トーナメントプレイにおいては、デッドボタンを使用する。</p>

                            <h6 class="">33: ブラインドの回避</h6>
                            <p class="card-description mb-4">意図的にブラインドをかわすプレイヤーは、ペナルティを負う。<span class="text-danger">（排除: テーブルブレイクから移動するとき）</span></p>

                            <h6 class="">34: ヘッズアップ時のボタン</h6>
                            <p class="card-description mb-4">ヘッズアップでは、SBをボタンとし、プリフロップでは最後のカードが配られ、最初にアクションを起こし、以降のベットラウンドでは最後にアクションを起こす。ヘッズアップを始めるとき、どちらのプレイヤーもBBが2回連続とならないようにボタンの位置を調整する。</p>

                            <div class="text-center">
                            <h5 class="mb-5 text-center">ディールルール</h5>
                            </div>

                            <h6 class="">35: ミスディール<span class="text-danger">および不正なデック</span></h6>
                            <ul>
                            <li>ミスディールは以下のときに該当するが、これらに限定されない。1) 最初のディールで、2枚以上のカードがボックス（表裏が逆にデックに混入）されていたとき。2) 最初のカードが誤ったシートに配られたとき。3) ハンドに参加できないシートにカードが配られたとき。4) ハンドに参加できるシートにカードが配られなかったとき。5) フロップゲームで、最初に配られる2枚のカードのどちらかまたは合計2枚のカードが、ディーラーのミスによりエクスポーズしたとき。ドローゲーム（例: ローボール）にはハウスルールが適用される。</li>
                            <li>ボタンのプレイヤーは、2枚連続でカードが配られることがある（第37項も参照）。</li>
                            <li>ミスディールとなったとき、再ディールは正確なリプレイとする。ボタンは動かさず、新しいプレイヤーは着席させずに、ブラインドも上がらない。ペナルティを受けているプレイヤーや最初のディールのときに着席していなかったプレイヤーにはカードを配り（第30項）、そのあとキルする。最初のディールと再ディールはペナルティを受けているプレイヤーにとって1ハンドとみなし、2ハンドとはみなさない。</li>
                            <li>SAが起こってしまうと、ミスディールを宣言することはできず、そのハンドは続行しなければならない（第36項を参照）。</li>
                            <li><span class="text-danger">何をもって不正なデックとするかは、その地域のゲーミング規制法およびハウスルールによって定義される。もし不正なデックが発見されたときは、SAが起きているかどうかに関わらず、プレイを止め、すべてのベットは返却される。ハンドが終了したあとは、不正なデックに基づく異議申し立ての権利は、第22項によって失う。</span></li>
                            </ul>

                            <h6 class="">36: 実質的なアクション（サブスタンシャル?アクション: SA）</h6>
                            <p class="card-description mb-4">SAとは、A) 少なくともどちらか片方がチップをポットに入れる、2つの順序にそったアクション（例：「2人がチェック」「2人がフォールド」以外の、任意のふた続きのアクション）または、B) 順序にそった3つのアクションの組合せ（チェック、ベット、レイズ、コールやフォールド）をいう。ブラインドをポストすることは、SAの遂行とはみなさない。第35項-Dおよび第53項-Bを参照。</p>

                            <h6 class="">37: 少ないカード枚数でのボタン</h6>
                            <p class="card-description mb-4">通常よりも少ない枚数しか配られなかった、ボタンのプレイヤーは、そのことをすぐに報告しなければならない。配られなかったボタンのカードは、行われているゲームタイプが許すときには、SAが起こったあとでも配ることができる。しかし、カードの枚数が少ないまま、ボタンのプレイヤーがアクションを起こしたとき（チェックやベットをしたとき）、それはデッドハンドとなる。</p>

                            <h6 class="">38: SAのあとのバーン</h6>
                            <p class="card-description mb-4">バーンカードは、スタブをプロテクトするために使用されるもので、カードの順序を守るためのものではない。SAが起こり、誤った枚数のカードのせいでキルされてしまったとき、キルされたすべてのカードはマックされ、以降のディーリングには偶然性が適用される<span class="text-danger">（推奨手順-14: 特別な状況ではランダム性が適用されるときがあるを参照）</span>。そのスタブは、通常のスタブとして取り扱われ、以降のそれぞれのストリートに対して、スタブから1枚だけ、バーンがされる。</p>

                            <h6 class="">39: 4枚のフロップや早く出されてしまったカード</h6>
                            <p class="card-description mb-4">フロップのカードが3枚ではなく4枚になったとき、それらカードが表になっているかどうかに関わらず、フロアが呼ばれる。ディーラーは4枚のカードを裏返しにして混ぜ、フロアが無作為に、その中のカードを1枚、次のバーンカードとして選び、残りの3枚をフロップのカードとする<span class="text-danger">（推奨手順-14: 特別な状況ではランダム性が適用されるときがあるを参照）</span>。こうした、早くディールしてしまったカードの取り扱いは、推奨手順-5を参照。</p>


                            <div class="text-center">
                            <h5 class="mb-5 text-center">プレイ: ベットとレイズ</h5>
                            </div>

                            <h6 class="">40: ベットの方法: 口頭によるベットとチップによるベット</h6>
                            <ul>
                            <li>ベットは、口頭による宣言および／またはチップを前に出すことで行われる。プレイヤーがその両方を行ったときは、先に行われた方がベット内容を決定する。同時に起こったとき、明確で合理的な発声が優先されるが、それ以外のときはチップによるものが有効となる。はっきりしないときや口頭による宣言とチップが矛盾しているとき、TDは、その場の状況と第1項を勘案して、そのベットを裁定する。追補例解を参照。第57項も参照。</li>
                            <li>口頭による宣言は、一般的な用語（コール、レイズなど）、額のみ（ワンサウザンドなど）またはその両方（レイズ、ワンサウザンド）で行うことができる。</li>
                            <li>すべてのベッティングルールにおいて、特定の額のみの宣言は、その額のチップを宣言せずに前に出したときと同じこととみなす。例: 「ツーハンドレッド」と宣言するのは、黙って200のチップを前に出すことと同じである。</li>
                            </ul>

                            <h6 class="">41: コールの方法</h6>
                            <p class="card-description mb-4">標準的な、許容されるコールの方法は、A) 「コール」と発声するB) コール額と同額のチップを出すC) 宣言せずにコール額以上のチップ1枚を出すD) 複数チップベットルール<span class="text-danger">（第45項）</span>に従い、宣言せずにコールと同額の複数チップを出す。ベット額と比較してかなり小さな額のチップを出す（例: NLHEで、ブラインドが2000-4000。Aが50000のベットしたあと、Bが1000のチップを1枚出す）ことは標準的でなく、とても推奨されない行為であり、ペナルティ対象となる。フルコールとみなすかも含め、TDの裁量で解釈される。</p>

                            <h6 class="">42: レイズの方法</h6>
                            <p class="card-description mb-4">ノーリミットまたはポットリミットで、レイズは、A) 一回の動作で、全額を前に出すB) チップを前に出すより先に、口頭で総額を宣言する<span class="text-danger">（C: 2モーションレイズは排除された）</span>自らの意思を明確に示すことは、プレイヤーの責任である。</p>

                            <h6 class="">43: レイズ額</h6>
                            <ul>
                            <li>レイズ額は、少なくとも、現行のベッティングラウンドにおける最大の<span class="text-danger">完全な</span>ベットまたは<span class="text-danger">完全な</span>レイズの額と同額以上でなければならない。直前の最大ベットの50%以上、ミニマムレイズ未満のレイズを行ったプレイヤーは、ミニマムレイズを行わなければならない。もし、50%未満のレイズ額で最初にレイズを宣言しなかったとき、あるいは、そのプレイヤーがオールイン（第45項-B）ではなかったとき、それはコールとなる。額を宣言することと同じ額のチップを前に出すことは同義である。（第40項-C）例: NLHEで1000のオープンベットが行われたあと、「フォーティーンハンドレッド」と口頭で宣言することと、1400のチップを前に出すことはどちらも、その前にレイズという宣言がなければ、コールである。追補例解を参照。</li>
                            <li>ほかに明らかにする情報がなければレイズを宣言したときの額は、合計のベット額を指す。例: Aが2000でオープン、Bは「レイズ、8000」と宣言。このときトータルベット8000となる。</li>
                            </ul>

                            <h6 class="">44: 大きな額のチップによるベット<span class="text-danger">（オーバーチップ）</span></h6>
                            <p class="card-description mb-4">ベットやブラインドに際したプレイヤーが、1枚の大きな額のチップ（最後のチップを含む）を1枚前に出したとき、レイズが口頭で先に宣言されなければコールとみなす。1枚の大きな額のチップでレイズをするとき、チップがテーブルに触れる前に宣言しなければならない。レイズが宣言されたが、額が宣言されなかったときには、そのチップで認められる最大額がレイズ額となる。ベットに相対していないとき、宣言なしにポットにチップを投入すると、そのチップに認められる最大額のベットをしたこととなる。</p>

                            <h6 class="">45: 複数チップでのベット</h6>
                            <ul>
                            <li>ベットしているときに、レイズやオールインを先に宣言せず、複数のチップ（最後のチップすべてのときを含む）でベットするとき、どのチップもコールをするに必要なときは、コールとみなされる。言い換えると、最も小さい額のチップを1枚のぞいても、コールの額未満のときは、コールとみなす。例1: プレイヤーAが400にオープン。Bが合計1100へとレイズした。Cは、1枚の500チップと1枚の1000チップを黙って前へ出した。このときはコールとなる。なぜなら、1枚の500チップを取りのぞくと、コール額となる1100に満たない額となってしまうからである。例2: 25-50で、プレイヤーAがポストフロップで1050にオープン。Bは、最後のチップである2枚の1000チップを出した。このとき、レイズやオールインが最初に宣言されていなければ、コールとなる。</li>
                            <li>すべてのチップがコールするために必要ではないとき、例えば、最小単位のチップを1枚取りのぞいても、コール額以上のとき、1) プレイヤーの手元にチップが残っているときは、<span class="text-danger">第43項</span>の50%基準にしたがって、そのベットを裁定する。2) プレイヤーのすべてのチップを使ったベットのとき、それが50%基準を満たしているか否かに関わらず、オールインとなる。追補例解を参照。</li>
                            </ul>

                            <h6 class="">46: さげられていない以前のベットでのチップ</h6>
                            <ul>
                            <li><span class="text-danger">混乱を避けるためにも、レイズに直面しているプレイヤーは以前のベットのとき出されたチップで、まださげられていないものが出されたまま残っているときは、以前のベットに新たなチップを加える前に、自分のアクションを口頭で発声すべきである。</span></li>
                            <li><span class="text-danger">レイズに直面しているときに、</span>前のベットで出したチップを明らかにさげる動作を行ったときは、コールまたはレイズのアクションが義務付けられ、そのチップを戻してフォールドすることはできない。</li>
                            <li><span class="text-danger">もし新たなチップが黙って足され、そのベットがハウスから見て曖昧であるとき、コールおよびレイズに関する第41-45項は以下のように適用される。1) もし以前に出されたチップがコール分を充たしておらず、なおかつ、そのまま残されているかまたは完全にさげられているかのどちらかのとき、額を越えるチップ1枚が出されたときはコールとなり、新たなチップ複数枚が出されたときは50％レイズ基準（第43項）が適用される。2) もし以前に出されたチップが部分的にさげられている、または、以前に出されたチップがコール分を充たしているとき、最終的なチップ額の合計が50％基準（第43項および45項）を満たしていればレイズとなり、もし満たしていないときは、コールとなる。追補例解を参照。</span></li>
                            </ul>

                            <h6 class="">47: リオープンベット</h6>
                            <ul>
                            <li>ノーリミットとポットリミットで、オールイン（または<span class="text-danger">複数のショートオールインによる蓄積</span>）は、その合計がフルベットやフルレイズにたっしていないとき、すでにアクションを起こし、自分にアクションが再度戻ってきたときに、フルベットやフルレイズ以上の額に直面していないプレイヤーに対し、新たにベッティング機会（リオープン）を与えるものではない。<span class="text-danger">もし複数のショートオールインがベットをリオープンするとしたら、そのラウンドで最後に発声している完全かつ有効なベットは常にミニマムレイズとなる（第43項も参照）。</span></li>
                            <li>リミットでは、フルベットやフルレイズの50％以上がリオープンには求められる。追補例解を参照。</li>
                            </ul>

                            <h6 class="">48: 許されるレイズの回数</h6>
                            <p class="card-description mb-4">ノーリミットとポットリミットでは、レイズの回数に制限はない。リミットでは、イベントの残りプレイヤー数が2人に減るまでは、ヘッズアップのときでさえレイズ回数が制限される。制限される回数は、ハウスが定める。</p>

                            <h6 class="">49: 受け入れられたアクション</h6>
                            <p class="card-description mb-4">ポーカーは、油断なき警戒と継続的観察のゲームである。コールの前に、相手の正しいベット額を判断するのは、他人に何を言われたとしても、そのコーラーの責任である。そのコーラーがカウントを要求したものの、ディーラーやほかのプレイヤーから誤った情報を与えられ、その額のチップを差し出すか<span class="text-danger">コールを宣言した</span>とき、コーラーは完全な正しいアクションを受け入れたとみなされ、本来の正しいベット額やオールイン額へとそれを訂正しなければならない。すべてのとき同様、TDの裁量により、第1項が適用されることがある。<span class="text-danger">推奨手順-12も参照。</span></p>

                            <h6 class="">50: 順序にそったアクション</h6>
                            <ul>
                            <li>プレイヤーは順序にそって、発声および／またはチップを前に出すことで、アクションを起こさなければならない。順序にそったアクションは拘束力を有し、投入されたチップはポットに維持される。</li>
                            <li>プレイヤーはベットの額が明確になるまでは、自身のアクションを待たなければならない。例: NLHEで、プレイヤーAが「レイズ」と言ったものの、額は言っていないときに、Bがすぐにフォールドしてしまった。レイズ額が明確になるまでは本来、Bはアクションを起こさずに待つべきである。</li>
                            </ul>

                            <h6 class="">51: 拘束力を持つ宣言／順序にそったアンダーコール</h6>
                            <ul>
                            <li>順序にそった、標準的な発声による宣言（コールやレイズなど）は、そのプレイヤーをそのときのアクションに完全にコミットさせる。追補例解を参照。</li>
                            <li>最初に「コール」と宣言せずに、コールに必要な額よりも少ない額を宣言あるいはチップを前に出すことはアンダーコールとなる。プレイヤーが順序にそったアンダーコールを 1) ヘッズアップのあらゆるベットもしくは 2) あらゆるベッティングラウンドにおけるマルチウェイのオープニングベットに直面した状況で行ったとき、そプレイヤーはフルコールをしなければならない。それ以外の状況は、TDの裁量で判断される。オープニングベットとは、それぞれのベットラウンドにおいて最初のチップによるベット（チェックではなく）である。ブラインドゲームにおいてポストされたBBは、プリフロップにおける、オープンベットとみなされる。オールインボタンはアンダーコールの起こる頻度を減らすために効果がある（推奨手順1を参照）。このルールは、プレイヤーがフルコールしなければならない状況およびTDの裁量により<span class="text-danger">アンダーコールされた額を放棄した上で</span>フォールドができるときに適用される<span class="text-danger">（追補例解も参照）。</span>アンダーベット、アンダーレイズについては、第43項を参照。</li>
                            <li>2人以上のアンダーコールが続けて起きたとき、1人目となるアンダーコーラーまでプレイを戻した上で、そのプレイヤーのベットアクションを、第51項-Bに従って、修正させなければならない。ほかのベッターのハンドの取り扱いは、TDが状況を鑑みて決定する。</li>
                            </ul>

                            <h6 class="">52: 不正確なベット、足りないベット、レイズ</h6>
                            <ul>
                            <li>リミットとノーリミットにおいて、正しい最低限の額にたっしていないオープンやレイズは、そのストリートのどのときであっても、修正される（リバーのときは、ショーダウンの始まる前）。例: NLHEで、ブラインドは100-200。ポストフロップでAが600にオープンし、Bが（200だけ足りない）1000へのリレイズをした。CとDがコールしたあと、Eがフォールドし、その誤りが見つかった。ターンを始める前までは、すべてのベッターに対してベット額を合計1200に訂正し、ターンを始めたあとは、誤りを訂正しない。アンダーコールについては、<span class="text-danger">第51項</span>を参照。</li>
                            <li>ポットリミットにおいて、不正確なポットのカウントによって足りないポットベットがあったとき、ポットのカウントが高く誤った（本来できないベット）ときは、そのストリートにおけるどのときであってもすべてのプレイヤーに対し、その額を訂正し、ポットのカウントが本来より低く誤ったものなら、そのベット後のSAが起こる前であれば、訂正される。追補例解を参照。</li>
                            </ul>

                            <h6 class="">53: 順序にそわないアクション（アウトオブターン: OOT）</h6>
                            <ul>
                            <li>OOT（チェック、コール、レイズ）があるとき、正しい順番のプレイヤーまで、アクションを戻す。OOTは、ペナルティを受ける対象となり、またそのプレイヤーまでのアクションに変更がないとき、そのアクションは拘束力がある。本来の順番のプレイヤーのチェック、コール、フォールドは、アクションを変更するものではない。アクションが変わるとき、OOTに拘束力はなくなり、ベットやレイズはそのプレイヤーに一旦戻された上で、コール、レイズ、フォールドの、すべての選択肢がある。順序にそわないフォールドは拘束力がある。追補例解を参照。</li>
                            <li>OOTにより順番を飛ばされたとき、そのプレイヤーのアクションを起こす権利は守られなければならない。合理的な時間があったにも関わらず、順番を飛ばされたプレイヤーが、SA（第36項）が起こる前に何も言わず、そのプレイヤーの後ろでOOTが起きたとき、OOTは拘束力を持つ。アクションの差戻しと飛ばされたハンドの取扱いは、デッドハンドの裁定やアグレッシブではないアクションへの制限を含め、その状況にあったものとTDが判断するような裁定につき、フロアが下す。追補例解を参照。</li>
                            </ul>

                            <h6 class="">54: ポットサイズとポットリミットでのベット</h6>
                            <ul>
                            <li>プレイヤーは、ポットリミットゲームに限って、ポットのサイズのカウントを要求する資格がある。リミットとノーリミットゲームは、ディーラーがポットのカウントをすることはない。</li>
                            <li>プリフロップにおけるブラインドのショートオールインのとき、ポットベット額の算出に、その額は影響しない。ポストフロップでは実際のポットサイズをもとにポット額を計算する。</li>
                            <li>「ポット額をベット」の宣言はノーリミットゲームでは有効なベットではないが、そのプレイヤーは有効な額をベット（少なくともミニマムベット）するよう拘束され、その行為は、ペナルティ対象となる。<span class="text-danger">ベットに直面したプレイヤーのとき、</span>有効なレイズを行なわなければならない。</li>
                            </ul>

                            <h6 class="">55: 無効となるベットの宣言</h6>
                            <p class="card-description mb-4">ベットに直面していないプレイヤーが、A) 「コール」と宣言したとき、それはチェックとなりB) 「レイズ」と宣言したとき、少なくともミニマムベットとなる。ベットに直面したプレイヤーが、「チェック」と宣言したとき、コールまたはフォールドとなるが、レイズはできない。</p>

                            <h6 class="">56: ストリングベット／レイズ</h6>
                            <p class="card-description mb-4">ストリングベット／レイズの判定は、ディーラーが行う。</p>

                            <h6 class="">57: 標準的でない、不明確なベット</h6>
                            <p class="card-description mb-4">非公式なベット用語やジェスチャーは、プレイヤーのリスクのもとで使わなければならない。これらは、プレイヤーの意図する意味以外に解釈される可能性がある。また、宣言されたベットのサイズが法的に複数の意味を持つとき、ベットをする前のポット額*以下の範囲で考えられる最も高い合理的な額と判断される。例: NLHEで、ブラインドは200-400。ポットが5000未満のとき、ここで「ベット、5」と宣言したとき、それ以外に明確な情報がないとき、ベット額は500とみなす。仮にポット額が5000以上のとき、ベット額は5000とみなす。*ポットの額は、それまでに行われ、プレイヤーの前に出されている、ひかれていないすべてのベット額の合計である。第2項、第3項および第40項、<span class="text-danger">第42項</span>を参照。</p>

                            <h6 class="">58: 標準的でないフォールド</h6>
                            <p class="card-description mb-4">最後のベットラウンドが終わるまでは、自分の番までのあいだにフォールドがないとき（チェックで回ってきたときや自分がポストフロップで最初にアクションを起こすプレイヤーのとき）に行う正しい順序にそったフォールドや、そもそも順序にそわないフォールドは、いずれもペナルティの対象となるうえ、そのフォールドは拘束力を持つ。<span class="text-danger">第15項-Bを参照。</span></p>

                            <h6 class="">59: 条件付きの、完了していない宣言</h6>
                            <ul>
                            <li>将来のアクションに関する条件付きの宣言は、標準的ではなく、強く推奨されない。そうした宣言は、TDの裁量により、拘束力があり、かつ／またはペナルティの対象となることがある。例: 「もしベットするなら、私はレイズするよ」といった宣言。</li>
                            <li>プレイヤーAが、「ベット」もしくは「レイズ」を宣言したあとに、正確なベット額が知られる前に、プレイヤーBがコールしたとする。このとき、Bに対して任意の額へのコールの義務を課すという裁定を含めた、その状況に最適なベット額をTDが判断する。</li>
                            </ul>

                            <h6 class="">60: 相手のチップスタックのカウント</h6>
                            <p class="card-description mb-4">プレイヤー、<span class="text-danger">ディーラーおよびフロア</span>は、相手のチップスタックを合理的に見積もる権利がある（第25項）。プレイヤーはオールインベットを受け、なおかつ自分がアクションに直面したときに限り、より正確なカウントを要求できる。オールインしたプレイヤーにはカウントの義務はなく、要求に応じ、ディーラーまたはフロアがカウントをする。許容されたアクション<span class="text-danger">（第49項）</span>が適用される。チップスタックを見やすくカウントしやすくするためのルール（第25項）によって、カウントの正確性が大きく<span class="text-danger">向上する。</span></p>

                            <h6 class="">61: お釣りを望んだオーバーベット</h6>
                            <p class="card-description mb-4">お釣りを得るためのベッティングアクションを起こしてはならない。意図したベット額以上のチップを前に出すと、周りのプレイヤーを混乱させることがある。宣言せずに出されたチップは、すべてベットの一部とみなされるリスクがある。例: オープンベットが325であり、プレイヤーAが200のお釣りを期待して、525（1枚の500と1枚の25）を宣言せずに出したとき、これは複数のチップルール<span class="text-danger">（第45項）</span>により、650へのレイズとみなす。</p>

                            <h6 class="">62: チップが後ろに残っているとあとで分かったオールイン</h6>
                            <p class="card-description mb-4">Aがオールインし、あるプレイヤーがそれをコールしたあとに、隠れたチップが見つかったとき、そのチップが許容されたアクションの一部になるか、TDが判断する<span class="text-danger">（第49項）</span>。アクションの一部とならないとき、Aが勝ったとしてもそのチップ分は得られない。Aが負けたときには、そのチップ分を節約することはできず、TDは勝ったコーラーにそれを渡すことができる。</p>


                            <div class="text-center">
                            <h5 class="mb-5 text-center">プレイ: その他</h5>
                            </div>

                            <h6 class="">63: 隠れているチップと移動中のチップ</h6>
                            <p class="card-description mb-4">プレイヤーは、チップを見えない状態で保持、移動してはならない。そのプレイヤーは、隠したチップを没収、失格処分となることがある。没収されたチップは、ゲームから除外される。チップを移動するとき、必要に応じてラックやバッグを提供することを、TDAは推奨する。</p>

                            <h6 class="">64: 遺失物のチップ</h6>
                            <p class="card-description mb-4">遺失物のチップが見つかり、<span class="text-danger">誰のものかが分からないとき</span>、プレイから除外し、トーナメント用備品に戻す。</p>

                            <h6 class="">65: 誤ったキル／反則／エクスポーズとされたハンド</h6>
                            <ul>
                            <li>プレイヤーは、ショーダウンのときに判定を待つまでのあいだも含めて、自身のハンドを常にプロテクトしなければならない。ディーラーが、誤ってキルしたり、TDの判断でキルしたりして、100%の確実性を持って特定できないときは、プレイヤーはいかなる処置も受けず、ベット分を取り戻す権利もない。プレイヤーがベットやレイズし、まだコールされていないときは、そのベットやレイズ分のチップはプレイヤーに戻される。</li>
                            <li>一度キルされたものの、それが特定可能であるとき、カードが開かれてしまったとしても、プレイの続行は可能である。</li>
                            </ul>

                            <h6 class="">66: スタッドでのデッドハンドとマック</h6>
                            <p class="card-description mb-4">スタッドポーカーで、プレイヤーがアクション時にアップカードを取り上げたとき、そのハンドはデッドハンドとなる。スタッドにおける正しいマックは、すべてのアップカードを裏にし前に出すことである。</p>


                            <div class="text-center">
                            <h5 class="mb-5 text-center">エチケットとペナルティー</h5>
                            </div>

                            <h6 class="">67: 情報開示の禁止、<span class="text-danger">ワンプレイヤーワンハンド</span></h6>
                            <p class="card-description mb-4">プレイヤーは、トーナメントにおいて常にほかのプレイヤーのことをプロテクトしなければならない。そのためプレイヤーは、ハンドに参加しているかどうかに関わらず:</p>
                            <ol>
                            <li>ライブカードやマックしたハンドの内容を話してはならず</li>
                            <li>プレイについて、いつでもアドバイスしたり批判したりしてはならず</li>
                            <li>まだ開かれていないハンドを推測してはならない</li>
                            </ol>
                            <p class="card-description mb-4">ワンプレイヤーワンハンドを採用する。なかでも、特にこのルールでは、ほかのプレイヤー、アドバイザー、見学者に対して、ハンドを見せたり、戦略を議論することが禁止されている。</p>

                            <h6 class="">68: カードのエクスポーズと適切なフォールド</h6>
                            <p class="card-description mb-4">アクション保留中のハンドのエクスポーズは、<span class="text-danger">そのプレイヤーが最後にアクションを起こすときを含めて、</span>ペナルティの対象となる可能性があるが、デッドハンドにはならない。任意のペナルティは、そのハンドが終了したときから始まる。フォールドのときは、カードをテーブル上で低く出さなければならず、故意にそれをエクスポーズしたり、高く投げたりしてはならない。第66項を参照。</p>

                            <h6 class="">69: 倫理的なプレイ</h6>
                            <p class="card-description mb-4">ポーカーは、個人のゲームである。ソフトプレイはペナルティとなり、そのペナルティにはチップの没収やイベントの失格も含まれる。チップダンピングやそのほかの様々な共謀も失格の原因となる。</p>

                            <h6 class="">70: エチケット違反</h6>
                            <p class="card-description mb-4">エチケット違反は、第71項の強制的な執行の対象となる。たとえば、常習的なゲームの遅延、ほかのプレイヤーの身体やカードまたはチップへの不必要な接触、OOTの繰り返し、<span class="text-danger">カードやチップを見えにくかったり数えにくい形での保持</span>、ディーラーの届かない場所へのベット、罵倒行為、<span class="text-danger">他者を不快にする体臭</span>、および過度の私語を含むが、これに限らない。</p>

                            <h6 class="">71: 警告、ペナルティー、失格</h6>
                            <ul>
                            <li>執行の選択肢には、口頭注意、1ハンド以上の退席、1周以上の退席、失格が含まれる。退席する周回数は、次のように決める。ペナルティが課されたとき、テーブルにいるプレイヤー（違反者含む）の人数と、ペナルティとなる周回数を掛けたハンドのあいだ退席とする。度重なる違反はさらに重いペナルティの対象となる。退席しているプレイヤーからも、アンティやブラインドが出される。</li>
                            <li>アクション保留中にカードを見せ、カードを投げ、1プレイヤー1ハンドルールに違反するなどのときは、エチケット違反（第70項）の対象となることがある。ペナルティは、ソフトプレイ、不適切行為、秩序を破壊する振舞いやチーティングのときにも課される。リバーにおいて最後のアクションを起こすとき、絶対的なナッツをチェックする行為は、自動的にソフトプレイによる違反にはならない。状況に応じてTDが裁定する。</li>
                            <li>ペナルティを受けたとき、テーブルから離れなければならない。カードは、そのプレイヤーの席にも配られ、ブラインドとアンティも出されるが、最初のディールのあとにキルする。スタッドでブリングインの対象となったときは、そのプレイヤーも、ブリングインをポストしなければならない。</li>
                            <li>失格となったプレイヤーのチップは、取りのぞかれる。</li>
                            </ul>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4 mb-4">
                            <img class="mx-auto text-center" src="https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/images/poker_tda.png" alt="poker_tda" />
                        </div>
                        <div class="col-md-8 pt-3 text-center" style="line-height:1.5rem;font-size:1.1rem">
                            <div class="font-weight-bold" style="font-size: 1.4rem">POKER TOURNAMENT DIRECTORS ASSN</div>
                            <div class="font-weight-bold">
                                <span class="">2019年版推奨手順</span>
                            </div>
                            <div class="font-weight-bold" style="">
                                <span class="text-danger">Version 1.0, 2019年9月17日</span>
                            </div>
                            <div class="text-danger" style="">赤字の斜体は2017年からの変更</div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">

                            <p class="card-description mb-4">TDA推奨手順は、エラーを減らしイベント管理を改善するための指針の提言である。この推奨手順は同時に、多くのバリエーションがある状況のために単一の共通的なルールでは対処できないときに適用する。このようなときの最も公正な裁定には、複数のルールを適用しすべての状況を評価することが必要になる。そのときに第1項は第一の指針となる。</p>

                            <h6 class="">推奨手順-1: オールインボタン</h6>
                            <p class="card-description mb-4">オールインボタンは、プレイヤーが「オールイン」したことを、明確に示すものである。このボタンは（プレイヤーが持つよりも）ディーラーが預かっておくべきものである。プレイヤーがオールインしたとき、ディーラーはテーブルの残りのプレイヤーから見えるよう、そのプレイヤーの目の前にオールインボタンを置く。 </p>

                            <h6 class="">推奨手順-2: ベットをブリンギングすることは推奨されない</h6>
                            <p class="card-description mb-4">ベットやレイズがされるたびに、そのチップから前のベット額を引くブリンギングは、ディーリングとしてよくないものである。ベットスタックを減少させることは、アクションに影響を与えたり、混乱を招いたり、エラーを増やしてしまったりする。<span class="text-danger">（排除: カウントが必要であるとき以外は、ベットチップにディーラーは触れてはいけない）。</span>アクションに直面するプレイヤーに限り、ディーラーにブリンギングを求めることができる。</p>

                            <h6 class="">推奨手順-3: 個人の持ち物</h6>
                            <p class="card-description mb-4">テーブル上はチップスタックの管理、ディーリング、ベッティングのための重要な場所である。テーブルと近くのスペース（足元と通路）は、不必要な私物で散らかしてはいけない。各カードルームは、トーナメントエリアで許可される物を明確に表示するべきである。</p>

                            <h6 class="">推奨手順-4: 順番が乱れたスタブ</h6>
                            <p class="card-description mb-4">配られるカードが残った段階で、スタブを誤って落としてしまい、順番が変わったと思われるとき: 1) まず可能であれば元々の順番に再構築することを優先する。2) 不可能なとき、スタブのカードだけを用いて（マックやそれまでにバーンされたカードを混ぜずに）新しいスタブを作る。そのとき、しっかりシャッフル、カットをして作った新しいスタブを使ってプレイを進行する。3) 落としたスタブが、マックやバーンカードと混ざったときは、マックやバーンカードと一緒にスタブをよく混ぜ、シャッフル、カットして作った新しいスタブを使い、プレイを進行する。</p>

                            <h6 class="">推奨手順-5: 早く配られてしまったカード</h6>
                            <p class="card-description mb-4">進行中のラウンドのアクションが終わる前に、誤って次のラウンドのボードやバーンカードが配られてしまうことがある。このとき、一般的な対処法は次の通りとなる。</p>
                            <ul>
                            <li><u>フロップを早々に開いてしまったとき、</u>バーンとして使ったカードはそのまま、正しいバーンカードとする。開いてしまったフロップは、一度スタブに戻してから再度シャッフルして、バーンカードなしで、改めてその新しいスタブからフロップを開く。</li>
                            <li><u>ターンを早々に開いてしまったとき、</u>そのカードは、横に置かれる。別のカードがバーンカードとして使われ、リバーカードとなる予定だったカードが新しいターンカードとして配られる。アクションを起こしたあと、横に置かれていたターンカードはスタブに戻されシャッフルされる。リバーカードはバーンカードなしで開く。</li>
                            <li><u>リバーカードを早々に開いてしまったとき、</u>リバーカードはスタブに戻され、置かれたバーンカードはそのままリバーカードとする。リバーアクションが終わったら、スタブを再度シャッフルした上で、バーンカードなしでリバーカードを配る。</li>
                            <li><u>スタッドにおいて、早く配られたカードがあったとき、</u>追加のカードが配られ、そのカードは早く配られてしまったカードの横に、残っているプレイヤーのため、全体のラウンドのカードを表すため、置かれる。そのラウンドが終わったら、次のストリートは通常通りバーンとディールする。最終ストリートとは、早く配られてしまったカードと横に追加的に置いたカードはスタブに戻す。スタブはリシャッフルされ、最後のストリートのカードを配る。</li>
                            </ul>

                            <h6 class="">推奨手順-6: プレイヤーの効率的な移動</h6>
                            <p class="card-description mb-4">テーブルブレイクやバランスのときのプレイヤーの移動は、ブラインドを逃したり、ゲームが遅れないように迅速に行われなければならない。可能なら、チップの持ち運びはラックが用いられるべきである。プレイヤーが不必要に大量のチップを持ち運ぶことがないよう、チップのカラーアップを十分に行わなければならない（<span class="text-danger">第10項、第11項、</span>第63項を参照）。</p>

                            <h6 class="">推奨手順-7: ディーラー交代のタイミング</h6>
                            <p class="card-description mb-4">ディーラーの交代は、休憩やブラインドアップまでの90秒間は避けることをTDAは推奨する。ゲームの極めて重要な段階で、時間切れとなることを避けるためである。</p>

                            <h6 class="">推奨手順-8: ハンドフォーハンドの手順</h6>
                            <ul>
                            <li>払い出しを受ける資格は、次のようなアナウンスで開始となる: 「現在プレイ中のハンドが終了後、ハンドフォーハンドとなります」。その進行中のハンドにおいて、インザマネーとなるのに十分な人数が負けて（バスト）しまったとき、バストしたプレイヤーも払い出しを受ける可能性がある。例: 50人入賞のNLHEのトーナメントで、アナウンスが行われたときに52人が残っていて、そのハンドで3人が一度にバストした。これら3人のプレイヤーは、50位に払い出される額を分け合う。</li>
                            <li>ハンドフォーハンド中、実際にハンドがどれほどの時間をかけてプレイされたかには関係なく、それぞれのハンドに対し予め設定された時間を掛けて進める。TDA標準は1ハンドにつき2分である。2分進行はアナウンスの瞬間にプレイされているハンドから始まる。例: 「現在プレイ中のハンド終了後ハンドフォーハンドとなります」とアナウンスがあった瞬間、いまのレベルの残り時間が17:30だったとき、次ハンドの開始時間は15:30にセットされ、その次ハンドは13:30から始まる。</li>
                            <li>プレイヤーたちがレベルの変わるタイミングを明確に知ることができるよう、それぞれのハンドに対し、可能なときはいつでも、時計を2分から減らす。</li>
                            <li>1ハンド2分ずつの時間経過および新しいレベルにたっしたときは、ブラインドも上がり続ける。</li>
                            <li>ハンドフォーハンド中、プレイヤーたちは自分の席にとどまることが推奨されるものの、要求事項ではない。</li>
                            <li>ハンドフォーハンド中、オールインコールがあったとき、プレイ中であるすべてのプレイヤーのカードは裏向きにされなければいけない。ディーラーは、命じられるまで追加のカードを配ってはならない。</li>
                            </ul>

                            <h6 class="">推奨手順-9: ファイナルテーブルの構成人数 </h6>
                            <p class="card-description mb-4"><span class="text-danger">9人テーブル（ナインハンデッド）および8人テーブル（エイトハンデッド）のイベントでは、5人プレイヤーの2テーブルから9人のファイナルテーブルへと統合される。7人テーブル（セブンハンデッド）および6人テーブル（シックスハンデッド）のイベントでは、4人プレイヤーの2テーブルから7人のファイナルテーブルへと統合する。</span></p>

                            <h6 class="">推奨手順-10: スタッドトーナメントのディーリング手順</h6>
                            <ul>
                            <li><span class="text-danger">最初のディールのときに伏せ札（ダウンカード）が表向きに配られた（エクスポーズ）とき、それはそのプレイヤーの表向きカード（アップカード）として用いられ、サードストリートのカードがそのプレイヤーに伏せて配られる。そのプレイヤーはブリングインになり得る。</span></li>
                            <li><span class="text-danger">セブンスストリートのカードが表向きに配られたとき、もしまだベットアクションが残されているときは交換される。セブンスストリートのカードは、そのハンドでのベットアクションがもう残されていないときでも伏せて配られるべきであり、オールインの状況では敗退の危機にないプレイヤーから先に開くべきである。</span></li>
                            <li>席にいないプレイヤーへのカード（第30項を参照）は、キルされる。<span class="text-danger">フォースストリートのカードは、ライブハンドのないプレイヤーには配られない。</span></li>
                            <li>スタッド（やスタッド8）のハイハンドやラズのローハンドで、みえている2人以上のハンドが同じであったとき、どちらのゲームでも、スートによるハイカードを持つプレイヤーからはじめにベッティングをスタートさせる。</li>
                            <li>ローカードを配られたプレイヤーがアンティでオールインとなるとき、ベットアクションは、そのプレイヤーの、左隣のプレイヤーからはじめる。チップを持つプレイヤーたちは、少なくともブリングインするか、フォールドしなければならない。</li>
                            <li><span class="text-danger">ペアが表にみえているときでも、フォースストリートでのベットは2倍にならない。</span></li>
                            <li><span class="text-danger">スタッドで、本来配られるべきときより前に配られてしまったカードの取り扱いについては推奨手順5-Dを参照。</span></li>
                            <li><span class="text-danger">セブンスストリートでスタブが足りなくなったときの手続き。もしセブンスストリートまでにスタブが「必要とされる数」（残りのプレイヤー数+バーンカード+配られていない最後のカード）を下回ったときは次のように進行する。A) もし必要とされる数がそこまでの3枚のバーンカード（フォース、フィフス、シックススストリート）を加えることで充たされるなら、そのスタブにそこまでのバーンカードを加えた上でスクランブルし、新たなスタブを作る。新しいスタブはカットされ、カード1枚がバーンされ、各プレイヤーに1枚ずつが配られる。B) もし、いまのスタブに最低3枚のカードが残っているが、これまでのバーンを加えても必要とされる数を充たさないなら、ディーラーはいまのスタブの一番上のカードをバーンして、その次のカードはテーブルの中央にコミュニティカードとしてディールされる。C) もし、いまのスタブが3枚未満であるとき、それまでのバーンカード3枚を加えてスクランブルし、新しいスタブはカットされ、カード1枚がバーンされ、その次のカードがコミュニティカードとしてディールされる。D) コミュニティカードが使われているとき、シックススストリートでの最初のプレイヤーがセブンスストリートで最初にアクションを起こすプレイヤーとなる。</span></li>
                            </ul>

                            <h6 class=""><span class="text-danger">推奨手順-11: アンティの形式、アンティは減額しない</span></h6>
                            <p class="card-description mb-4"><span class="text-danger">シングルペイヤーシステム（支払う人数を1人だけにするシステム）にすることを目的として、アンティファーストカルキュレーション（まずアンティを先に計算する）によるビッグブラインドアンティ形式（big blind ante: BBA）が推奨される。イベント進行に伴うアンティ減額はすべきではない（ファイナルテーブルを含む）。</span></p>

                            <h6 class=""><span class="text-danger">推奨手順-12: ディーラーはベットやレイズをアナウンスすべき</span></h6>
                            <p class="card-description mb-4"><span class="text-danger">ディーラーは、オールインではないベットの額をテーブルでベッティングが進行しているあいだ、常にアナウンスし続けるべきである。オールインベットは、そのときアクションを起こすべきプレイヤーからの要求があったときのみカウントされる。受け入れられたアクションのルール（第49項）は、ここでも継続して適用される。スケジュールとして組み込まれた、あるいはその場の判断によるカラーアップは、ベットのカウントしやすさを向上させる。</span></p>

                            <h6 class=""><span class="text-danger">推奨手順-13: ディーラーはスプリットポットゲームではチップをスタックにするべき</span></h6>
                            <p class="card-description mb-4"><span class="text-danger">それが可能であれば、ディーラーはスプリットポットゲームでは、ポットを定期的にスタックにするべきである。チップをスタックにすることで、プレイヤーからの視認性を不明瞭にしたり、ゲーム進行の妨げになることは避けるべきである。</span></p>

                            <h6 class=""><span class="text-danger">推奨手順-14: 特別な状況ではランダム性が適用されるときがある</span></h6>
                            <p class="card-description mb-4"><span class="text-danger">TDAルールおよび手順の中で取り上げられていない誤りを修復する目的で、TDは解決法を編み出すに当たってランダム性の概念を用いることがある。</span></p>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4 mb-4">
                            <img class="mx-auto text-center" src="https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/images/poker_tda.png" alt="poker_tda" />
                        </div>
                        <div class="col-md-8 pt-3 text-center" style="line-height:1.5rem;font-size:1.1rem">
                            <div class="font-weight-bold" style="font-size: 1.4rem">POKER TOURNAMENT DIRECTORS ASSN</div>
                            <div class="font-weight-bold">
                                <span class="text-danger">2019ルール版 追補例解</span>
                            </div>
                            <div class="font-weight-bold" style="">
                                <span class="text-danger">Version 1.0, 2019年9月17日</span>
                            </div>
                            <div class="text-danger">
                                <span class="text-danger">赤字の斜体は2017からの変更</span>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <p class="card-description mb-4">Poker TDAは、2001年に設立されたポーカー業界の任意団体である。ポーカートーナメントルールの世界的な統一性を高めることが使命である。Poker TDAルールは、ハウスルールを補足する。ハウスルールとの競合があったときは、ハウスルールを適用する。</p>

                            <h6 class="">第10項: テーブルブレイクのときの2段階のランダムな手順</h6>
                            <p class="card-description mb-4">2段階のランダムや「ダブルブラインド」と呼ばれる方法により、新しい席の割り当てに不正のないような方法をとることができる。以下は例である。1) ブレイクするテーブルでプレイヤーにシートカードを見せたあと、それを裏向きにしてシャッフルし束にする。2) ディーラーは、プレイヤーに表向きでトランプを1枚ずつ配る。数字とスートの強いトランプを持っているプレイヤーから順番に、シートカードを配る。</p>

                            <h6 class="">第16項: オールイン時のホールカードのフェイスアップ</h6>
                            <p class="card-description mb-4">このルールの意味することは次の通りである。少なくとも1人のプレイヤーがオールインをし、ほかのプレイヤーに、これ以上のベットアクションの機会がないとき、すべてのプレイヤーのホールカードが表向きにされる。ショーダウンとなるまで、カードの開示を遅らせることもなければ、メインポットのみしか関与していないプレイヤーのカードの開示を、サイドポットの決着がつくまで遅らせることもない。ショーダウンの前のどのストリートにおいても、ベットアクションの完了したときに、カードをすべて表向きにしたあと、残りのコミュニティーカードをめくることになる。</p>
                            <p class="card-description mb-4"><u><strong>例1:</strong></u> NLHEで2人のプレイヤーが残っている。ターンにおいて、スタックの少ないプレイヤーAがオールインし、それをプレイヤーBにコールされた。このとき、AとB両方のダウンカードを表向きにする。そしてバーンをし、リバーを開き、ショーダウンに進む。</p>
                            <p class="card-description mb-4"><u><strong>例2:</strong></u> NLHEで3人のプレイヤーが残っている。プリフロップで、1番ショートスタックのプレイヤーAがオールインし、プレイヤーBとCの2人にコールされた。このときはBとCの両方が、さらなるベットアクションの可能なチップを持っているため、カードはまだ表向きにしてはいけない。<br />フロップにおいてBとCがチェックをした。まだベットはできるため、カードは表向きにしない。<br />ターンでBがオールインをし、Cがコールした。このときこれ以上のベットアクションは起こらないため、A、B、Cのカードをすべて表向きにしなければいけない。バーンをして、リバーを開いて、ショーダウンに進まなければいけない。まず、BとCの2人のあいだのサイドポットを裁定し、そのあと、メインポットを与える。注意: BとCのあいだのサイドポットが裁定されるまで、Aの持つカードを裏向きにしておくということはない。</p>
                            <p class="card-description mb-4"><u><strong>例3:</strong></u> NLHEで3人のプレイヤーが残っている。プリフロップで、1番ショートスタックのプレイヤーAが700をオールインしたところ、数千チップを残しているプレイヤーBとCのふたりにコールされた。BとCは、さらにベットアクションができるチップを持っているので、このときは、まだカードを表向きにしない。<br />フロップにおいて、BとCがチェックした。まだベットアクションができるため、カードは表向きにしない。<br />ターンにおいて、Bが1000をベット、Cがコールした。BとCの両者はどちらもまだチップを持っていて、リバーカードが残っているため、ベットアクションは可能な状況である。そのため、このときはまだカードを表向きにしない。<br />リバーにおいて、BとCがチェックした。ベットアクションは終了し、ハンドがショーダウンの段階となっているため、AとBとCはハンドを表向きにしなければいけない。まず初めに、BとCのあいだの2000のサイドポットを裁定する。そのあと、メインポットの裁定をする。注意: BとCのあいだのサイドポットが裁定されるまで、Aの持つカードを裏向きにしておくことはない。</p>

                            <h6 class="">第18項: ハンドの開示要求</h6>
                            <p class="card-description mb-4"><u><strong>例1:</strong></u> NLHEで3人のプレイヤーが残っている。リバーの段階で誰もベットやオールインをしていない状況である。ショーダウンで、プレイヤーAはカードを裏向きのまま投げた。ディーラーによって、それらカードはマックされた。プレイヤーBはカードを表向きにし、プレイヤーCは裏向きのままカードを前に押し出した。このときBは自分だけがカードをみせたことになるため、Cにハンドを見せるよう要求することができる。しかし、この要求はTDの裁量に委ねられる。なぜならば、リバーの段階でプレイヤーBはベットしておらず、ハンドをみる権利がないとみなされるためである。また、AとCは自分の持つハンドを見せておらず、またそのときにカードを持っていないため、ほかのプレイヤーにハンドの開示を求めることはできない。</p>
                            <p class="card-description mb-4"><u><strong>例2:</strong></u> NLHEで4人のプレイヤーが残っている。リバーの段階でプレイヤーAは1000ベットし、Bはコールした。Cが5000にレイズしたあと、D、A、Bはそれぞれコールした。誰もオールインはしていない。Bがカードを表向きにし、トリップスをみせた。Dはすぐにカードを裏向きでマックし、それをディーラーがキルした。Cは裏向きでカードを押し出そうとした。このとき、AとBは、Cのハンドの開示を要求することができる。理由は以下の通りである。1) Cはリバーにおけるラストアグレッサーであり、AとBの2人は、Cのカードを見るために、コール分のお金を払っているため。2) AとBの2人は、自身のカードを所持しているためDは自身のカードを開示せずフォールドしたので、Cにハンド開示を要求する権利はない。この状況で、BがAのハンドの開示を要求するなどのほかの要求（ほかのコーラーのハンド開示要求）は、すべてTDの裁量に委ねられる。</p>

                            <h6 class="">第20項: 奇数チップの授与</h6>
                            <p class="card-description mb-4">ハンドが同じ価値を持つとき（例オマハ8のウィール（A2345））は、ポットを可能な限り平等に分けなければいけない。</p>
                            <p class="card-description mb-4"><u><strong>例1:</strong> オマハハイローにおいて、</u>2人のプレイヤーが、ハイとローの両方となる2-3-4-5-6のレインボーで勝ったとする。プレイヤーAは6のスペード、Bは6のクラブである。 ポットには、最小デノミまで分割された合計66のチップがある。このときの、公平な正しいチップの分配の仕方は、Aに33、Bに33である。誤っている分割方法は、次のようなものである。ハイに33、ローに33をわける。そしてハイにおいて残った1枚のチップを、マークの優劣によりAに与え、ローにおいても同様にマークの優劣で残った1枚をAに与える。このとき、Aが34、Bが32となってしまう。</p>
                            <p class="card-description mb-4"><u><strong>例2:</strong> セブンカードスタッドハイローにおいて、</u>2人のプレイヤーがハイとローの両方となる2-3-4-5-6で勝ったとする。プレイヤーAは6のスペード、Bは6のクラブである。 ポットには、最小デノミまで分割された合計66のチップがある。このときの、公平な正しいチップの分配の仕方は、Aに33、Bに33である。誤っている分割方法は、例1と同様である。</p>

                            <h6 class="">第38項: SAのあとのバーン</h6>
                            <p class="card-description mb-4"><u><strong>例1-A:</strong></u> ブラインドは、50-100。1, 2番シートがSB, BBである。プリフロップで、すべてのプレイヤーに最初のハンドが配られた。3番シート（UTG）がフォールド、4番シートがコールをして、SAが完了した。ここで5番シートのプレイヤーが、自身のハンドに1枚のカードしかないことに気が付いた。このとき、そのプレイヤーのハンドはデッドハンドとなる。すでに、SAが完了しているためである。このあとディーラーは、1枚バーンカードを出してからフロップを開く。カードを「元の順番へ戻す」ために2枚のカードをバーンすることはない。</p>
                            <p class="card-description mb-4"><u><strong>例1-B:</strong></u> ブラインドは、50-100。1, 2番シートがSB, BBである。プリフロップで、すべてのプレイヤーに最初のハンドが配られた。3番シート（UTG）がフォールド、4番シートがコールをして、SAが完了した。ここで、5番シートのプレイヤーが、自身のハンドに3枚のカードがあることに気が付いた。このとき、そのプレイヤーのハンドはデッドハンドとなる。すでに、SAが完了しているためである。このあとディーラーは、1枚バーンカードを切って、フロップを開くことになる。5番シートの3枚目のカードをバーンカードとして切り、スタブからはバーンを使わないといった取り扱いをすることはない。</p>

                            <h6 class="">第40項-A: ベットの方法: 不明確、あるいは矛盾しているベット</h6>
                            <p class="card-description mb-4">はっきりしないときや口頭による宣言とチップが矛盾しているとき、TDは、その場の状況と第1項を勘案して、そのベットを裁定する。追補例解を参照。</p>
                            <p class="card-description mb-4"><u><strong>例1:</strong></u> リバーのヘッズアップにおいて、プレイヤーAが「フォーティトゥーサウザンド」と口頭で宣言しつつ、5000チップを1枚しか出さなかった。テーブル全員が、口頭の宣言を聞いたわけではなかった。プレイヤーBはコールしようと5000チップを出した。両者がハンドをみせ、Aがベストハンドだった。裁定の基準は様々複合している: 口頭の宣言が先にあったが必ずしも明確ではなかった。チップでは5000のベットが示されている。こうした不明確で矛盾している状況のとき、TDは第1項を適用してできる限り公正な裁定を行う。</p>

                            <h6 class="">第43項: レイズ額「いまのベットラウンドにおける最大のベット額もしくはレイズ額」</h6>
                            <p class="card-description mb-4">この一説の意味するところは、いまのラウンドにおける、前のベッターによる最大の追加的なアクション、もしくは「適切な（額の）増分」を意味する。いまのラウンドとは、「いまのストリート」のことを意味し、ボードゲームであれば、例えば、プリフロップ、フロップ、ターン、リバーであり、7スタッドであれば、3rd-4th-5th-6th-7thストリートのことを意味する。</p>
                            <p class="card-description mb-4"><u><strong>例1:</strong></u> NLHEで、ブラインドは100-200。ポストフロップにおいて、Aが600にオープンし、Bが1000を追加した1600のレイズをした。さらにCが、2000を増やした3600のリレイズをした。この状況でDがさらにレイズをしたいときは、彼は少なくとも「いまのラウンドにおける、最大のベット額もしくはレイズ額」であるCの2000という額を上乗せしなければいけない。そのためDは、3600に2000を加えた、少なくとも5600以上の額にしなければならない。注意しなければいけないのは、Dが最低でも上乗せしなければいけない額が、Cのベット額全体の3600ではなく、Cが上乗せした額の2000であるという点である。</p>
                            <p class="card-description mb-4"><u><strong>例2:</strong></u> NLHEで、ブラインドは50-100。プリフロップにおいて、UTG にいるAが、トータル150（ベット額の増分は50）のオールインをした。つまり我々にとっては、100のブラインド分のベット額に加えて、50の分だけ増えたオールインを受けていることになる。どちらの額が大きいだろうか。100という額が、「いまのラウンドにおける、最大のベット額もしくはレイズ額」であるため、もしBがリレイズをしたいとき、少なくとも100を増分とした、250以上の額をトータルの額とするレイズをしなければならない。</p>
                            <p class="card-description mb-4"><u><strong>例3:</strong></u> NLHEで、ブラインドは100-200。ターンにおいて、Aが300をベットした。Bは2枚の500チップで、合計1000のレイズ（増分は 700）をした。Cがコールをするときの額は1000 となる。Cがレイズをしたいとき、「いまのラウンドにおける、最大のベット額もしくはレイズ額」は、Bのレイズの増分である700である。そのため、Cの最小のレイズ額は、700を加えた合計1700となる。彼の最小レイズのための増分は、Bのベット額の1000ではないことに注意してほしい。</p>
                            <p class="card-description mb-4"><u><strong>例4-A:</strong></u> NLHEで、ブラインドは25-50。Aは75を加えた合計125のレイズをした。これは、50に75を加えた額であることに注意したい。このストリートにおいて、さらにレイズをしたいとき、「いまのラウンドにおける、最大のベット額もしくはレイズ額」は75である。そして今Bは、そのミニマムのレイズ分（75）を加えた200のレイズをした。そしてCはさらに、300を加えた500のレイズをした。いまの状況は、最初のベット額の50に対して、75の額の増加が2回あったあと、300の額の増加があった。もしDがリレイズしたいとき、「レイズの増加額は少なくとも、いまのラウンドにおける、最大のベット額もしくはレイズ額」 である、300を加えなければならない。そのため、Dは少なくとも300を加えた合計800のレイズをしなければならない。</p>
                            <p class="card-description mb-4"><u><strong>例4-B:</strong></u> 4-Aと同じブラインドであり、Dまで500で回ってきた部分は同じであるものの、Aによって450の増分を加えた500のレイズがあり、BとCがそれにコールをした状況である。このとき、ブラインドのベット額である、50とレイズの増額が450である状況となっている。「レイズの増加額は少なくとも、いまのラウンドにおける、最大のベット額もしくはレイズ額」 であるため、今回それはAの増分である450となる。Dがコールをしたいときは500のままであり、レイズをしたいときは、少なくとも450をそれに加えた、950以上のレイズをしなければならない。</p>

                            <h6 class="">第45項: 複数チップのベッティング</h6>
                            <p class="card-description mb-4">A: ベットのとき、レイズやオールインを先に宣言することのない（最後のチップによるベットを含む）複数の同一単位のチップ（を投入すること）は、すべてのチップがコールをするために必要なとき、コールとみなす。B: すべてのチップがコールをするために必要ではないとき、たとえば最小単位のチップ1枚をのぞいたときにもコール額以上あるときには、1) プレイヤーの手元にチップが残っているときには、第47項の50%の基準に従って裁定する。2) プレイヤーの最後のチップを使ったベットであってオールインであったとき、50%の基準にたっしているかどうかで、オールインとなるかを裁定する。</p>
                            <p class="card-description mb-4"><u><strong>例1:</strong></u> 1枚のチップを引いてもコールの額未満のときには、コールとみなされる。<br /><u>1-A:</u> フロップでプレイヤーAが1200ベットし、Bが宣言せずに1000を2枚出したとき。これは、 どちらかのチップをのぞくと1200を下回るため、コールとなる。<br /><u>1-B:</u> NLHEで、ブラインドは250-500。プリフロップでUTGがレイズ600?トータル1100。次のプレイヤーが宣言せずに500点1枚と1000点1枚を出した。これは、500点か1000点のチップをのぞくと1100を下回るため、コールとなる。</p>
                            <p class="card-description mb-4"><u><strong>例2:</strong></u> 1-Bのとき、UTG+1のプレイヤーが宣言せずに1000を1枚と100を5枚出した。このとき、コール額以外の4枚の100は取りのぞかれ、1100のコール分を残す。さらに、第43項に従って、ミニマムレイズの増分は600の50%は300となる。このため、UTG+1はコール額の1100に300を加えた1400以上を出しているので、フルレイズの1700と判断される。UTG+1はレイズしなければならない。</p>
                            <p class="card-description mb-4"><u><strong>例3:</strong></u> 例2と同じように、UTG+1のプレイヤーが宣言せずに1枚の1000と3枚の100を出した。このとき、1100をのぞいた増分は200で、ミニマムレイズ額の50%の300にたっしていないため、コールとなり、200はプレイヤーに返される。</p>
                            <p class="card-description mb-4"><u><strong>例4:</strong></u> すべてのチップによる複数ベットA) もしすべてのチップがコールに必要なとき、プレイヤーの手元にチップが残っているときと同じように取り扱う（例1を参照）。B) 最小単位のチップ1枚を取りのぞいてもコール額以上のとき、そのベット額が50%のレイズ基準にたっしているかどうかに関わらず、そのプレイヤーはオールインをしたものとみなす。</p>
                            <p class="card-description mb-4"><u><strong>例4-A:</strong></u> Aが1400にオープンし、（手元にまだ多くチップの残る）Bが宣言せずに1枚の1000チップと3枚の500チップを出した。このときは、強制的に2800のミニマムレイズとなる。なぜなら、50%の基準値である2100（1400+700）にたっしている額であるためである。</p>
                            <p class="card-description mb-4"><u><strong>例4-B:</strong></u> 同じように1400へのオープンに対し、Bが1枚の1000チップと2枚の500チップを出した。このときはコールとなる。なぜなら、50%の基準値である2100には満たない額であるためである。<u><strong>注意: 4-A, 4-Bどちらの例でも、プレイヤーがすべてのチップを使ってそのベットを行ったとき、オールインとなる。</strong></u></p>

                            <h6 class="">第46項: さげられなかった前のベットのチップ、<span class="text-danger">状況例</span></h6>
                            <p class="card-description mb-4"><u><strong>シチュエーション1:</strong> 前のベットのチップが<span class="text-danger">コール額を満たさない状態でそのまま置かれているとき。</span></u></p>
                            <p class="card-description mb-4">例: NLHEで、25-50、BBが2枚の25をポストしていて、ボタンが600へとレイズした（BBに550を上乗せ）ケース。</p>
                            <ol>
                            <li>1枚のオーバーチップを<span class="text-danger">加えた</span>ときはコールとなる（2枚の25 チップに対し1枚の1000チップを入れるとき）。</li>
                            <li>複数枚の新しいチップを加えたときで、<u>新たに加えた</u>チップすべてがコールに必要なときはコール。a) 2枚の25チップに対し、2枚の500チップを加えたときb) 2枚の25チップに対し、1枚の100チップと500チップを加えたとき。<span class="text-danger">上記の例2つでは、それ以前のチップに合算されたとき、<u>新たに加えた</u>チップすべてがコールに必要である。</span></li>
                            <li>新たに加えたチップのうち、最小単位のものがコールに必要のない分として1枚以上あったとき、第45項の複数チップでのベットとなる。（2枚の25チップに対して1000と500を1枚ずつ入れ、合計1550のベットをしているとき）第45項の複数枚のチップによるベットとなる。第45項にしたがって、宣言せずに出された複数枚のチップによるベットは、それが50%の額にたっしているときにはレイズとなり、それ以外はコールとなる。</li>
                            </ol>
                            <p class="card-description mb-4"><u><strong>シチュエーション2:</strong> 前のチップがコール額を満たすとき、</u>新たに加えられたチップは、第45項複数チップでのベットとなる。例: NLHEで、50-100、BBが1000チップ1枚をポストしている。プリフロップで700へとレイズ（BBに600上乗せ）。前の1000チップはそのレイズをカバーしているので、そこに新たなチップを足すことは第45項にしたがってすべてのチップがベットされていることになる。</p>
                            <p class="card-description mb-4"><u><strong>シチュエーション3:</strong> 以前のチップが<span class="text-danger">すべてさげられている</span>とき:</u><br />1) 前のチップをすべてさげ、1枚のオーバーチップを出したときはコール（2枚の25チップをさげて、1枚の1000チップを出す）。<br />2) 前のチップをすべて一度さげ、複数枚の新しいチップを出したときは、第45項にしたがったベットとなる（2枚の25チップをさげて、2枚以上の新しいチップを出す）。</p>
                            <p class="card-description mb-4"><u><strong><span class="text-danger">シチュエーション4: 前のチップが部分的にさげられたとき</span></strong></u><br />1) 前のチップを部分的にさげること（1枚の25をさげ、別の25が残ったまま、新しいチップを加える）は、第45項にしたがって<span class="text-danger">複数枚のチップによる</span>ベットとなる<span class="text-danger">（もし50％にたっしていればレイズ、それ以外はコール）。</span></p>
                            <p class="card-description mb-4"><u><strong>シチュエーション5:</strong></u> 上記にかかわらず、すべてのチップを合わせてから、押し出したり前に投げたりするジェスチャーは、第45項にしたがって、すべてのチップでベットする意図と解釈される可能性がある。</p>

                            <h6 class="">第47項: ベットのリオープン（再オープン）</h6>
                            <p class="card-description mb-4"><u><strong>例1:</strong></u> <span class="text-danger">複数の</span>ショートオールインが<span class="text-danger">累積で</span>フルレイズ<span class="text-danger">と同額にたっし</span>、ベッティングがリオープンされるケース</p>
                            <p class="card-description mb-4">NLHEで、ブラインドは50-100。ポストフロップ、Aが100のミニマムでオープンベット。<br />Bが125をオールイン、Cが125をコール。<br />Dがトータル200となるオールインをし、Eが200をコール。<br />Aにアクションが戻り、Aは合計100のレイズに直面している。100はフルレイズの額を満たしていて、Aに対してベッティングがリオープン（再オープン）されているため、Aはフォールド、コール、レイズのいずれも行うことができる。注目すべき点は、Bの増加分25とDの増加分75は、ともにそれだけではフルレイズに満たないが、それらが合わさることでフルレイズとなり、したがって「アクションが戻ってきたときに、少なくともフルレイズに直面しているプレイヤー」に対してベッティングがリオープンしていることである。</p>
                            <p class="card-description mb-4"><u><strong>例1-A:</strong></u> 例1で最後にAが合計200をスムースコール（100を加えた）した。Cのアクションであるが、このとき、Cが直面しているのは75の増加分のみである。Cは前のアクションで125コールしていて、彼が直面しているのは合計200（75の増加分）である。<span class="text-danger">Cがベッティングをリオープンするためには少なくとも合計225に直面していなくてはならない。</span>75はフルレイズに満たないため、Cに対してはベッティングがリオープンされておらず、Cは75を加えてコールするか、フォールドすることができるが、レイズはできない。</p>
                            <p class="card-description mb-4"><u><strong>例1-B:</strong></u> 例1で最後に、Aがミニマムレイズ（100）行い、Cに対して合計300へとベットした。Cはすでに125をコールしていて、コールにはCは175を加えることになる。175はフルレイズ額を越えている。したがってCはすでにアクションを起こしていて「アクションが戻ってきたときに、少なくともフルレイズに直面したプレイヤー」となるため、ベッティングがリオープンされていて、フォールド、コール、リレイズができる。</p>
                            <p class="card-description mb-4"><span class="text-danger"><u><strong>例2:</strong></u> 複数のショートオールインがあり、ミニマムレイズは最後の有効なフルベットまたはフルレイズであるとき。</span><br /><span class="text-danger">NLHEで、ブラインドは50-100。ポストフロップでAが300へとオープン、Bが合計500をオールイン、Cが合計650をオールイン、Dが合計800をオールイン、Eは800をコール。プレイヤーFにとってのミニマムレイズはいくらか? オープンベット（300）が最初のミニマムレイズ額を設定する。どのプレイヤーも単独では300を越えるオールインとなっていないため、Fにとってのミニマムレイズは300のままである。Fは800をスムースコールするか、あるいは最低1100へとレイズできる。追補例解の第43項例2も参照。</span></p>
                            <p class="card-description mb-4"><u><strong>例3:</strong></u> ショートオールインでの2つのシナリオ<br />NLHE、ブラインドは2000-4000。プリフロップでAがBBの4000をコール、Bがフォールドし、Cが合計7500となるオールイン（4000に対して3500の増加分）をした。SBまでフォールドで回り、SBもフォールド。</p>
                            <p class="card-description mb-4"><u><strong>例3-A:</strong></u> まだアクションを起こしていないBBに対しては3500が上乗せされている。BBは、フォールド、3500をスムースコール、最低4000を加えた合計11500以上にレイズするのいずれかを選ぶことができる。BBはスムースコールし、アクションはAに移った。Aは一度アクションを起こしていて、フルレイズに満たない3500増加のベットに直面している。そのため、Aはフォールドか、3500を加えるコールしかできない。理由は「アクションが戻ってきたときに、少なくともフルベットに直面しているプレイヤー」になっていないためである。</p>
                            <p class="card-description mb-4"><u><strong>例3-B:</strong></u> BBがミニマム（4000）の合計11500となるレイズをした。これはAに対して7500の増加分となっているため、フルミニマムレイズ額を越えていて、Aに対してはベッティングがリオープンされたため、Aはコール、フォールド、リレイズすることができる。</p>

                            <h6 class="">第51項: 拘束される宣言／順序にそったアンダーコール</h6>
                            <p class="card-description mb-4"><u><strong>例1:</strong></u> NLHEで、ブラインドは1000-2000。ポストフロップで、プレイヤーAが2000をベットし、プレイヤーBは8000にレイズした。そのあと、プレイヤーCは宣言せずに2000を押し出した。Cはアンダーコールとなる。<span class="text-danger">第51項-B</span>により、Bはオープンしたプレイヤー（このときAである）ではなく、この状況ではマルチウェイである。TDの裁量により、Cは正しくコールするか、またはアンダーコールした2000を没収し、フォールドさせることもある。</p>
                            <p class="card-description mb-4"><u><strong>例2:</strong></u> NLHEで、ブラインドは1000-2000。プリフロップでプレイヤーが4人残っている。プレイヤーAは8000をベットし、Bは宣言せずに2000を押し出した。<span class="text-danger">第51項-B</span>により、プレイヤーBはアンダーコールとなり、ベットされた8000を正しくコールしなければならない。</p>
                            <p class="card-description mb-4"><u><strong>例3:</strong></u> NLHEで、ブラインドは1000-2000。プレイヤーAは、2000をベット、Bが8000にレイズした。Cは「コール」と宣言した。<span class="text-danger">第51項-A</span>により、プレイヤーCは8000を正しくコールしなければならない。</p>

                            <h6 class="">第52項-B: ポットリミットゲームにおける、不正確なベット額</h6>
                            <p class="card-description mb-4"><u><strong>例1:</strong></u> ポットリミットオマハで、ブラインドは500-1000。ポストフロップで、ポットは合計10500であった。プレイヤーAはポット額をベットするために、ディーラーにポットのカウントを要求したところ、ディーラーは「9500」と答えた。Aは9500を出し、Bはフォールド、Cは9500をコールした。最初の誤ったベット額のあとで、SAが起こっている。この状況で、ディーラーが、Aのポットベット額を正しくは10500であることに気がついたとする。このとき、ディーラーが宣言した額は実際のポット額よりも低いものであり、SAが起こったあとであるため、9500のベットは拘束力を持つものとなり、あとから10500に訂正されることはない。</p>
                            <p class="card-description mb-4"><u><strong>例2:</strong></u> 例1と同じ状況で、プレイヤーBがフォールドした直後に、ディーラーが正しい10500というポットベット額に気がついたとする。このとき、SAが起こっていないため、Aはベット額を10500に訂正しなければならない。</p>
                            <p class="card-description mb-4"><u><strong>例3:</strong></u> ポットリミットオマハで、ブラインドは500-1000。ポストフロップで、ポットは合計10500であった。プレイヤーAはポット額をベットするために、ディーラーにポットのカウントを要求したところ、ディーラーは「11500」と答えた。Aは11500を出し、Bはフォールド、CとDはどちらも11500をコールした。ディーラーが、バーンを切り、次のカードを開く前に、Aのベットが適切でないオーバーベットであると気がついた。SAが起こったかどうかにはかかわらず、そのベット額が適切でないためにそのストリートにおいてコールしたすべてのプレイヤーのコール額を10500に減らす。もし、次のカードが開かれたとき、その誤った額は保たれてしまう。</p>

                            <h6 class="">第53項-A: 順序にそわないアクション（OOT）</h6>
                            <p class="card-description mb-4"><u><strong>例1:</strong></u> ブラインドは、50-100。ポストフロップで、シート3のプレイヤーが300にオープン、シート4がフォールドした。そして、シート5のアクションの順序となったところで、シート6が「800へレイズ」と宣言した。<br />ステップ1: 300のベットを受けている正しい順序（シート5）のプレイヤーまでアクションを戻す。<br />ステップ2: もし、シート5がコールもしくはフォールドのとき、300のベットのアクションは変化せず、シート6の順序から外れた 800へのレイズも拘束力を持つ。しかし、シート5がレイズ（例えば 、600へのレイズ）をしたときには、シート6にたどり着くアクションは300から600のベットへと変わる。アクションが変わったとき、800のチップはシート6に返され、そのプレイヤーは、600へのコール、少なくとも900以上へのレイズ、フォールドのアクションも起こすことができる。</p>
                            <p class="card-description mb-4"><u><strong>例2:</strong></u> ブラインドは、50-100。ポストフロップで、シート3のプレイヤーがチェック、シート4もチェックした。そして、シート5にアクションに直面したところで、シート6が「チェック」と宣言した。<br />ステップ1: ベットを受けていない正しい順序（シート5）のプレイヤーまで、アクションを戻す。<br />ステップ2: もし、シート5がチェックをしたとき、チェックのアクションは変化せず、シート6の順序にそわないチェックは拘束力を持つ。しかし、シート5がベット（例えば300のベット）をしたとき、シート6にたどり着くアクションは、チェックから300のベットへと変化する。アクションが変わったとき、シート6は、300へのコール、少なくとも600以上へのレイズ、フォールドのどのアクションも起こすことができる。</p>

                            <h6 class="">第53項-B: 実質的な順序にそわないアクション（OOT）</h6>
                            <p class="card-description mb-4">順序にそわないアクションにより飛ばされたプレイヤーのアクションを起こす権利は守られなければならない。合理的な時間がたって、アクションを飛ばされたプレイヤーがSAまで指摘せずSAが左で起きたときは、その順序にそわないアクションは拘束力がある。飛ばされたハンドをどのように扱うかを決めるためには、フロアを呼ぶ。</p>
                            <p class="card-description mb-4"><u><strong>例1:</strong></u> NLHEで、ブラインドが100-200。シート3のUTGが600にレイズ。シート4が飛ばされ、シート5がOOTとして、600をコール。シート6は少し考えフォールドした。そのとき、シート4の左に、チップを巻き込んだアクション中のプレイヤーは2人いる。この2人のチップは残しプレイヤーのアクションは、SAとみなす。（第36項）また、シート4は、飛ばされたことを申告し、ディーラーの注意を喚起するのに十分な時間を持っていた。シート5によるコールは、順序にそわない（ながらも）SAであるために、拘束力があり、シート6による順序にそわないフォールドも拘束力がある（第58項）。シート4のハンドの運命は、判断を下すためにフロアが呼ばれる。</p>
                            <p class="card-description mb-4"><u><strong>例2:</strong></u> NLHEで、ブラインドが100-200。4人のプレイヤーがターンを見るために残っている。ディーラーがターンのカードを開いたあと、UTG（シート3）のプレイヤーが600をベットした。シート4のプレイヤーのアクションが飛ばされ、シート5のプレイヤーがチェック、シート6のプレイヤーが600のベットという、OOTが起きた。シート4のハンドの運命について裁定するために、フロアが呼ばれる。</p>

                            <div class="space-50"></div>
                            <p class="card-description mb-4">日本語訳は、ジャパンオープンポーカーツアー株式会社が、NPO法人日本ポーカー協会の協賛のもと行った。
This translation was last updated on November 17, 2019. Translater: Munehiko Matsuyama & shotaimu / Editor: Naoki Watanabe & Takaaki Ogasahara / Supervisor: Tatsumune Miyata</p>

                            <div class="space-50"></div>
                            <p class="card-description mb-4"><a href="https://www.pokertda.com/">Poker Tournament Directors Association（https://www.pokertda.com/）</a>より転載</p>

                        </div>
                    </div>
                    <div class="space-50"></div>
                </div>
-->

            </div>
        </div>

    </div>
</template>

<script>
  import {
    Breadcrumb,
    BreadcrumbItem,
  } from '@/components';
  import axios from "axios";
  import {API_ENDPOINT} from '@/constants';

  export default {
    name: 'tda',
    bodyClass: 'presentation-page',
    components: {
      [Breadcrumb.name]: Breadcrumb,
      [BreadcrumbItem.name]: BreadcrumbItem,
    },
    data() {
      return {
        html_content: ''
      }
    },
    async created() {
      try {
        document.title = 'ポーカーの遊び方 - POKER TOURNAMENT DIRECTORS ASSN. 2019年版 ルール | ポーカーナビ - 全国のポーカースポット、サークル、トーナメント情報を配信！'
        await axios
            .get(API_ENDPOINT + `appconstant/3`)
            .then(response => {
              this.html_content = response.data.text_value
            })
/*
      await axios
          .get('https://s3-ap-northeast-1.amazonaws.com/pokernavi.breezebox.co.jp/contents/tda_20200615.txt')
          .then(response => {
            this.html_content = response.data
          })
*/
      } catch (e) {
        this.html_content = '<div class="spinner"></div><div class="space-50"></div>'
      } finally {
        //
      }
    },
  }
</script>
