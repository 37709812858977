<template>
    <div v-if="this.no_content" class="wrapper presentation-page no-content">
        <div class="mx-auto text-center">
            <div class="spinner"></div>
            <div class="space-50"></div>
        </div>
    </div>
    <div v-else class="wrapper presentation-page">

        <vue-backTop v-if="true" :height="600" :bottom="50" :right="30" @onClick="" @complete="">
            <div class="backtop">
                <i class="fa fa-spade display-5 text-info"></i>
            </div>
        </vue-backTop>

        <div id="poker-spot" data-background-color="white">
            <div class="space-100"></div>

            <div class="container">
                <div class="mb-5">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }"><span class="text-black-50"><i class="fa fa-home"></i></span></el-breadcrumb-item>
                      <el-breadcrumb-item v-if="page.title">{{ page.title }}</el-breadcrumb-item>
                      <el-breadcrumb-item v-else>イベントチケットについて</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>

            <a v-if="true" :href="order_form" target="_blank" class="register-wrap">
                <div class="register">
                   <span>無</span>
                   <span>料</span>
                   <span>掲</span>
                   <span>載</span>
                   <span>登</span>
                   <span>録</span>
                </div>
            </a>

            <div class="container">

                <div class="mb-0 text-right">
                    <social-sharing
                        :url="currentURL()"
                        title="イベントチケット一覧 | ポーカーナビ - 全国のポーカースポット、サークル、トーナメント情報を配信！"
                        description="イベントチケット一覧"
                        quote="イベントチケット一覧"
                        :hashtags="getHashtags()"
                        twitter-user="PokerNavi_JP"
                        inline-template>
                        <div>
                            <network network="facebook">
                                <div class="sns_share fb">
                                    <i class="fab fa-facebook-f mr-1" style="color:#fff"></i>
                                    <span class="font-weight-bold" style="font-size:.8rem">Share</span>
                                </div>
                            </network>
                            <network network="twitter">
                                <div class="sns_share twitter">
                                    <i class="fab fa-twitter mr-1" style="color:#fff"></i>
                                    <span class="font-weight-bold" style="font-size:.7rem;vertial-align:middle">ツイート</span>
                                </div>
                            </network>
                        </div>
                    </social-sharing>
                </div>

                <h6 class="with-title-dark">
                  <span v-if="page.title !== null && page.title !== ''">{{ page.title }}</span>
                  <span v-else>イベントチケットについて</span>
                </h6>
            </div>

            <div class="container">
                <div class="row">

                    <div v-if="sales.length == 0" class="mx-auto">
                        <pre class="text-center">登録されているイベントチケットはありません</pre>
                    </div>

                    <div v-else class="col-md-12">
                        <div>
                            <p class="card-description">チケットの申し込みには店舗会員の登録が必要です。<br />登録は <a :href="order_form" target="_blank" class="font-weight-bold">こちら</a> よりお願い致します。</p>
                            <hr  class="mb-5" />
                        </div>
                        <div class="cards">
                            <div v-if="this.page.description" class="mb-5">
                                <p class="inner-card description">{{ this.page.description }}</p>
                            </div>
                            <div v-if="meta.total_pages > 1" class="pagination-container justify-content-center mb-3">
                                <sales-pagination type="primary"
                                              :total="meta.count"
                                              :value="meta.current_pages"
                                              :page-count="meta.total_pages"
                                              v-model="pagination.simple"
                                              @fav="addToFavorite"
                                              ></sales-pagination>
                            </div>

                            <div class="row d-flex">
                                <div v-for="(event, i) in sales" :key="event.id" class="col-sm-12 col-md-12 col-lg-6 d-sm-flex mb-4">
                                    <div class="col-12 col-sm-5">
                                        <div class="row">
                                            <div class="flyer" :style="'background-image: url(' + event.on_sale_thumb + ');'" />
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-7 flyer-desc">
                                        <div>
                                            <h5 class="card-title font-weight-bold">{{ event.title }}</h5>
                                            <div class="flyer-desc-link-wrap mb-4">
                                                <a v-if="event.on_sale_link !== null && event.on_sale_link !== ''" :href="event.on_sale_link" target="_blank">
                                                    <n-button type="primary" size="sm">
                                                        <span class="mx-4">チケットを申し込む</span>
                                                    </n-button>
                                                </a>
                                                <router-link v-if="event.published" :to="{ name: 'event', params: { id: event.id }}" tag="a">
                                                    <n-button type="primary" size="sm" simple>
                                                        <span class="text-black mx-4">イベントページへ</span>
                                                    </n-button>
                                                </router-link>
                                            </div>
                                            <p class="card-description" v-html="$options.filters.readmore(event.on_sale_text, 120)"></p>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            </div>

                            <div v-if="meta.total_pages > 1" class="pagination-container justify-content-center mb-3">
                                <video-pagination type="primary"
                                              :total="meta.count"
                                              :value="meta.current_pages"
                                              :page-count="meta.total_pages"
                                              v-model="pagination.simple"
                                              @fav="addToFavorite"
                                              ></video-pagination>
                            </div>
                            <div class="space-50"></div>

                        </div>
                    </div>

                    <div v-if="false" class="pt-3 mb-5">
                        <div class="adsense-infeed text-center">
                            <dfp-slot
                                path="/21867335047/pokernavi/pokernavi-footer"
                                :size="dfpSlotSize('div-gpt-ad-1580361234575-0')"
                                id="div-gpt-ad-1580361234575-0"
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
  import {
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Card,
    NukButton,
    SalesPagination,
  } from '@/components';
  import {
    Button,
    Loading,
  } from 'element-ui';
  import axios from "axios";
  import {API_ENDPOINT} from '@/constants';
  import DfpSlot from '../components/DfpSlot.vue'

  export default {
    name: 'ticket',
    bodyClass: 'presentation-page',
    components: {
      Badge,
      Card,
      [NukButton.name]: NukButton,
      [Button.name]: Button,
      [Breadcrumb.name]: Breadcrumb,
      [BreadcrumbItem.name]: BreadcrumbItem,
      [SalesPagination.name]: SalesPagination,
      DfpSlot,
    },
    methods: {
      dfpSlotSize: function(key) {
        let dfp_slots_sizes = this.dfp_slots[key]
        if (1200 <= window.innerWidth) {
          return dfp_slots_sizes['336']
        } else if(767 < window.innerWidth && window.innerWidth < 1199) {
          return dfp_slots_sizes['336']
        } else if (window.innerWidth <= 517) {
          return dfp_slots_sizes['336']
        }
      },
      addNew: function(index) {
        return 'add-new';
      },
      setTypeBlog: function(index) {
        if (index%3 !== 0) {
          return 'blog';
        }
      },
      getSales () {
        let endpoint = this.baseUrl+'events/?sales'

        axios
          .get(endpoint)
          .then(response => {
            this.meta = response.data.meta
            this.sales = response.data.events
            this.no_content = false
            document.title = 'イベントチケットについて | ポーカーナビ - 全国のポーカースポット、サークル、トーナメント情報を配信！'
          })
          .catch(response => {
            console.err(response);
          });

        // layout ------------
        axios
          .get(API_ENDPOINT + `appconstant/7`)
          .then(response => {
            this.order_form = response.data.text_value
          })
      },
      setPages () {
        let numberOfPages = Math.ceil(this.sales.length / this.perPage);
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      addToFavorite: function(arrayPosts) {
        this.sales = arrayPosts
      },
    },
    async created() {
      try {
        this.loadingInstance = Loading.service({ fullscreen: true });
        this.getSales();
      } catch (e) {
        console.error(e)
      } finally {
        this.loadingInstance.close();
      }
    },
    watch: {
      posts() {
        this.setPages();
      },
      sales: function() {
        this.$nextTick(() => {
            var positionY = sessionStorage.getItem('positionY')
            scrollTo(0, positionY);
            setTimeout(function(){
                scrollTo(0, positionY);
                }, 500);
            })
        this.no_content = (this.sales == null)
      }
    },
    data() {
      return {
        no_content: true,
        meta: {},
        sales: [],
        order_form: '',
        loadingInstance: null,
        baseUrl: API_ENDPOINT,
        page: {
          title: '',
          description: ''
        },
        currentPage: 1,
        pagination: {
            simple: 1,
            default: 2,
            full: 3
        },
        paginationValue: 1,
        dfp_slots: {
          'div-gpt-ad-1571972845074-0': { // 不明
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580282482771-0': { // 不明
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580355841225-0': { // circles
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580353644223-0': { // spots
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580356736019-0': { // events
            336: [336, 280]
          },
          'div-gpt-ad-1580361234575-0': { // footer
            300: [300, 250]
          },
        }
      }
    }
  }
</script>
<style scoped lang="scss">
.flyer {
  background-position: left top;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 300px;
}
.flyer-desc > div {
  margin-right: -15px;
}
@media (max-width: 575px){
  .flyer {
    background-position: center top;
    margin-bottom: 30px;
  }
  .flyer-desc {
    margin-bottom: 50px;
    h5 {
      text-align: center;
      margin-bottom: 10px;
    }
    .flyer-desc-link-wrap {
      text-align: center;
    }
  }
}
</style>
