import '@/assets/sass/now-ui-kit.scss';
import '@/assets/sass/demo.scss';
import globalDirectives from './globalDirectives';
import globalMixins from './globalMixins';
//import globalComponents from './globalComponents';
//import lang from 'element-ui/lib/locale/lang/en';
import lang from 'element-ui/lib/locale/lang/ja';
import locale from 'element-ui/lib/locale';
import VueLazyload from 'vue-lazyload'
import MagicGrid from 'vue-magic-grid';

locale.use(lang);

export default {
    install(Vue) {
        Vue.use(globalDirectives);
        Vue.use(globalMixins);
        //Vue.use(globalComponents);
        Vue.use(VueLazyload, {
          observer: true,
          observerOptions: {
            rootMargin: '0px',
            threshold: 0.1
          },
          throttleWait: 5000,
          loading: '/images/preloader.svg'
        });
        Vue.use(MagicGrid, {
          container: '.posts-list',
          animate: true,
          gutter: 30,
          static: true,
          useMin: true
        });
    }
}
