<template>
    <div id="app">
        <router-view name="header"/>
        <div>
            <router-view :key="$route.fullPath + Date.now()" />
        </div>
        <router-view name="footer"/>
    </div>
</template>
<script>
  export default {
    name: 'AppComponent',

    methods: {
      track () {
        this.$ga.page('/')
      },
    },
    created() {
      window.fbAsyncInit = function() {
        FB.init({
          appId            : '475699036356762',
          autoLogAppEvents : true,
          xfbml            : true,
          version          : 'v3.2'
        });
      };

      (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v3.2';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    }
  }
</script>

