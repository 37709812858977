<template>
    <div v-if="true">
        <div v-if="this.no_content" class="wrapper profile-page no-content">
            <div class="spinner"></div>
        </div>
        <div v-else class="wrapper profile-page">

            <a v-if="true" :href="order_form" target="_blank" class="register-wrap">
                <div class="register">
                   <span>無</span>
                   <span>料</span>
                   <span>掲</span>
                   <span>載</span>
                   <span>登</span>
                   <span>録</span>
                </div>
            </a>

            <div class="slider-container google-calendar-wraps" data-background-color="white">

                <div class="space-100"></div>
                <div class="container">
                    <div class="mb-5">
                        <el-breadcrumb separator-class="el-icon-arrow-right">
                            <el-breadcrumb-item :to="{ path: '/' }"><span class="text-black-50"><i class="fa fa-home"></i></span></el-breadcrumb-item>
                            <el-breadcrumb-item :to="{ path: '/videos' }"><span class="text-black-50">動画一覧</span></el-breadcrumb-item>
                            <el-breadcrumb-item><span class="text-black-50">{{ video.title }}</span></el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                </div>


                <div class="google-calendar-mask">

                    <div v-if="true" class="container mt-3">
                        <div class="mb-0 text-right">
                            <social-sharing
                                :url="currentURL()"
                                :title="getTitle(video)"
                                :description="getDescription(video)"
                                :quote="getDescription(video)"
                                :hashtags="getHashtags(video)"
                                twitter-user="PokerNavi_JP"
                                inline-template>
                                <div>
                                    <network network="facebook">
                                        <div class="sns_share fb">
                                            <i class="fab fa-facebook-f mr-1" style="color:#fff"></i>
                                            <span class="font-weight-bold" style="font-size:.8rem">Share</span>
                                        </div>
                                    </network>
                                    <network network="twitter">
                                        <div class="sns_share twitter">
                                            <i class="fab fa-twitter mr-1" style="color:#fff"></i>
                                            <span class="font-weight-bold" style="font-size:.7rem;vertial-align:middle">ツイート</span>
                                        </div>
                                    </network>
                                </div>
                            </social-sharing>
                        </div>
                    </div>

                    <div class="container mb-3">

                        <div v-if="video.youtube !== null && video.youtube !== '' ">
                            <h6 class="with-title-dark">{{ video.title }}</h6>

                            <div v-if="tag_includes.length > 0" class="row">
                                <div class="col-md-12">
                                    <n-button v-for="tag in tag_includes" :key="tag.id" type="primary" size="sm" simple>
                                        <router-link :to="{ name: 'videos', query: { tag: tag.id }}">
                                            <span class="text-black-50">{{tag.title}}</span>
                                        </router-link>
                                    </n-button>
                                    <hr  class="mb-5" />
                                </div>
                            </div>

                            <div class="youtube_wrap mb-4">
                                <iframe class="youtube_content" :src="'https://www.youtube.com/embed/' + video.youtube + ''" frameborder="0" allow="accelerometer;autoplay;encrypted-media;gyroscope;picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div class="mb-4">{{ video.description }}</div>
                        </div>

                        <div v-if="true" class="pt-3 mb-5">
                            <div class="adsense-infeed text-center">
                                <dfp-slot v-if="true"
                                    path="/21867335047/pokernavi/pokernavi-footer"
                                    :size="dfpSlotSize('div-gpt-ad-1580361234575-0')"
                                    id="div-gpt-ad-1580361234575-0"
                                />
                            </div>
                        </div>

                        <div v-if="this.events.length > 0" class="row">
                            <div class="container">
                                <h6 class="with-title-dark">関連イベント</h6>
                                <div class="cards">
                                    <div v-if="this.events.length > 0" class="card-columns mb-3">
                                        <div v-for="(event, i) in this.events" :key="event.id">
                                            <router-link :to="{ name: 'event', params: { id: event.id }}" tag="a">
                                                <a>
                                                    <card :cardBodyClasses="addNewEvent(event)" >
                                                        <img v-if="event.thumbnail !== null" slot="image" class="img rounded" v-lazy="event.thumbnail">
                                                        <h5 class="card-title font-weight-bold">{{event.title}}</h5>
                                                        <p v-if="false" class="card-description" v-html="$options.filters.readmore(event.overview, 100)"></p>
                                                    </card>
                                                </a>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="this.spots.length > 0" class="row">
                            <div class="container">
                                <h6 class="with-title-dark">関連スポット</h6>
                                <div class="cards">
                                    <div v-if="this.spots.length > 0" class="card-columns mb-3">
                                        <div v-for="(spot, i) in this.spots" :key="spot.id">
                                            <router-link v-if="spot.unique_domain !== null && spot.unique_domain !== ''" :to="'/' + spot.unique_domain" tag="a">
                                                <a>
                                                    <card :cardBodyClasses="addNewSpot(spot)" >
                                                        <img v-if="spot.thumbnail !== null" slot="image" class="img rounded" v-lazy="spot.thumbnail">
                                                        <h5 class="card-title font-weight-bold">{{ spot.name }}</h5>
                                                        <p v-if="false" class="card-description" v-html="$options.filters.readmore(spot.announcement, 100)"></p>
                                                    </card>
                                                </a>
                                            </router-link>
                                            <router-link v-else :to="{ name: 'spot', params: { id: spot.id }}" tag="a">
                                                <a>
                                                    <card :cardBodyClasses="addNewSpot(spot)" >
                                                        <img v-if="spot.thumbnail !== null" slot="image" class="img rounded" v-lazy="spot.thumbnail">
                                                        <h5 class="card-title font-weight-bold">{{ spot.name }}</h5>
                                                        <p v-if="false" class="card-description" v-html="$options.filters.readmore(spot.announcement, 100)"></p>
                                                    </card>
                                                </a>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="this.circles.length > 0" class="row">
                            <div class="container">
                                <h6 class="with-title-dark">関連サークル</h6>
                                <div class="cards">
                                    <div v-if="this.circles.length > 0" class="card-columns mb-3">
                                        <div v-for="(circle, i) in this.circles" :key="circle.id">
                                            <router-link :to="{ name: 'circle', params: { id: circle.id }}" tag="a">
                                                <a>
                                                    <card :cardBodyClasses="addNewCircle(circle)" >
                                                        <img v-if="circle.thumbnail !== null" slot="image" class="img rounded" v-lazy="circle.thumbnail">
                                                        <h5 class="card-title font-weight-bold">{{ circle.name }}</h5>
                                                    </card>
                                                </a>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="tag_videos_filtered.length > 0" class="row">
                            <div class="container">
                                <h6 class="with-title-dark">関連動画</h6>
                                <div v-for="(tag, i) in tag_videos_filtered" :key="tag.id" class="">
                                    <h6 class="mb-3 text-black video_h6" slot="title">
                                        <n-button type="primary" size="sm" simple>
                                            <router-link :to="{ name: 'videos', query: { tag: tag.id }}">
                                                <span class="text-black-50">{{ tag.title }}</span>
                                            </router-link>
                                        </n-button>
                                        <span class="font-weight-normal text-right align-middle ml-3">含まれる動画：<span class="font-weight-bold mr-1">{{ tag.videos.length }}</span>件</span>
                                    </h6>
                                    <div class="card-columns mb-3">
                                        <div class="cards">
                                            <div v-for="(v, i) in tag.videos.slice(0,3)" :key="v.id">
                                                <router-link :to="{ name: 'video', params: { id: v.id }}" tag="a">
                                                    <a>
                                                        <card>
                                                            <img v-if="v.thumbnail !== null" slot="image" class="img rounded" v-lazy="v.thumbnail">
                                                            <h5 class="card-title font-weight-bold">{{ v.title }}</h5>
                                                        </card>
                                                    </a>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>


        </div>
    </div>
</template>
<script>
  import {
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    NukButton,
    Card,
    Collapse,
    CollapseItem,
  } from '@/components';
  import Twitter from '../components/Twitter';
  import { API_ENDPOINT } from '@/constants'
  import {
    Loading,
    Carousel,
    CarouselItem,
    Table,
    TableColumn,
  } from 'element-ui';
  import axios from "axios";
  import dayjs from 'dayjs'
  import DfpSlot from '../components/DfpSlot.vue'

  export default {
    name: 'video',
    bodyClass: 'profile-page',
    components: {
      Card,
      Collapse,
      CollapseItem,
      Twitter,
      Badge,
      [Breadcrumb.name]: Breadcrumb,
      [BreadcrumbItem.name]: BreadcrumbItem,
      [NukButton.name]: NukButton,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      DfpSlot
    },
    filters: {
      dateFormatter: function (date) {
        return format(date, 'YYYY年MM月DD日')
      },
      yyyyMMdd: function (date) {
        return dayjs(date).format('YYYY年MM月DD日')
      },
      MMdd: function (date) {
        return dayjs(date).format('MM月DD日')
      },
      HHmm: function (date) {
        return dayjs(date).format('HH:mm')
      },
      yyyyMMddd: function (date) {
        return dayjs(date).format('YYYY年M月D日 (ddd)');
      },
    },
    computed: {
      tag_videos_filtered: function() {
        return this.tag_includes.filter(function(i) {
          return i.videos.length > 0;
        })
      },
    },
    async created() {
      try {
        this.loadingInstance = Loading.service({ fullscreen: true });
        await this.getVideo()
      } catch (e) {
        console.error(e)
      } finally {
        this.loadingInstance.close();
      }
    },
    data() {
      return {
        no_content: true,
        video: {},
        order_form: '',
        sliders: [],
        tag_includes: [],
        twitter_id: '',
        loadingInstance: null,
        calendar_view_event: 0,
        events: [],
        spots: [],
        circles: [],
        dfp_slots: {
          'div-gpt-ad-1571972845074-0': { // 不明
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580282482771-0': { // 不明
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580355841225-0': { // circles
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580353644223-0': { // spots
            300: [300, 250],
            336: [336, 280]
          },
          'div-gpt-ad-1580356736019-0': { // events
            336: [336, 280]
          },
          'div-gpt-ad-1580361234575-0': { // footer
            300: [300, 250]
          },
        },
      }
    },
    watch: {
      video: function() {
        this.$nextTick(() => {
            var positionY = sessionStorage.getItem('positionY')
            scrollTo(0, positionY);
            setTimeout(function(){
                scrollTo(0, positionY);
                }, 500);
            })
        this.no_content = (this.video == null)
        document.title = this.video.title
      },
    },
    updated() {
      this.$nextTick(()=>{
        if (window.FB.XFBML) {
          window.FB.XFBML.parse(document.getElementById('fbpage'));
        }
      });
    },
    methods: {
      dfpSlotSize: function(key) {
        let dfp_slots_sizes = this.dfp_slots[key]
        return dfp_slots_sizes['300']
      },
      getVideo: function() {
        axios
          .get(API_ENDPOINT + `videos/` + this.$route.params.id)
          .then(response => {
            this.video = response.data
            this.events = response.data.events
            this.spots = response.data.spots
            this.circles = response.data.organizations

            if (this.video !== null) {
              axios
                .get(API_ENDPOINT + `tags/?video=` + this.$route.params.id)
                .then(response => {
                  this.tag_includes = response.data
                })
            }
            this.no_content = false
          })
        // layout ------------
        axios
          .get(API_ENDPOINT + `appconstant/7`)
          .then(response => {
            this.order_form = response.data.text_value
          })
/*
        if (!document.getElementById('facebook-jssdk')) {
          (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v3.2';
            fjs.parentNode.insertBefore(js, fjs);
          }(document, 'script', 'facebook-jssdk'));
        }
*/
      },
      handleRowClick(spot) {
        this.$router.push({ name: 'spot', params: { id: spot.id } });
      },
      getFbWidgetWidth: function() {
        if (window.innerWidth > 1200) {
          return 445;
        } else if (991 < window.innerWidth && window.innerWidth <= 1200) {
          return 370;
        } else if (768 < window.innerWidth && window.innerWidth <= 991) {
          return 270;
        } else if (window.innerWidth <= 533) {
          return window.innerWidth - 30;
        } else if (533 < window.innerWidth && window.innerWidth <= 767) {
          return 500;
        }
      },
      addNewEvent: function(event) {
        let day7ago = dayjs(Date.now()).add(-7, 'days')
        if (dayjs(event.updated_at).diff(day7ago) > 0) {
          return 'add-new';
        }
      },
      addNewSpot: function(spot) {
        let day7ago = dayjs(Date.now()).add(-7, 'days')
        if (spot.charged && (dayjs(spot.updated_at).diff(day7ago) > 0)) {
          return 'add-new';
        }
      },
      addNewCircle: function(circle) {
        let day7ago = dayjs(Date.now()).add(-7, 'days')
        if (circle.charged && (dayjs(circle.updated_at).diff(day7ago) > 0)) {
          return 'add-new';
        }
      },
      setOrange: function(image) {
        if (!image) {
          return 'orange';
        }
      },
    },
  }
</script>
<style scoped>
.twitter-widget-wrapper iframe { width: 20000px !important; }
.video_h6 {
  display: block;
  border-bottom: 1px solid #ddd;
}
</style>
